import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import { useTheme } from "@material-ui/core/styles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

function InputAutocomplete({ field, form, data, ...other }) {
  const currentError = form.errors[field.name];
  const theme = useTheme();

  if (other.withChips) {
    return (
      <Autocomplete
        name={field.name}
        multiple
        id={other.id}
        options={data}
        getOptionLabel={(option) => option}
        disableCloseOnSelect
        value={field.value}
        onChange={(event, newValue) => {
          if (newValue.length <= other.limit) {
            form.setFieldValue(field.name, newValue, false);
          }
        }}
        style={{
          flex: 1,
          width: "100%",
        }}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={
                <CheckBoxIcon
                  fontSize="small"
                  style={{ fill: theme.palette.primary.main }}
                />
              }
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={other.label}
            placeholder={other.placeholder ? other.placeholder : undefined}
          />
        )}
      />
    );
  } else {
    return (
      <Autocomplete
        freeSolo
        id={other.id}
        disableClearable
        options={data}
        inputValue={field.value}
        onInputChange={(e, newValue) =>
          form.setFieldValue(field.name, newValue, false)
        }
        style={{
          flex: 1,
          width: "100%",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={other.label}
            variant="outlined"
            name={field.name}
            value={field.value}
            error={currentError ? true : false}
            helperText={currentError ? currentError : ""}
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
      />
    );
  }
}

export default InputAutocomplete;
