const JOTFORM_LOOKUP_PROD = {
  generalinquiry: "https://form.jotform.com/213413549977466",
  rentalinquiry: "https://form.jotform.com/203032247876455",
  tractorinquiry: "https://form.jotform.com/203045714511444",
  implementinquiry: "https://form.jotform.com/203046275443451",
  tractorinquiryFdw:
    process.env.REACT_APP_INBOUND_FDW ??
    "https://main.d2j0pkze6spwsy.amplifyapp.com/inbound",
  implementinquiryFdw:
    process.env.REACT_APP_INBOUND_FDW ??
    "https://main.d2j0pkze6spwsy.amplifyapp.com/inbound",
  complaint: "https://form.jotform.com/203045367090450",
  miscellaneous: "https://form.jotform.com/203045563627454",
  advisory: "https://form.jotform.com/203045789049462",
  outbound: "https://form.jotform.com/203300994509051",
  burdwanCMS: "https://form.jotform.com/211864045873460",
  ecommerce:"https://form.jotform.com/221852862146054",
};
const JOTFORM_LOOKUP_DEV = {
  generalinquiry: "https://form.jotform.com/203084164957460",
  rentalinquiry: "https://form.jotform.com/203084195929463",
  tractorinquiry: "https://form.jotform.com/203345617503450",
  implementinquiry: "https://form.jotform.com/203345390051446",
  tractorinquiryFdw:
    process.env.REACT_APP_INBOUND_FDW ??
    "https://main.d2j0pkze6spwsy.amplifyapp.com/inbound",
  implementinquiryFdw:
    process.env.REACT_APP_INBOUND_FDW ??
    "https://main.d2j0pkze6spwsy.amplifyapp.com/inbound",
  complaint: "https://form.jotform.com/203345513125445",
  miscellaneous: "https://form.jotform.com/203345190391451",
  advisory: "https://form.jotform.com/203345291265453",
  outbound: "https://form.jotform.com/203345831703450",
  burdwanCMS: "https://form.jotform.com/211864045873460",
  ecommerce:"https://form.jotform.com/221852862146054",
};

export const API_ROUTES = {
  GET_CAMPAIGNS_FOR_GIVEN_PHONE: "/get_campaigns_for_given_phone/",
  GET_CALLING_LIST:
    "https://outbound-campaign-functions.azurewebsites.net/api/get_calling_list",
  CHECK_SERVICABILITY: "/orders/check_servicability/",
  FETCH_ORDERS: "/orders/order/",
  CANCEL_ORDERS: "/orders/cancel/",
  CREATE_NEW_ORDER: "/orders/create_new/",
  FETCH_SERVICES: "/lookups/services/",
  CREATE_CAMPAIGN: "/handle_excel_entries/",
  RECORD_CALL_HEALTH: "/record_crm_call_health/",
  FETCH_CMS_TICKETS: "/fetch_cms_tickets/",
  FETCH_TRACTORS: "/fetch_tractors/",
  WELCOME_FORM_SUBMIT: "/submit_tickets/",

  FEEDBACK_CALL_SUBMIT: "/submit_tickets/",
  FORCE_CLOSE_TICKET: "/force_close_callbacks/",
  JOTFORM_HISTORY: "/jotform/history/",
  JOTFORM_LIST: "/jotform/list/",
  CHECK_USER: "/uid/check_user/",
  SAVE_USER: "/uid/save_user/",
  LOGIN_API: "/uid/login/",
  RECORD_CALL_LOG: "/record_call_log/",
  TRIGGER_IVR: "/trigger_ivr/",
  GET_ALL_LIST_OF_CAMPAIGNS: "/get_call_list_for_campaign/",
  FETCH_CALL_HEALTH: "/fetch_crm_call_health/",
  FETCH_FILTER_CALLS: "/jotform/inquiries/filter",
  FETCH_FILTER_CALLS_SELECTED: "/jotform/inquiry/details/",
  FETCH_SMS_TEMPLATES: "/smsportal/templates/",
  TRIGGER_SMS: "/smsportal/send/",
};

export const API_BASE_URL = {
  CRM_FAST_API: process.env.REACT_APP_CRM_FAST_API
    ? process.env.REACT_APP_CRM_FAST_API
    : "https://crm-fast-api-cms.azurewebsites.net",
  KRISHE_CRM_API: process.env.REACT_APP_KRISHE_CRM_API
    ? process.env.REACT_APP_KRISHE_CRM_API
    : "https://krishe-crm.azurewebsites.net",
};

export const JOTFORM_LOOKUP =
  process.env.NODE_ENV === "production"
    ? JOTFORM_LOOKUP_PROD
    : JOTFORM_LOOKUP_DEV;
