import * as types from "../../types";

export const forceCloseRequest = ({ body }) => {
  return {
    type: types.FORCE_CLOSE_REQUEST,
    payload: { body },
  };
};

export const forceCloseSuccess = ({ res }) => {
  return {
    type: types.FORCE_CLOSE_SUCCESS,
    payload: res,
  };
};

export const forceCloseFailure = (e) => {
  return {
    type: types.FORCE_CLOSE_FAILURE,
    payload: {},
    error: e,
  };
};
