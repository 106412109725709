import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
export default function Redirect() {
  const history = useHistory();

  return (
    <body style={{ backgroundColor: "#FAF3FF", height: "100vh" }}>
      <div
        style={{ display: "flex", justifyContent: "center", minHeight: "10px" }}
      >
        <motion.div
          animate={{ rotate: 360 }}
          transition={{
            type: "spring",
            damping: 10,
            mass: 0.75,
            stiffness: 100,
          }}
        >
          <Card
            style={{
              width: 400,

              backgroundColor: "rgb(101, 32, 120)",
              height: "300px",
              margin: "auto",
              display: "flex",
              marginTop: "100px",
              flexDirection: "column",
            }}
          >
            <Button
              style={{
                backgroundColor: "#F37021",
                color: "white",
                width: "200px",
                height: "50px",
                margin: "auto",
              }}
              onClick={() => {
                history.push("/nav");
              }}
            >
              Add another entry
            </Button>

            <Button
              style={{
                backgroundColor: "#F37021",
                color: "white",
                width: "200px",
                height: "50px",
                margin: "auto",
              }}
              onClick={() => {
                history.push({ pathname: "/" });
              }}
            >
              End call
            </Button>
          </Card>
        </motion.div>
      </div>
    </body>
  );
}
