import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types";
import { fetchSmsTemplates } from "../../api";
import {
  fetchSmsTemplatesSuccess,
  fetchSmsTemplatesFailure,
} from "../action/fetchSmsTemplatesAction";
import { returnErrors, clearErrors } from "../action/errorAction";

function* asyncfetchSmsTemplatesSaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(fetchSmsTemplates);
    yield put(
      fetchSmsTemplatesSuccess({ templates: res.data.templates ?? [] })
    );
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(fetchSmsTemplatesFailure(e));
  }
}
export default function* fetchSmsTemplatesSaga() {
  yield takeLatest(
    types.FETCH_SMS_TEMPLATE_REQUEST,
    asyncfetchSmsTemplatesSaga
  );
}
