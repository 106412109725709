import React from "react";
import Logo from "../../assets/Logo.svg";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Button } from "../../elements";
import { connect } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import navbarStyles from "./navbar.styles";

const leftButtonHide = [
  "/",
  "/general",
  "/miscellaneous",
  "/rental",
  "/tractor",
  "/tractor-fdw",
  "/implement-fdw",
  "/implement",
  "/advisory",
  "/complaints",
  "/outbound-campaign",
  "/rental-order",
  "/outbound",
  "/outbound/form",
  "/admin",
  "/admin/create",
];

const leftIconTextData = {
  "/nav": {
    text: "Back To Search",
    to: "/",
  },
  "/search-calls": {
    text: "Back to Tiles",
    to: "/nav",
  },
  default: {
    text: "Back to Home",
    to: "/",
  },
};

const Navbar = ({ setLoggedIn, ...props }) => {
  const classes = navbarStyles();
  const username = localStorage.getItem("username");
  const history = useHistory();
  const location = useLocation();

  if (location.pathname === "/cancelOrder") {
    return null;
  }

  return (
    <nav className={classes.root}>
      {!leftButtonHide.includes(location.pathname) && (
        <NavLink
          className={classes.navLink}
          to={leftIconTextData[location.pathname]?.to ?? "/"}
        >
          <Button
            color="secondary"
            style={{ lineHeight: "2.3em", width: "auto" }}
            startIcon={<ArrowBackIosIcon style={{ fontSize: 17 }} />}
          >
            <p>
              {" "}
              {leftIconTextData[location.pathname]?.text ?? "Back to Home"}
            </p>
          </Button>
        </NavLink>
      )}
      <h1 className={classes.logo}>
        <img src={Logo} alt="logo" />
      </h1>
      <div className={classes.accountDesc}>
        <h3>Hello {username}</h3>
        <Button
          variant="outlined"
          color="secondary"
          className={classes.logoutBtn}
          onClick={() => {
            localStorage.clear();
            sessionStorage.clear();
            setLoggedIn(false);
          }}
        >
          Logout
        </Button>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  cameFromHome: state.ticketReducer.cameFromHome,
});

export default connect(mapStateToProps, {})(Navbar);
