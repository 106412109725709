import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 280;
const burdwanCmsStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  toolbar: theme.mixins.toolbar,
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    padding: "0px",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(10) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(20) + 1,
    },
  },
  jotformContainer: {
    width: "100%",
    maxWidth: "700px",
    margin: "4em auto 2em auto",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& > * + *": {
      marginTop: "1em",
    },
  },
  metabaseLink: {
    marginTop: "2em",
    textTransform: "capitalize",
  },

  jotForm: {
    width: "100%",
    height: "100%",
  },

  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "0.4s",
    borderRadius: "20px 20px 0 0 ",
    background: theme.palette.light.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > img": {
      width: "100px",
    },
  },
}));

export default burdwanCmsStyles;
