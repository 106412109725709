import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types";
import { triggerSms } from "../../api";
import {
  smsTriggerSuccess,
  smsTriggerFailure,
} from "../action/smsTriggerAction";
import { returnErrors, clearErrors } from "../action/errorAction";

function* asyncSmsTriggerSaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(triggerSms, { body: action.payload.body });
    yield put(smsTriggerSuccess({ res }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(smsTriggerFailure(e));
  }
}
export default function* smsTriggerSaga() {
  yield takeLatest(types.SMS_TRIGGER_REQUEST, asyncSmsTriggerSaga);
}
