import { makeStyles } from "@material-ui/core/styles";

const searchCallsStyles = makeStyles((theme) => ({
  searchCallContainer: {
    maxWidth: "1300px",
    margin: "3em auto",
    width: "100%",
    minHeight: "800px",
    borderRadius: "15px",
    background: "#fff",
    padding: "2em",
    boxShadow: "0px 3px 20px rgba(0,0,0,0.1)",
  },
}));

export default searchCallsStyles;
