import React, { useEffect, useState } from "react";
import { Button, InputSelect, InputText, InputField } from "../../elements";

import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import smsTriggerStyles from "./smstrigger.styles";

import { fetchSmsTemplatesRequest } from "../../store/action/fetchSmsTemplatesAction";
import { smsTriggerRequest } from "../../store/action/smsTriggerAction";
import { CircularProgress } from "@material-ui/core";
import { useLocation } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const SmsTrigger = ({
  fetchTemplates,
  templates,
  triggerSms,
  triggerRes,
  triggerLoading,
}) => {
  const classes = smsTriggerStyles();
  const [templateArr, setTemplateArr] = useState([]);
  const [messageValue, setMessageValue] = useState("");
  const location = useLocation();
  const [phoneValue, setPhoneValue] = useState(
    location?.state?.phone ?? sessionStorage.getItem("number")
  );
  const [templateValue, setTemplateValue] = useState(null);
  const number = location?.state?.phone ?? sessionStorage.getItem("number");
  const hideLanguage = true;

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    if (Array.isArray(templates) && templates.length > 0) {
      let modifiedTemplates = templates.map((template) => {
        return {
          label: template.title,
          value: template.id,
        };
      });
      setTemplateArr(modifiedTemplates);
    }
  }, [templates]);

  useEffect(() => {
    if (triggerRes) {
      MySwal.fire({
        icon: triggerRes.status ? "success" : "error",
        title: triggerRes.status ? "Hurrah!" : "Oops...",
        text: triggerRes.message,
        showConfirmButton: true,
      });
    }
  }, [triggerRes]);

  const handleTemplateChange = (value) => {
    setTemplateValue(value);
    let templateBody = templates.find((el) => el.id === value).body;
    setMessageValue(templateBody);
  };

  const handlePhoneChange = (value) => {
    setPhoneValue(value);
  };

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.heading}>Sms Trigger</h2>
      <Formik
        enableReinitialize={true}
        initialValues={{
          phone: phoneValue,
          template: templateValue,
          language: "",
          message: messageValue,
        }}
        validate={(values) => {
          const errors = {};
          if (values.phone === "") {
            errors.phone = "*Phone Number is Required!";
          }
          if (!values.template) {
            errors.template = "*Please Select a template.";
          }
          if (values.message === "") {
            errors.message = "*Message is Required!";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          let body = {
            phone: values.phone,
            message: values.message,
            sender:
              templates.find((el) => el.id === values.template)?.senderName ??
              "",
          };
          triggerSms({ body });
          setSubmitting(false);
        }}
      >
        {({ values }) => (
          <Form>
            <div className={classes.inputContainer}>
              <div className={classes.inputWrapper}>
                <label htmlFor="phone-label">Phone Number:</label>
                <Field
                  name="phone"
                  id="phone-label"
                  size="small"
                  width="400px"
                  disabled={number ? true : false}
                  onChangeUtil={handlePhoneChange}
                  component={InputField}
                />
              </div>
              <div className={classes.inputWrapper}>
                <label htmlFor="choose-template">Choose the template:</label>
                <Field
                  name="template"
                  id="choose-template"
                  options={templateArr}
                  size="small"
                  width="400px"
                  onChangeUtil={handleTemplateChange}
                  label="Select the template"
                  component={InputSelect}
                />
              </div>
              {!hideLanguage && (
                <div className={classes.inputWrapper}>
                  <label htmlFor="choose-language">Choose Language:</label>
                  <Field
                    name="language"
                    id="choose-language"
                    width="170px"
                    label="English/Hindi"
                    options={[
                      { label: "English", value: "english" },
                      { label: "Hindi", value: "hindi" },
                    ]}
                    size="small"
                    component={InputSelect}
                  />
                </div>
              )}
              <div className={classes.inputWrapper}>
                <label>Message Template:</label>
                <Field
                  name="message"
                  id="message-template"
                  width="80%"
                  label="Message"
                  value={messageValue}
                  component={InputText}
                />
              </div>
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                boxShadow: "none",
                padding: "0.4em 1.2em",
                fontWeight: "400",
              }}
            >
              Send Message
              {triggerLoading && (
                <CircularProgress
                  size={15}
                  style={{ color: "#fff", marginLeft: "1em" }}
                />
              )}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => ({
  templates: state.smsTemplates.templates,
  triggerLoading: state.smsTrigger.loading,
  triggerRes: state.smsTrigger.res,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTemplates: () => {
    dispatch(fetchSmsTemplatesRequest());
  },
  triggerSms: ({ body }) => {
    dispatch(smsTriggerRequest({ body }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SmsTrigger);
