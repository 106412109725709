import * as types from "../types";
const initialState = {
  loading: false,
  file: null,
  msg: null,
  data: null,
  isValid: true,
  uploaded: false,
  error: {},
};

const uploadExcelReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPLOAD_EXCEL_REQUEST:
      return {
        ...state,
        loading: true,
        file: action.payload.file,
        data: null,
        uploaded: false,
        error: {},
      };
    case types.UPLOAD_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        isValid: true,
        data: action.payload.data,
        uploaded: true,
        msg: action.payload.msg,
        error: {},
      };
    case types.UPLOAD_EXCEL_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        file: null,
        isValid: false,
        uploaded: true,
        error: action.error,
      };
    case types.RESET_EXCEL:
      return {
        ...state,
        loading: false,
        file: null,
        msg: null,
        data: null,
        isValid: true,
        uploaded: false,
        error: {},
      };
    default:
      return state;
  }
};

export default uploadExcelReducer;
