import { makeStyles } from "@material-ui/core/styles";

const adminStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "2000px",
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    margin: "0 auto",
    background: theme.palette.grey.main,
    paddingTop: "1em",
    textAlign: "initial !important",
    "& h1,h2,h3,h4,h5,p": {
      margin: "0 ",
      padding: "0",
    },
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
    "& input[type=`number`]": {
      "-moz-appearance": "textfield",
    },
  },
  innerbox: {
    width: "80%",
    minHeight: "200px",
    margin: "3em auto",
    height: "auto",
    background: "#FFFFFF",
    borderRadius: "20px 20px 0 0 ",
    padding: "2em 4em",
    position: "relative",
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h1": {
      display: "flex",
      alignItems: "center",
      gap: ".7em",
    },
  },
  submitBtn: {
    // width: 140,
    lineHeight: "44px",
    backgroundColor: `${theme.palette.accent.main} !important`,
    color: `${theme.palette.light.main} !important`,
    boxShadow: "none !important",
    margin: "1.3em 0 0 0",
  },
}));

export default adminStyles;
