import React, { useState } from "react";
import searchCallsStyles from "./searchCalls.styles";
import {
  SearchCallModal,
  SearchCallTable,
  SearchCallTopBar,
} from "./SearchCallComponents";

const SearchCalls = () => {
  const classes = searchCallsStyles();
  const [open, setOpen] = useState(false);
  const handleModal = () => setOpen((prev) => !prev);

  return (
    <main className={classes.searchCall}>
      <div className={classes.searchCallContainer}>
        <SearchCallTopBar />
        <SearchCallTable handleModal={handleModal} />
        <SearchCallModal open={open} handleClose={handleModal} />
      </div>
    </main>
  );
};

export default SearchCalls;
