import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types";
import * as _api from "../../api";
import {
  fetchFilterCallsSuccess,
  fetchFilterCallsFailure,
  fetchFiltercallsSelectSuccess,
  fetchFiltercallsSelectFailure,
} from "../action/fetchFilterCallsAction";
import { returnErrors, clearErrors } from "../action/errorAction";

function* asyncfetchFilterCallsSaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(_api.fetchFilterCalls, {
      searchKey: action.payload.searchKey,
      searchValue: action.payload.searchValue,
    });
    if (res.message === "Success") {
      yield put(fetchFilterCallsSuccess({ data: res.data }));
    } else {
      yield put(returnErrors({ msg: "Failed to fetch Data", status: true }));
      yield put(
        fetchFilterCallsFailure({ msg: "Failed to fetch Data", status: true })
      );
    }
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(fetchFilterCallsFailure(e));
  }
}
function* asyncfetchFilterCallsSelectSaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(_api.fetchFilterCallsSelected, {
      ticket_id: action.payload.ticket_id,
      category: action.payload.category,
    });
    if (res.message === "Success") {
      yield put(fetchFiltercallsSelectSuccess({ data: res.data }));
    } else {
      yield put(returnErrors({ msg: "Failed to fetch Data", status: true }));
      yield put(
        fetchFiltercallsSelectFailure({
          msg: "Failed to fetch Data",
          status: true,
        })
      );
    }
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(fetchFiltercallsSelectFailure(e));
  }
}

export default function* fetchFilterCallsSaga() {
  yield takeLatest(types.FETCH_FILTER_CALLS_REQUEST, asyncfetchFilterCallsSaga);
}
export function* fetchFilterCallsSelectSaga() {
  yield takeLatest(
    types.FETCH_FILTER_CALLS_SELECT_REQUEST,
    asyncfetchFilterCallsSelectSaga
  );
}
