import { makeStyles } from "@material-ui/core/styles";

const smsTriggerStyles = makeStyles((theme) => ({
  wrapper: {
    width: "80%",
    background: "rgba(0,0,0,0.03)",
    margin: "3em auto",
    padding: "2em",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: "10px",
    textAlign: "left",
    "& > form": {
      width: "100%",
    },
  },
  heading: {
    fontSize: "1.6em",
    color: theme.palette.black.main,
  },
  inputContainer: {
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "1em 0",
  },
  inputWrapper: {
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.7em",
    margin: "1em 0",
    "& > label": {
      fontWeight: "500",
      fontSize: "1em",
    },
  },
}));

export default smsTriggerStyles;
