import React, { Suspense } from "react";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

const AreaComponent = React.lazy(() => import("./Area"));
const DistanceComponent = React.lazy(() => import("./Distance"));
const FuleConsumptionComponent = React.lazy(() => import("./FuelComsumption"));
const FuelLevelComponent = React.lazy(() => import("./FuelLevel"));
const JobTimeComponent = React.lazy(() => import("./JobTime"));
const LocationComponent = React.lazy(() => import("./Location"));
const MissingAlertsComponent = React.lazy(() => import("./MissingAlerts"));
const OthersComponent = React.lazy(() => import("./Others"));
const RechargeRequestComponent = React.lazy(() => import("./RechargeRequest"));
const TotalTimeComponent = React.lazy(() => import("./TotalTime"));
const TripMissComponent = React.lazy(() => import("./TripMiss"));
const TooManyAlertsComponent = React.lazy(() => import("./TooManyAlerts"));

const loadingSkeleton = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "40px",
      }}
    >
      <Skeleton
        animation="wave"
        style={{
          width: "100%",
        }}
      />
    </div>
  );
};
const RenderQuestions = ({ ...props }) => {
  return (
    <>
      <Suspense fallback={() => loadingSkeleton()}>
        {props.issues.includes("area") && <AreaComponent />}
      </Suspense>
      <Suspense fallback={() => loadingSkeleton()}>
        {props.issues.includes("distance") && <DistanceComponent />}
      </Suspense>

      <Suspense fallback={() => loadingSkeleton()}>
        {props.issues.includes("fuel_consumption") && (
          <FuleConsumptionComponent />
        )}
      </Suspense>
      <Suspense fallback={() => loadingSkeleton()}>
        {props.issues.includes("fuel_level") && <FuelLevelComponent />}
      </Suspense>
      <Suspense fallback={() => loadingSkeleton()}>
        {props.issues.includes("job_time") && <JobTimeComponent />}
      </Suspense>
      <Suspense fallback={() => loadingSkeleton()}>
        {props.issues.includes("location") && <LocationComponent />}
      </Suspense>
      <Suspense fallback={() => loadingSkeleton()}>
        {props.issues.includes("missing_alerts") && <MissingAlertsComponent />}
      </Suspense>
      <Suspense fallback={() => loadingSkeleton()}>
        {props.issues.includes("others") && <OthersComponent />}
      </Suspense>
      <Suspense fallback={() => loadingSkeleton()}>
        {props.issues.includes("recharge_request") && (
          <RechargeRequestComponent />
        )}
      </Suspense>
      <Suspense fallback={() => loadingSkeleton()}>
        {props.issues.includes("total_time") && <TotalTimeComponent />}
      </Suspense>
      <Suspense fallback={() => loadingSkeleton()}>
        {props.issues.includes("trip_miss") && <TripMissComponent />}
      </Suspense>
      <Suspense fallback={() => loadingSkeleton()}>
        {props.issues.includes("too_many_alerts") && <TooManyAlertsComponent />}
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  issues: state.issuesReducer.issues,
});

export default connect(mapStateToProps, {})(RenderQuestions);
