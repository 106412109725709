import React, { useState, useEffect } from "react";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { connect } from "react-redux";
import createCampaignStyles from "./createCampaign.styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import SampleExcel from "../../data/sample.xlsx";

import { Formik, Form, Field, useFormikContext } from "formik";
import { DatePicker, InputField, Button } from "../../elements";
import { FormHelperText } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";

import { DropzoneArea } from "material-ui-dropzone";
import {
  uploadExcelRequest,
  resetExcel,
} from "../../store/action/uploadExcelAction";
import { updateCampaignRequest } from "../../store/action/fetchCampaignAction";
import {
  createCampaignRequest,
  resetCreateCampaign,
} from "../../store/action/createCampaignAction";
import LinearProgress from "@material-ui/core/LinearProgress";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import Chip from "@material-ui/core/Chip";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { formatDateInYYMMDD } from "../../utils";

const MySwal = withReactContent(Swal);

const SetData = ({ resetExcel, res, ...props }) => {
  const { values, setFieldValue, resetForm } = useFormikContext();

  useEffect(() => {
    if (resetExcel) {
      setFieldValue("farmerExcel", null, false);
    }
    //TODO: still a little bug left here not able to reset excel
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.farmerExcel, resetExcel]);
  useEffect(() => {
    if (res && res.status === "Success") {
      resetForm({
        values: {
          campaignCode: "",
          campaignName: "",
          jotformLink: "",
          faqLink: "",
          callScriptLink: "",
          startDate: new Date(),
          farmerExcel: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res]);

  useEffect(() => {
    if (new Date(values.startDate) > new Date(values.endDate))
      setFieldValue("endDate", values.startDate, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.startDate]);

  return null;
};

function CreateCampaign({
  setUrl,
  getNumber,
  setLoggedIn,
  username,
  ...props
}) {
  const classes = createCampaignStyles();
  const [resetExcel, setResetExcel] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const {
    state: { campaign, editing },
  } = location;

  useEffect(() => {
    return () => {
      props.resetExcelData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.excelUploaded) {
      if (props.excelValid) {
        setResetExcel(false);
        MySwal.fire({
          icon: "success",
          title: "Hurrah!",
          text: "Excel Uploaded Successfully",
          showConfirmButton: true,
        });
      } else {
        setResetExcel(true);
        MySwal.fire({
          icon: "error",
          title: "oops!",
          text: props.excelError?.msg ?? "Please upload the Excel again!",
          showConfirmButton: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.excelUploaded]);

  useEffect(() => {
    if (props.campaignRes) {
      MySwal.fire({
        icon: props.campaignRes.status.toLowerCase(),
        title:
          props.campaignRes.status.toLowerCase() === "success"
            ? "Hurrah!"
            : "oops!",
        text: props.campaignRes.message,
        showConfirmButton: true,
      }).then((isConfirm) => {
        if (isConfirm) {
          props.resetExcelData();
          props.updateCampaigns({ phone: 1413914 });
          props.resetCampaign();
          history.push("/admin");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.campaignRes]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.innerbox}>
          <h1 className={classes.heading}>
            <span className={classes.backIcon}>
              <IconButton
                onClick={() => {
                  history.push("/admin");
                }}
              >
                <KeyboardBackspaceIcon />
              </IconButton>
            </span>
            Create Campaign:
          </h1>
          <Formik
            initialValues={{
              campaignCode: campaign?.campaign_code ?? "",
              campaignName: campaign?.campaign_name ?? "",
              jotformLink: campaign?.jotform_url ?? "",
              startDate: campaign?.start_date
                ? new Date(campaign.start_date)
                : new Date(),
              endDate: campaign?.end_date
                ? new Date(campaign.end_date)
                : new Date(),
              farmerExcel: null,
              action: "overwrite",
            }}
            validate={(values) => {
              let expression =
                /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
              let regex = new RegExp(expression);

              const errors = {};
              if (values.campaignCode === "") {
                errors.campaignCode = "*Campaign Code is Required!";
              }
              if (values.campaignName === "") {
                errors.campaignName = "*Campaign Name is Required!";
              }
              if (values.jotformLink === "") {
                errors.jotformLink = "*Jotform Link is Required!";
              }
              if (!editing && !values.farmerExcel) {
                errors.farmerExcel = "*Excel is Required!";
              }
              if (!values.jotformLink.match(regex)) {
                errors.jotformLink = "*Please enter a valid url!";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(false);
              let body = {};
              body.mode = editing ? "edit" : "new";
              body.campaign_code = values.campaignCode;
              body.campaign_name = values.campaignName;
              body.start_date = formatDateInYYMMDD(values.startDate);
              body.end_date = formatDateInYYMMDD(values.endDate);
              body.jotform_url = values.jotformLink;
              body.excel_data = props.excelData ?? null;
              body.action = values.action ?? "overwrite";
              props.submitCampaign({ data: body });
            }}
          >
            {({ setFieldValue, errors, values }) => (
              <Form>
                <div className={classes.questionBox}>
                  <h4>Enter the Campaign Code:</h4>
                  <Field
                    type="text"
                    name="campaignCode"
                    id="campaign-code"
                    label="Campaign Code"
                    disabled={editing ? true : false}
                    labelWidth={120}
                    component={InputField}
                  />
                </div>
                <div className={classes.questionBox}>
                  <h4>Enter the Campaign Name:</h4>
                  <Field
                    type="text"
                    name="campaignName"
                    id="campaign-name"
                    label="Campaign Name"
                    labelWidth={120}
                    component={InputField}
                  />
                </div>
                <div className={classes.questionBox}>
                  <h4>Enter the Campaign Start Date:</h4>
                  <Field
                    name="startDate"
                    id="start-date"
                    format="dd/MM/yyyy"
                    disablePast={true}
                    component={DatePicker}
                  />
                </div>
                <div className={classes.questionBox}>
                  <h4>Enter the Campaign End Date:</h4>
                  <Field
                    name="endDate"
                    id="end-date"
                    format="dd/MM/yyyy"
                    disablePast={true}
                    // maxDate={new Date(values.startDate).setMonth(
                    //   new Date(values.startDate).getMonth() + 6
                    // )}
                    minDate={new Date(values.startDate)}
                    component={DatePicker}
                  />
                </div>
                <div className={classes.questionBox}>
                  <h4>Enter the JotForm Link:</h4>
                  <Field
                    type="text"
                    name="jotformLink"
                    id="jotform-link"
                    label="Jotform Link"
                    labelWidth={120}
                    component={InputField}
                  />
                </div>
                {editing && (
                  <div className={classes.questionBox}>
                    <h4>Excel Upload Mode:</h4>
                    <RadioGroup
                      aria-label="upload-mode"
                      name="action"
                      value={values.action}
                      onChange={(e) => {
                        setFieldValue("action", e.target.value, false);
                      }}
                      className={classes.actionGroup}
                    >
                      <FormControlLabel
                        value="overwrite"
                        control={<Radio color="primary" />}
                        label="Overwrite"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="append"
                        control={<Radio color="primary" />}
                        label="Append"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </div>
                )}
                <div className={classes.questionBox}>
                  <div className={classes.sampleExcel}>
                    <h4>Farmer's Excel:</h4>
                    <a href={SampleExcel} download>
                      <GetAppIcon /> Sample Excel
                    </a>
                  </div>
                  {props.excelLoading && <LinearProgress />}
                  <DropzoneArea
                    acceptedFiles={[
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      "application/vnd.ms-excel",
                    ]}
                    filesLimit={1}
                    showPreviewsInDropzone={false}
                    onChange={(files) => {
                      if (files && files.length > 0) {
                        setFieldValue("farmerExcel", files[0], false);
                        props.uploadExcel({ file: files[0] });
                      }
                    }}
                    onDelete={(file) => {
                      setFieldValue("farmerExcel", null, false);
                    }}
                    showAlerts={false}
                  />
                  {values.farmerExcel && (
                    <div style={{ marginTop: "0.4em" }}>
                      Selected File :{" "}
                      <Chip label={`${values.farmerExcel?.name}`} />
                    </div>
                  )}
                  {errors && errors.farmerExcel && (
                    <FormHelperText error>{errors.farmerExcel}</FormHelperText>
                  )}
                </div>
                <SetData resetExcel={resetExcel} res={props.campaignRes} />
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submitBtn}
                  disabled={props.campaingLoading}
                >
                  {props.campaignLoading
                    ? "Creating Campaign..."
                    : "Create Campaign"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  excelLoading: state.uploadExcelReducer.loading,
  excelUploaded: state.uploadExcelReducer.uploaded,
  excelValid: state.uploadExcelReducer.isValid,
  excelError: state.uploadExcelReducer.error,
  excelData: state.uploadExcelReducer.data,
  campaignRes: state.createCampaignReducer.res,
  campaignLoading: state.createCampaignReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  uploadExcel: ({ file }) => {
    dispatch(uploadExcelRequest({ file }));
  },
  resetExcelData: () => {
    dispatch(resetExcel());
  },
  updateCampaigns: ({ phone }) => {
    dispatch(updateCampaignRequest({ phone }));
  },
  submitCampaign: ({ data }) => {
    dispatch(createCampaignRequest({ data }));
  },
  resetCampaign: () => {
    dispatch(resetCreateCampaign());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaign);
