import axios from "axios";
import { API_ROUTES, API_BASE_URL } from "../config";

export const fetchCampaigns = async ({ phone }) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.GET_CAMPAIGNS_FOR_GIVEN_PHONE}?phone=${phone}`
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchUsers = async ({ spoc, campaign }) => {
  try {
    const { data } = await axios.get(
      `https://outbound-campaign-functions.azurewebsites.net/api/get_calling_list?name=${spoc}&campaign=${campaign}`
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
