import { put, takeLatest, select } from "redux-saga/effects";
import * as types from "../../types";
import { addIssue, removeIssue } from "../../action/cmsActions/issueTypeAction";
import { returnErrors, clearErrors } from "../../action/errorAction";

const getIssues = (state) => state.issuesReducer.issues;

function* asyncIssueTypeSaga(action) {
  try {
    yield put(clearErrors());
    let issues = yield select(getIssues);
    if (issues.includes(action.payload.issue)) {
      console.log("inside the if block");
      yield put(removeIssue({ issue: action.payload.issue }));
    } else {
      console.log("inside the else block");
      yield put(addIssue({ issue: action.payload.issue }));
    }
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
  }
}

export default function* issueTypeSaga() {
  yield takeLatest(types.ISSUE_TYPE_REQUEST, asyncIssueTypeSaga);
}
