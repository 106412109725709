import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import InputText from "./Fields/InputText";
import { Button } from "../elements";
import Modal from "@material-ui/core/Modal";
import { forceCloseRequest } from "../../store/action/cmsActions/forceCloseAction";
import { updateTicketsRequest } from "../../store/action/cmsActions/fetchTicketAction";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "600px",
    minHeight: "300px",
    overflow: "scroll",
    outline: "none",
    border: "none",
    background: theme.palette.light.main,
    padding: "2em",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    "& h1,h2,h3,h4,h5,p": {
      margin: "0 !important",
      padding: "0 !important",
    },
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  ticketHeader: {
    width: "100%",
    padding: "1em 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `4px solid ${theme.palette.grey.main}`,
    "& > h2": {
      fontWeight: "600",
      fontSize: "1.3em",
      color: theme.palette.dark.main,
    },
    "& > span": {
      color: theme.palette.accent.main,
      cursor: "pointer",
    },
  },
  submitBtn: {
    width: 140,
    lineHeight: "44px",
    backgroundColor: `${theme.palette.accent.main} !important`,
    color: `${theme.palette.light.main} !important`,
    boxShadow: "none !important",
    margin: "1.3em 0 0 0",
  },
  closingForm: {
    marginTop: "2em",
  },
}));

const TicketForceCloseForm = ({
  forceClose,
  handleForceClose,
  selectedTicket,
  forceCloseLoading,
  forceCloseSubmit,
  handleModalOpen,
  forceCloseSubmitted,
  ...props
}) => {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    if (forceCloseSubmitted) {
      handleForceClose();
      handleModalOpen(false);
      MySwal.fire({
        icon: "success",
        title: "Ticket Closed",
        showConfirmButton: true,
        confirmButtonText: "Close",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((isConfirm) => {
        if (isConfirm) {
          props.updateTickets({
            spoc: localStorage.getItem("username") || props.curSpoc,
          });
          history.push("/cms");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceCloseSubmitted]);
  return (
    <Modal
      open={forceClose}
      onClose={handleForceClose}
      aria-labelledby="ticket-detail-modal"
      aria-describedby="ticket-detail-modal"
    >
      <div className={classes.modalStyle}>
        <div className={classes.ticketHeader}>
          <h2>Force Close Ticket</h2>
          <span onClick={handleForceClose}>Close X</span>
        </div>
        <div className={classes.closingForm}>
          <Formik
            initialValues={{
              comments: "",
            }}
            validate={(values) => {
              const errors = {};
              if (values.comments === "") {
                errors.comments = "*Required Field";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              let body = {
                id: selectedTicket?.id,
                type: "Callback Request",
                comments: values.comments,
              };
              forceCloseSubmit({ body });
              setSubmitting(false);
            }}
          >
            {() => (
              <Form>
                <Field
                  name="comments"
                  id="force-close-comments"
                  label="Comments"
                  labelWidth={50}
                  component={InputText}
                />
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submitBtn}
                >
                  {forceCloseLoading ? "loading.." : "Submit"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  selectedTicket: state.ticketReducer.selectedTicket,
  forceCloseLoading: state.forceClose.loading,
  forceCloseSubmitted: state.forceClose.res,
  curSpoc: state.ticketReducer.spoc,
});
const mapDispatchToProps = (dispatch) => ({
  forceCloseSubmit: ({ body }) => {
    dispatch(forceCloseRequest({ body }));
  },
  updateTickets: ({ spoc }) => {
    dispatch(updateTicketsRequest({ spoc }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketForceCloseForm);
