import * as types from "../../types";

export const fetchTractorRequest = ({ phone }) => {
  return {
    type: types.FETCH_TRACTOR_REQUEST,
    payload: { phone },
  };
};

export const fetchTractorSuccess = ({ tractors, existingTickets }) => {
  console.log(tractors);
  return {
    type: types.FETCH_TRACTOR_SUCCESS,
    payload: { tractors, existingTickets },
  };
};

export const fetchTractorFailure = (error) => {
  return {
    type: types.FETCH_TRACTOR_FAILURE,
    payload: {},
    error: error,
  };
};

export const resetTractors = () => {
  return {
    type: types.RESET_TRACTORS,
  };
};
