import { makeStyles } from "@material-ui/core/styles";

const sideCallStyles = makeStyles((theme) => ({
  sideBarWrapper: {
    width: "30vw",
    padding: "2em",
  },
  image: {
    width: "40px",
    height: "80px",
    margin: "auto 10px",
  },
  sideBarTop: {
    width: "100%",
    padding: "1em 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "1em",
  },
  customerInfo: {
    width: "100%",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "1.3em",
    fontFamily: "Lucida Grande, sans-serif",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.black.main,
  },
  phoneNumber: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: theme.palette.dark.main,
    textAlignt: "left",
    "& > span": {
      fontSize: "0.8em",
      color: theme.palette.primary.main,
    },
  },
  customerLocation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "0.9em",
    "& > span": {
      fontSize: "0.8em",
      color: theme.palette.primary.main,
    },
    "& > p": {
      color: theme.palette.dark.main,
    },
  },
  recentOrders: {
    marginTop: "2em",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "2em",
    "& > h3": {
      fontSize: "1.3em",
      color: theme.palette.black.main,
      textAlign: "left",
    },
  },
  orderList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "1.3em",
  },
  order: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.7em",
    width: "180px",
    minHeight: "110px",
    background: theme.palette.light.main,
    borderRadius: "5px",
    boxShadow: "2px 2px 10px rgba(0,0,0,0.1)",
    transition: "0.3s",
    padding: "1em",
    border: `1px solid #DADADA`,
    "& > p": {
      fontSize: ".8em",
    },
  },
  orderInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.3em",
    width: "100%",
    "& > p": {
      fontSize: ".8em",
    },
  },
  orderStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.3em",
    width: "100%",
    height: "23px",
    // background: "red",
    borderRadius: "15px",
    fontSize: ".8em",
    textTransform: "capitalize",
    "& svg": {
      fontSize: "1em",
      margin: "0",
    },
  },
  farmName: {
    textAlign: "left",
    color: theme.palette.black.main,
    fontSize: "1em",
    fontWeight: "500",
    width: "100%",
  },
  burdwanOrder: {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    background: theme.palette.primary.main,
    display: "grid",
    placeContent: "center",
    fontSize: "0.8em",
    color: "white",
  },
}));

export default sideCallStyles;
