import * as types from "../../types";
export const searchTicketRequest = ({ searchTerms }) => {
  return {
    type: types.SEARCH_TICKET_REQUEST,
    payload: { searchTerms },
  };
};

export const searchTicketSuccess = ({ tickets }) => {
  return {
    type: types.SEARCH_TICKET_SUCCESS,
    payload: { tickets },
  };
};

export const searchTicketFailure = (error) => {
  return {
    type: types.SEARCH_TICKET_FAILURE,
    payload: {},
    error: error,
  };
};
