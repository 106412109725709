import React from "react";
import { CardActionArea } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

import Typography from "@material-ui/core/Typography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default function CardItem({ text, icon }) {
  return (
    <Card
      style={{
        width: "570px",
        display: "flex",
        flexDirection: "row",
        borderRadius: "10px",
        justifyContent: "space-between",
        height: "150px",
        boxShadow: "0px 5px 15px #65207812",
      }}
    >
      <CardActionArea
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            image={icon}
            style={{ width: "90px", height: "90px", margin: "auto 30px" }}
          />

          <Typography
            variant="h6"
            style={{
              fontFamily: "Lucida Grande, sans-serif",
              fontSize: "24px",
              margin: " 0px 10px 0px",
              width: "279px",
              textAlign: "left",
              height: "60px",
              fontWeight: "700",
            }}
          >
            {text}
          </Typography>
        </div>
      </CardActionArea>
      <ArrowForwardIosIcon
        style={{ margin: "auto 10px", width: "12px", height: "19px" }}
      />
    </Card>
  );
}
