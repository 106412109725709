import React, { useState, useEffect, useCallback } from "react";
import { withTheme, makeStyles } from "@material-ui/core/styles";

// import SearchIcon from "@material-ui/icons/Search";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppIcon from "@material-ui/icons/GetApp";
// import FilterListIcon from "@material-ui/icons/FilterList";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";

// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import InputAdornment from "@material-ui/core/InputAdornment";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import IconButton from "@material-ui/core/IconButton";

import { NavLink } from "react-router-dom";
import { searchTicketRequest } from "../../store/action/cmsActions/searchTicketAction";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import debounce from "lodash.debounce";

import { connect } from "react-redux";

import { Button } from "../elements";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableOptions: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "2em",
    borderBottom: `2px solid ${theme.palette.grey.main}`,

    // [theme.breakpoints.down("md")]: {
    //   width: "100%",
    //   flexDirection: "column",
    //   alignItems: "center",
    //   justifyContent: "center",
    // },
  },
  tableOptionsRight: {
    display: "flex",
    alignItems: "center",
    gap: "1.2em",
  },
  searchInput: {
    width: "300px",
    background: theme.palette.grey.main,
  },
  fieldSelection: {
    marginLeft: "1em",
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },
}));

const downloadExcel = (tickets) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const data = tickets;
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data2 = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data2, "tickets" + fileExtension);
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const LISTBOX_PADDING = 8; // px

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const TableOption = ({ theme, ...props }) => {
  const classes = useStyles();
  const [search, setSearch] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);

  useEffect(() => {
    if (props.allTickets) {
      let filters = [
        "spoc",
        "state",
        "district",
        "type",
        "user_name",
        "device_id",
        "id",
      ];
      let options = {};
      let searchData = [];
      props.allTickets.forEach((ticket) => {
        filters.forEach((filter) => {
          if (ticket[filter]) {
            if (!options[filter]) {
              options[filter] = [];
              options[filter].push(ticket[filter].toString().toLowerCase());
            } else {
              options[filter].push(ticket[filter].toString().toLowerCase());
            }
          }
        });
      });
      for (let key of Object.keys(options)) {
        options[key] = [...new Set(options[key])].sort((a, b) =>
          a > b ? 1 : -1
        );
        let newArr = [];
        options[key].forEach((item) => {
          newArr.push({ key, value: item });
        });
        // searchData = [...searchData, ...options[key]];
        searchData = [...searchData, ...newArr];
      }
      setSearchOptions(searchData);
    }
  }, [props.allTickets]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(
      (nextValue) =>
        props.filterTickets({
          searchTerms: nextValue.val,
        }),
      500
    ),
    []
  );

  const handleSearchChange = (newValue) => {
    setSearch(newValue);
    debouncedSearch({ val: newValue });
  };

  return (
    <div className={classes.tableOptions}>
      <div className={classes.searchBox}>
        <Autocomplete
          multiple
          id="virtualize-demo"
          style={{ width: 300 }}
          disableListWrap
          value={search}
          onChange={(e, newValue) => {
            setSearch(newValue);
            handleSearchChange(newValue);
          }}
          classes={classes}
          ListboxComponent={ListboxComponent}
          renderGroup={renderGroup}
          options={searchOptions}
          getOptionLabel={(option) => option.value}
          groupBy={(option) => option.value[0].toUpperCase()}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Search" />
          )}
          renderOption={(option) => (
            <Typography noWrap>{option.value}</Typography>
          )}
        />
        {/* <Autocomplete
          multiple
          limitTags={2}
          id="tags-outlined"
          options={searchOptions}
          getOptionLabel={(option) => option.value}
          value={search}
          style={{
            width: "300px",
          }}
          onChange={(e, newValue) => {
            setSearch(newValue);
            handleSearchChange(newValue);
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Search"
              placeholder="Search Tickets..."
            />
          )}
        /> */}
      </div>
      <div className={classes.tableOptionsRight}>
        {/* <h3>
            Show All <ExpandMoreIcon style={{ fontSize: 24 }} />
          </h3> */}
        {/* <Button
          variant="text"
          color="primary"
          style={{ width: 170, lineHeight: "45px" }}
          endIcon={<ExpandMoreIcon style={{ fontSize: 17 }} />}
        >
          <h3>Show All</h3>
        </Button>
        <IconButton
          aria-label="download tickets"
          // onClick={handleClickShowPassword}
          // onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          <FilterListIcon color="primary" style={{ fontSize: 32 }} />
        </IconButton> */}
        <Tooltip title="Download">
          <IconButton
            aria-label="download tickets"
            onClick={() => downloadExcel(props.tickets)}
            edge="end"
            disabled={props.loading}
          >
            <GetAppIcon color="primary" style={{ fontSize: 32 }} />
          </IconButton>
        </Tooltip>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon style={{ fontSize: 17 }} />}
          style={{
            width: 190,
            lineHeight: "45px",
            backgroundColor: `${theme.palette.accent.main}`,
            color: `${theme.palette.light.main}`,
            marginLeft: "1.4em",
            boxShadow: "none",
          }}
        >
          <NavLink to="/cms/feedback">
            <p>New Ticket</p>
          </NavLink>
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.ticketReducer.loading,
  tickets: state.ticketReducer.tickets,
  allTickets: state.ticketReducer.allTickets,
});

const mapDispatchToProps = (dispatch) => ({
  filterTickets: ({ searchTerms, searchField, searchType }) => {
    dispatch(searchTicketRequest({ searchTerms, searchField, searchType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(TableOption));
