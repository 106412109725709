import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import OrderDesc from "./OrderDesc";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PersonIcon from "@material-ui/icons/Person";
import DoneIcon from "@material-ui/icons/Done";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Button } from "../elements";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { formatDate, formatTimeInTwelve } from "../utils";
import Swal from "sweetalert2";
import { API_ROUTES, API_BASE_URL } from "../config";

const useStyles = makeStyles((theme) => ({
  sideBarWrapper: {
    width: "30vw",
    padding: "2em",
  },
  image: {
    width: "40px",
    height: "80px",
    margin: "auto 10px",
  },
  sideBarTop: {
    width: "100%",
    padding: "1em 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "1em",
  },
  customerInfo: {
    width: "100%",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "1.3em",
    fontFamily: "Lucida Grande, sans-serif",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.black.main,
  },
  phoneNumber: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: theme.palette.dark.main,
    textAlignt: "left",
    "& > span": {
      fontSize: "0.8em",
      color: theme.palette.primary.main,
    },
  },
  customerLocation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "0.9em",
    "& > span": {
      fontSize: "0.8em",
      color: theme.palette.primary.main,
    },
    "& > p": {
      color: theme.palette.dark.main,
    },
  },
  recentOrders: {
    marginTop: "2em",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "2em",
    "& > h3": {
      fontSize: "1.3em",
      color: theme.palette.black.main,
      textAlign: "left",
    },
  },
  orderList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "1.3em",
  },
  order: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.7em",
    minWidth: "180px",
    minHeight: "110px",
    background: theme.palette.light.main,
    borderRadius: "5px",
    boxShadow: "2px 2px 10px rgba(0,0,0,0.1)",
    cursor: "pointer",
    transition: "0.3s",
    padding: "1em",
    border: `1px solid #DADADA`,
    "& > p": {
      fontSize: ".8em",
    },
    "&:hover": {
      transform: "scale(1.04)",
      boxShadow: "2px 2px 20px rgba(0,0,0,0.2)",
    },
  },
  orderInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.3em",
    width: "100%",
    "& > p": {
      fontSize: ".8em",
    },
  },
  orderStatus: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.3em",
    width: "100%",
    height: "23px",
    // background: "red",
    borderRadius: "15px",
    fontSize: ".8em",
    textTransform: "capitalize",
    "& svg": {
      fontSize: "1em",
      margin: "0",
    },
  },
  farmName: {
    textAlign: "left",
    color: theme.palette.black.main,
    fontSize: "1em",
    fontWeight: "500",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  burdwanOrder: {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    background: theme.palette.primary.main,
    display: "grid",
    placeContent: "center",
    fontSize: "0.8em",
    color: "white",
  },
}));
export default function SideOrder(props) {
  const classes = useStyles();
  const data = JSON.parse(sessionStorage.getItem("formData"));
  const theme = useTheme();
  const [viewData, setViewData] = useState(null);
  const [formData, setformData] = useState(props.orders);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const history = useHistory();
  function showData(id) {
    setModalIsOpen(true);
    axios
      .get(`${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.FETCH_ORDERS}${id}/`)
      .then((val) => {
        setViewData(val.data.data);
      });
  }

  const handleEndCall = () => {
    const body = [
      {
        Mobile: data?.phone.length === 10 ? "91" + data?.phone : data?.phone,
        State: data?.state,
        Activity_Name: sessionStorage.getItem("form") ?? "",
        Call_Type: "Inbound",
      },
    ];
    let agentName = localStorage.getItem("agent_name");
    if (agentName) {
      body[0].Agent_Name = agentName;
    }
    Swal.fire({
      title: "Do you want to make the IVR Call?",
      showDenyButton: true,
      icon: "info",
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${API_BASE_URL.CRM_FAST_API}${API_ROUTES.TRIGGER_IVR}`, body)
          .then((res) => console.log(res))
          .catch((err) => {
            axios
              .post(
                `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.TRIGGER_IVR}`,
                body
              )
              .then((res) => console.log(res))
              .catch((err) => console.log(err));
          });
      }
    });
    let phoneNumber = sessionStorage.getItem("number");
    sessionStorage.clear();
    history.push({ pathname: "/sms-trigger", state: { phone: phoneNumber } });
  };
  return (
    <div className={classes.sideBarWrapper}>
      <div className={classes.sideBarTop}>
        <div className={classes.customerInfo}>{data?.name}</div>
        <div className={classes.customerLocation}>
          <span>
            <LocationOnIcon />
          </span>
          <p>
            {data?.village}, {data?.taluka}, {data?.district}, {data?.state}
          </p>
        </div>
        <p className={classes.phoneNumber}>
          <span>
            <PhoneIcon />
          </span>{" "}
          {data?.phone}
        </p>
        {data?.farmer_type && data?.farmer_type !== "" && (
          <p className={classes.phoneNumber}>Source : {data?.farmer_type}</p>
        )}
        <Button
          variant="contained"
          style={{
            width: "100%",
            margin: "auto",
            boxShadow: "none",
            background: theme.palette.accent.main,
            color: theme.palette.light.main,
            lineHeight: "40px",
          }}
          onClick={handleEndCall}
        >
          END CALL
        </Button>
      </div>
      <div className={classes.recentOrders}>
        <h3>All Transactions</h3>
        <div className={classes.orderList}>
          {formData?.map((order) => (
            <div
              className={classes.order}
              key={order.crm_order_id}
              onClick={() => showData(order.crm_order_id)}
            >
              <div className={classes.orderInfo}>
                <div className={classes.farmName}>{order.farm_name}</div>
                <p>
                  <DateRangeIcon
                    style={{
                      fontSize: "1em",
                      color: theme.palette.primary.main,
                    }}
                  />{" "}
                  {formatDate(order.job_start_time)}
                </p>
                <p>{formatTimeInTwelve(order.job_start_time)}</p>
              </div>
              <div
                className={classes.orderStatus}
                style={{
                  background: `${
                    order.status === "completed"
                      ? "rgba(0,255,0,0.1)"
                      : "rgba(255,0,0,0.1)"
                  }`,
                }}
              >
                <span>
                  {order.status === "completed" ? <DoneIcon /> : <PersonIcon />}
                </span>
                {order.status}
              </div>
              {order?.is_burdwan_order && (
                <span className={classes.burdwanOrder}>B</span>
              )}
            </div>
          ))}
        </div>
      </div>
      <OrderDesc
        name={data?.name}
        data={viewData}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        setViewData={setViewData}
      />
    </div>
  );
}
