import React, { useState, useEffect } from "react";
import { makeStyles, withTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter } from "@material-ui/core";
import { connect } from "react-redux";
import { formatDate, getData } from "../../utils";
import { Button } from "../elements";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: "100%",
    margin: ".5em 0",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: theme.palette.grey.main,
    color: "#706F6F",
  },
  body: {
    fontSize: 14,
    color: theme.palette.dark.main,
    fontWeight: "bold",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);

const ExistingTickets = ({ tractor, theme, ...props }) => {
  const classes = useStyles();
  const [tickets, setTickets] = useState(null);
  const [size, setSize] = useState(3);

  useEffect(() => {
    if (props.existingTickets) {
      let filteredTickets = props.existingTickets.filter(
        (ticket) => ticket.device_id === tractor.device_id
      );
      if (filteredTickets.length <= 3) {
        setSize(filteredTickets.length);
      } else {
        setSize(3);
      }
      setTickets(filteredTickets);
    }
  }, [tractor, props.existingTickets]);

  return (
    <div className={classes.tableContainer}>
      <h3>Existing Tickets</h3>
      <TableContainer style={{ marginTop: "1em" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Ticket ID</StyledTableCell>
              <StyledTableCell align="left">Issue Type</StyledTableCell>
              <StyledTableCell align="left">Device ID</StyledTableCell>
              <StyledTableCell align="left">Generation Time</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets &&
              tickets.slice(0, size).map((ticket) => (
                <StyledTableRow key={ticket.simha_ticket_id}>
                  <StyledTableCell align="left">
                    {getData(ticket.simha_ticket_id)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getData(ticket.issue_type)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {getData(ticket.device_id)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {formatDate(ticket.generation_time)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <StyledTableCell align="left">
                <Button
                  variant="contained"
                  style={{
                    width: 200,
                    lineHeight: "30px",
                    backgroundColor: `${theme.palette.accent.main}`,
                    color: `${theme.palette.light.main}`,
                    boxShadow: "none",
                  }}
                  onClick={() =>
                    setSize((prev) =>
                      prev === tickets.length ? 3 : tickets.length
                    )
                  }
                >
                  {tickets && tickets.length === size
                    ? "Show Less"
                    : "Show More"}
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  existingTickets: state.tractorReducer.existingTickets,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ExistingTickets));
