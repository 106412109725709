import React from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button } from "../elements";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { getData, getDateTime } from "../../utils";
import { useState } from "react";
import TicketForceCloseForm from "./TicketForceCloseForm";
import InputText from "./Fields/InputText";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import { updateTicketsRequest } from "../../store/action/cmsActions/fetchTicketAction";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { API_ROUTES, API_BASE_URL } from "../../config";

const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "fixed",
    top: "10%",
    left: "50%",
    bottom: "10%",
    minWidth: "500px",
    overflow: "scroll",
    transform: "translate(-50%,-0%)",
    outline: "none",
    border: "none",
    background: theme.palette.light.main,
    padding: "2em",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    "& h1,h2,h3,h4,h5,p": {
      margin: "0 !important",
      padding: "0 !important",
    },
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  ticketHeader: {
    width: "100%",
    padding: "1em 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `4px solid ${theme.palette.grey.main}`,
    "& > h2": {
      fontWeight: "600",
      fontSize: "1.3em",
      color: theme.palette.dark.main,
    },
    "& > span": {
      color: theme.palette.accent.main,
      cursor: "pointer",
    },
  },
  ticketDetail: {
    hright: "700px",
    margin: "1.4em 0",
    display: "flex",
    flex: 1,
    gap: "3em",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  ticketDetailLeft: {
    display: "flex",
    flex: 2,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "100%",

    "& > div": {
      margin: "auto 0",

      "& > p": {
        color: "#706F6F",
      },
      "& > h4": {
        color: theme.palette.dark.main,
      },
    },
  },
  ticketDetailRight: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "100%",

    "& > div": {
      margin: "auto 0",
      "& > p": {
        color: "#706F6F",
      },
      "& > h4": {
        color: theme.palette.dark.main,
      },
    },
  },
  ticketLog: {
    display: "flex",
    flex: 2,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "100%",
    marginLeft: "2em",
    paddingTop: "1em",
  },
  ticketLogInner: {
    minWidth: "300px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
    gap: "1em",
    "& > form": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      gap: "1em",
      flex: 1,
    },
  },
  prevLogs: {
    flex: 1,
    paddingTop: "1em",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    "& > p": {
      color: theme.palette.dark.main,
      fontWeight: "bold",
    },
    "& > ol": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "1em 0",
      width: "100%",
      maxHeight: "170px",
      overflowY: "scroll",
      "& > li": {
        listStyle: "none",
        color: theme.palette.dark.main,
        padding: ".2em 0",
      },
    },
  },
  ticketDetailActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actionBtn: {
    width: "50%",
    lineHeight: "44px",
  },
  btnOutlined: {
    border: `1px solid ${theme.palette.dark.main}`,
    color: theme.palette.accent.main,
    // fontSize: "1.3em",
  },
}));

function TicketDetail({ theme, showModal, handleModalOpen, ...props }) {
  const classes = useStyles();
  const history = useHistory();
  const [forceClose, setForceClose] = useState(false);
  const [callLogLoading, setCallLogLoading] = useState(false);

  const handleForceClose = () => {
    setForceClose((prev) => !prev);
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={handleModalOpen}
        aria-labelledby="ticket-detail-modal"
        aria-describedby="ticket-detail-modal"
      >
        {props.selectedTicket && (
          <div className={classes.modalStyle}>
            <div className={classes.ticketHeader}>
              <h2>Ticket Details</h2>
              <span onClick={() => handleModalOpen(false)}>Close X</span>
            </div>
            <div className={classes.ticketDetail}>
              <div className={classes.ticketDetailLeft}>
                <div>
                  <h4>{getData(props.selectedTicket.user_name)}</h4>
                  <h4>{getData(props.selectedTicket.user_phone)}</h4>
                  {/* <h4>Suresh Gupta</h4>
              <h4>9876543210</h4> */}
                </div>
                <div>
                  <h4>Bid</h4>
                  <h4>{getData(props.selectedTicket.state)}</h4>
                  {/* <h4>Maharashtra</h4> */}
                </div>
                <div>
                  <p>Created on</p>
                  {/* <h4>12/08/2020 - 08:37 pm</h4> */}
                  <h4>{getDateTime(props.selectedTicket.generation_time)}</h4>
                </div>
                <div>
                  <p>Issue Date</p>
                  <h4>
                    {new Date(
                      props.selectedTicket.generation_time
                    ).toLocaleDateString("en-US")}
                  </h4>
                  {/* <h4>12/08/2020</h4> */}
                </div>
                <div>
                  <p>SPOC</p>
                  <h4>{props.selectedTicket.spoc}</h4>
                  {/* <h4>Mandar</h4> */}
                </div>
                <div>
                  <p>Reason</p>
                  <h4>{props.selectedTicket.type}</h4>
                  {/* <h4>Customer Feedback</h4> */}
                </div>
              </div>
              <div className={classes.ticketDetailRight}>
                <div>
                  <p>Ticket Id</p>
                  <h4>{getData(props.selectedTicket.id)}</h4>
                </div>
                {/* <div>
                <p>HW batch</p>
                <h4>100</h4>
              </div> */}
                <div>
                  <p>Sim</p>
                  <h4>Airtel</h4>
                </div>
                <div>
                  <p>Device Id</p>
                  <h4>{getData(props.selectedTicket.device_id)}</h4>
                </div>
                <div>
                  <p>Warranty</p>
                  <h4>Yes</h4>
                </div>
                <div>
                  <p>Status</p>
                  <h4>{props.selectedTicket.status}</h4>
                  {/* <h4>Open</h4> */}
                </div>
                <div>
                  <p>Remarks</p>
                  <h4>NA</h4>
                </div>
                {/* <div>
                <p>Date of Problem</p>
                <h4>12/08/2020</h4>
              </div> */}
              </div>

              {props.selectedTicket.type !== "Inbound Call" && (
                <div className={classes.ticketLog}>
                  <Formik
                    initialValues={{
                      log: "",
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (values.log === "") {
                        errors.log = "*Required Field!";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setCallLogLoading(true);
                      axios
                        .post(
                          `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.RECORD_CALL_LOG}`,
                          {
                            id: props.selectedTicket.id,
                            log: values.log,
                          }
                        )
                        .then((res) => {
                          setCallLogLoading(false);
                          handleModalOpen(false);
                          values.log = "";
                          MySwal.fire({
                            icon: "success",
                            title: "Call Log Added",
                            showConfirmButton: true,
                            confirmButtonText: "Close",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                          }).then((isConfirm) => {
                            if (isConfirm) {
                              props.updateTickets({
                                spoc:
                                  localStorage.getItem("username") ||
                                  props.curSpoc,
                              });
                              history.push("/cms");
                            }
                          });
                        })
                        .catch((e) => {
                          setCallLogLoading(false);
                          handleModalOpen(false);
                        });
                      setSubmitting(false);
                    }}
                  >
                    {({ submitForm }) => (
                      <div className={classes.ticketLogInner}>
                        <Form>
                          <Field
                            name="log"
                            type="text"
                            id="log_input"
                            label="Add Log"
                            component={InputText}
                          />
                          <Button
                            type="submit"
                            // onClick={submitForm}
                            variant="contained"
                            style={{
                              lineHeight: "45px",
                              backgroundColor: `${theme.palette.accent.main}`,
                              color: `${theme.palette.light.main}`,
                              boxShadow: "none",
                            }}
                            disabled={callLogLoading}
                          >
                            Add Call Log
                          </Button>
                        </Form>

                        <div className={classes.prevLogs}>
                          <p>Logs</p>
                          {props.selectedTicket.call_log ? (
                            <ol>
                              {props.selectedTicket.call_log
                                .sort((a, b) =>
                                  a.timestamp > b.timestamp ? -1 : 1
                                )
                                .map((item) => (
                                  <li>
                                    ( {getDateTime(item.timestamp + "UTC")} ) :{" "}
                                    {item.log}
                                  </li>
                                ))}
                            </ol>
                          ) : (
                            <ol>
                              <li>No Call Log Found!</li>
                            </ol>
                          )}
                        </div>
                      </div>
                    )}
                  </Formik>
                </div>
              )}
            </div>
            <div className={classes.ticketDetailActions}>
              <Button
                variant="outlined"
                className={`${classes.actionBtn} ${classes.btnOutlined}`}
                // onClick={() => handleModalOpen(false)}
                onClick={() => {
                  props.selectedTicket.type === "Callback Request"
                    ? handleForceClose()
                    : handleModalOpen(false);
                }}
              >
                {props.selectedTicket.type === "Callback Request"
                  ? "FORCE CLOSE"
                  : "CLOSE"}
              </Button>
              <Button
                variant="contained"
                className={classes.actionBtn}
                style={{
                  // width: 190,
                  lineHeight: "45px",
                  backgroundColor: `${theme.palette.accent.main}`,
                  color: `${theme.palette.light.main}`,
                  marginLeft: "1.4em",
                  boxShadow: "none",
                  opacity: `${
                    props.selectedTicket.status === "Closed" ? 0.5 : 1
                  }`,
                }}
                disabled={props.selectedTicket.status === "Closed"}
              >
                {props.selectedTicket.type === "Welcome Call" ? (
                  <NavLink to={`/cms/welcome?id=${props.selectedTicket.id}`}>
                    ACTION
                  </NavLink>
                ) : (
                  <NavLink
                    to={`/cms/feedback?phone=${props.selectedTicket.user_phone}`}
                  >
                    ACTION
                  </NavLink>
                )}
              </Button>
            </div>
          </div>
        )}
      </Modal>
      <TicketForceCloseForm
        handleForceClose={handleForceClose}
        forceClose={forceClose}
        handleModalOpen={handleModalOpen}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  selectedTicket: state.ticketReducer.selectedTicket,
});

const mapDispatchToProps = (dispatch) => ({
  updateTickets: ({ spoc }) => {
    dispatch(updateTicketsRequest({ spoc }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(TicketDetail));
