import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddFarm from "./AddFarm";

import Switch from "@material-ui/core/Switch";
import Modal from "react-modal";
import machine from "../../src/assets/rental_machine.svg";
import Clock from "../assets/rental_clock.svg";
import Calander from "../assets/rental_calander.svg";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";

import Card from "@material-ui/core/Card";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import person from "../assets/rental_person.svg";
import wheel from "../assets/rental_wheel.svg";
import group from "../assets/rental_group.svg";
import Formnav from "./Formnav";
import Select from "@material-ui/core/Select";
import { useFormik } from "formik";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import SideOrder from "./SideOrder";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { generateOrderId } from "../utils";
import { fetchDataRequest } from "../store/action/fetchDataAction.js";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Skeleton from "@material-ui/lab/Skeleton";
import { API_ROUTES, API_BASE_URL } from "../config";

const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "auto",
    backgroundColor: "#FAF3FF",
  },
  image: {
    width: "36px",
    height: "80px",
    margin: "auto 10px",
  },
  dropdownStyle: {
    width: "600px",
    height: "284px",
  },
  card: {
    padding: "120px 120px",
    fontSize: "30px",
    backgroundColor: "rgb(243, 112, 33)",
    color: "white",
    fontWeight: "600",
    Padding: "12px 24px",
    borderRadius: "6px",
    borderBottom: "4px solid hsl(221, 89%, 85%)",
    transition: "all 0.1s ease-in-out",
    "&:hover": {
      borderBottomWidth: "0",
      marginTop: "3px",
    },
  },
  text: {
    fontSize: "20px",
    margin: "8px auto",

    width: "auto",
  },
  select: {
    width: "48vw",
  },
  text1: {
    fontSize: "20px",
    margin: "8px 0",

    width: "auto",
  },
  verticalLine: {
    width: "1px" /* Line width */,
    backgroundColor: "#DADADA" /* Line color */,
    height: "100%" /* Override in-line if you want specific height. */,
    float: "left",
  },
  hubManager: {
    fontSize: "1em",
    fontWeight: "600",
    "& > span": {
      fontWeight: "normal",
      color: theme.palette.dark.main,
    },
  },
  hubDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.3em",
    "& > div": {
      display: "flex",
      alignItems: "flex-start",
      gap: "1em",
      "& > p": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.dark.main,
        "& > span": {
          fontSize: "0.8em",
          color: theme.palette.primary.main,
        },
      },
    },
  },
  pulseToggler: {
    display: "flex",
    alignItems: "center",
    "& > p": {
      fontSize: "0.9em",
    },
    "& > * + *": {
      margin: "0 0 0 0.3em",
    },
  },
  otpContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1.2em 0",
    "& > * + *": {
      marginLeft: "2em",
    },
  },
}));
function RentalOrder2({ number, username, val, setVal, ...props }) {
  const [data, setData] = useState({});
  const [loading, isLoading] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [list, setList] = useState([]);
  const [farms, setFarms] = useState([]);
  const [farm_land, setFarmLand] = useState({});
  const [submitFarm, setSubmitFarm] = useState("");
  const [search, setSearch] = useState([]);
  const token = localStorage.getItem("token");
  const [color, setColor] = useState("");
  const [date, setDate] = useState(
    new Date(new Date().setHours(new Date().getHours() + 6))
  );
  const [newFarm, setNewFarm] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [area, setArea] = useState("");
  const [duration, setDuration] = useState("");
  const [addedFarm, setAddedFarm] = useState("");
  const [dateData, setDateData] = useState("");
  const [checkUnit, setCheckUnit] = useState("");
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [servicableBy, setServicableBy] = useState(null);
  const [dot, setDot] = useState([]);
  const [unit, setUnit] = useState("Acre");
  const [submitIsOpen, setSubmitIsOpen] = useState(false);
  const [timeUnit, setTimeUnit] = useState("Hour");
  const [orders, setOrders] = useState([]);
  const [show, setShow] = useState(false);
  const [benchMark, setBenchMark] = useState(
    new Date(new Date().setHours(new Date().getHours() + 6))
  );
  const [farm_select, setFarmSelect] = useState("");
  const [orderId, setOrderId] = useState("");
  const inputRef = useRef(null);
  const [priceIcon, setPriceIcon] = useState("");
  const [timeModal, setTimeModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [flag, setFlag] = useState(true);
  const [type, SetType] = useState("");
  const [centerDetails, setCenterDetails] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [showSelectFarm, setShowSelectFarm] = useState(true);
  const [burdwan, setBurdwan] = useState(false);

  const [pulseOrder, setPulseOrder] = useState(false);
  const [nPatches, setNPatches] = useState(1);
  const [comments, setComments] = useState("");
  const [otp, setOtp] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceLoading, setTotalPriceLoading] = useState(false);
  const [orderIdFromResponse, setOrderIdFromResponse] = useState(null);

  let [unitArr, setUnitArr] = useState(["Acre", "Hectare", "Guntha"]);
  let unit_time = ["Hour", "Minute"];
  useEffect(() => {
    let body = {
      farmer_phone: sessionStorage.getItem("number"),
    };
    props.sendRequest(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (new Date().getHours() >= 13 && new Date().getHours() < 19) {
      let d = new Date();
      d.setHours(d.getHours() + 18);
      // d.setDate(d.getDate() + 1);
      setDate(d);
      setBenchMark(d);
    } else if (new Date().getHours() >= 19) {
      let d = new Date();
      d.setHours(7);
      d.setDate(d.getDate() + 1);
      setDate(d);
      setBenchMark(d);
    } else if (new Date().getHours() < 3) {
      let d = new Date();
      d.setHours(7);

      setDate(d);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      job_start_time: "",
      duration: "",
      area: null,
      category: "",
      subcategory: "",
      coupon_code: "",
      comments: "",
      price_unit: "",
      price: 0,
      rate_card: "",
    },
    onSubmit: (values, e) => {
      if (formik.values.job_start_time === "") {
        let month = date.getMonth() + 1;
        let date_unit = date.getDate();
        if (month < 10) {
          month = "0" + month;
        }
        if (date_unit < 10) {
          date_unit = "0" + date_unit;
        }
        formik.values.job_start_time =
          date.getFullYear() +
          "-" +
          month +
          "-" +
          date_unit +
          " " +
          date.toTimeString().split(" ")[0];
      }
      if (formik.values.price === 0 && servicableBy && servicableBy === "hub") {
        MySwal.fire({
          icon: "warning",
          title: "Oops...",
          text: "Please select the Price (Charging as per)",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
        return;
      }

      if (timeUnit.toLowerCase() === "hour" && formik.values.duration !== "") {
        formik.values.duration = parseFloat(formik.values.duration) * 60 * 60;
      } else if (
        timeUnit.toLowerCase() === "minute" &&
        formik.values.duration !== ""
      ) {
        formik.values.duration = parseFloat(formik.values.duration) * 60;
      }

      if (unit.toLowerCase() === "hectare" && formik.values.area !== null) {
        formik.values.area = parseFloat(formik.values.area) / 0.4046;
      } else if (
        unit.toLowerCase() === "guntha" &&
        formik.values.area !== null
      ) {
        formik.values.area = parseFloat(formik.values.area) / 40;
      } else if (
        unit.toLowerCase() === "bigha" &&
        formik.values.area !== null
      ) {
        formik.values.area = parseFloat(formik.values.area) * 0.4;
      }

      setData({
        job_start_time: values.job_start_time,
        duration: values.duration,
        area: values.area,
        category: values.category,
        subcategory: values.subcategory,
        comments: values.comments,
        coupon_code: values.coupon,
        price_unit: values.price_unit
          ? values.price_unit
          : burdwan
          ? "acre"
          : "",
        price: values.price,
        rate_card: values.rate_card,
        order_id: generateOrderId(sessionStorage.getItem("number")),
      });

      if (
        (Object.keys(farm_land || {}).length !== 0 &&
          (searchTerm !== "" || inputValue !== "") &&
          area !== "" &&
          duration !== "") ||
        burdwan
      ) {
        setSubmitIsOpen(true);
      }
    },
  });

  useEffect(() => {
    let farmData = JSON.parse(sessionStorage.getItem("formData"));
    let body = {
      is_servicable: type === "hub" ? true : false,
      center_type: type,
      new_farm: newFarm,
      farm_details: farm_land,
      farmer_details: {
        ...farmData,
        farm_area:
          farmData.farm_area === "" ? farm_land.area : farmData.farm_area,
      },
      service_details: {
        order_id: data?.order_id,
        job_start_time: data?.job_start_time,
        duration: data?.duration,
        area: data?.area,
        category: data?.category,
        subcategory: data?.subcategory,
        comments: data?.comments,
        coupon_code: data?.coupon_code,
        price_unit: data?.price_unit,
        price: data?.price,
        rate_card: data?.rate_card,
        is_burdwan_order: burdwan,
        n_patches: burdwan ? (!isNaN(nPatches) ? parseInt(nPatches) : 1) : null,
      },
    };
    if (burdwan) {
      body.service_details.to_pulse = pulseOrder;
    }
    let config = {
      ApiKey: `41k59h2PEWniwk3QD0IjnYFYC7X92qPz`,
      Authorization: `Token ${token}`,
    };
    if (Object.keys(data || {}).length !== 0 && body.service_details.order_id) {
      setTotalPriceLoading(true);
      axios
        .post(
          `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.CREATE_NEW_ORDER}`,
          body,
          {
            headers: config,
          }
        )
        .then((val) => {
          setTotalPriceLoading(false);
          // console.log(val?.data?.data?.crm_order_id);
          setOrderId(val?.data?.data?.crm_order_id);
          setOtp(val?.data?.data?.service_details?.otp);
          setTotalPrice(val?.data?.data?.service_details?.total_price);
          setOrderIdFromResponse(val?.data?.data?.order_id ?? "");
        })
        .catch((e) => {
          setTotalPriceLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (props?.value?.is_servicable === false) {
      if (!location.state) {
        history.replace("/cancelOrder2");
      } else if (!location?.state?.data?.isBurdwan) {
        history.replace("/cancelOrder2");
      }
    }
    if (props?.value?.servicable_by === "krishe center") {
      let res = { dist_km: Number.POSITIVE_INFINITY };
      res = props.value?.center_details.reduce((prev, cur) =>
        prev.dist_km < cur.dist_km ? prev : cur
      );
      setCenterDetails(res);
    }
    setData(props?.value);
    setServicableBy(props?.value?.servicable_by);
    console.log(props?.value);
    setFarms(Object.values(props?.value?.farms || {}));
    setList(props?.value?.available_services);
    SetType(props?.value?.servicable_by);

    setOrders(props?.value?.orders);
    if (props?.value?.servicable_by === "hub")
      setSearch(props?.value?.available_services);
    isLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, props.servicableLoading]);

  useEffect(() => {
    if (pulseOrder) {
      setDate(getAdditionalDate(1));
    } else {
      setDate(new Date());
    }
  }, [pulseOrder]);
  // useEffect(() => {
  //   let body = {
  //     farmer_phone: sessionStorage.getItem("number"),
  //   };
  //   let config = {
  //     ApiKey: `41k59h2PEWniwk3QD0IjnYFYC7X92qPz`,
  //     Authorization: `Token ${token}`,
  //   };

  //   axios
  //     .post(
  //       `https://krishe-crm.herokuapp.com/orders/check_servicability/`,
  //       body
  //     )
  //     .then((val) => {
  //       if (val.data.data.is_servicable === false) {
  //         history.push("/cancelOrder2");
  //       }
  //       console.log(JSON.stringify(val.data) + " " + JSON.stringify(body));
  //       // console.log(val.data.body.data[0]);
  //       setData(val.data.data);
  //       setFarms(Object.values(val.data.data.farms));
  //       setList(val.data.data.available_services);
  //       SetType(val.data.data.servicable_by);

  //       setOrders(val.data.data.orders);
  //       if (val.data.data.servicable_by === "hub")
  //         setSearch(val.data.data.available_services);

  //       // console.log('2');
  //       isLoading(true);
  //     });
  // }, []);
  useEffect(() => {
    let locationState = location?.state ?? undefined;
    if (locationState && locationState?.data?.implements) {
      setBurdwan(locationState?.data?.isBurdwan);
      setDot(locationState.data.implements.sort());
      setShowSelectFarm(locationState?.data?.selectFarm ?? true);
      if (locationState?.data?.isBurdwan) {
        setSearchTerm(locationState?.data?.implements[0] ?? "");
        if (pulseOrder) {
          setDate(getAdditionalDate(1));
        } else {
          setDate(new Date());
        }
        // let curDate = new Date();
        // if (pulseOrder) {
        //   curDate.setDate(pulseOrder ? new Date().getDate() + 1 : new Date());
        //   handleDate(curDate);
        // } else {
        //   handleDate(new Date());
        // }
        // curDate.setDate(pulseOrder ? new Date().getDate() + 1 : new Date());
      }
      if (locationState?.data?.area_units) {
        setUnitArr(
          locationState?.data?.area_units ?? ["Acre", "Hectare", "Guntha"]
        );
      }
    } else {
      if (servicableBy && servicableBy !== "hub") {
        let config = {
          ApiKey: `41k59h2PEWniwk3QD0IjnYFYC7X92qPz`,
          Authorization: `Token ${token}`,
        };
        axios
          .get(`${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.FETCH_SERVICES}`, {
            headers: config,
          })
          .then((val) => {
            setDot(val.data.data.sort());
            console.log(val);
            console.log("1");
            // isLoading(true)
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicableBy]);

  useEffect(() => {
    setShow(true);
  }, [orders]);

  useEffect(() => {
    setSubmitFarm(farm_land);
    setFarmSelect(farm_land.farm_name);
    // console.log(farm_land);
  }, [farm_land, farms]);

  useEffect(() => {
    if (searchTerm !== "") {
      if (type === "hub" && priceIcon === "hour") {
        if (search[searchTerm]["standard_price_per_hour"]) {
          sPrice(
            search[searchTerm]["standard_price_per_hour"],
            "hour",
            "standard_price_per_hour"
          );
          setColor("1");
        } else if (search[searchTerm]["w_price_per_hour"]) {
          sPrice(
            search[searchTerm]["w_price_per_hour"],
            "hour",
            "w_price_per_hour"
          );
          setColor("2");
        } else if (search[searchTerm]["wo_price_per_hour"]) {
          sPrice(
            search[searchTerm]["wo_price_per_hour"],
            "hour",
            "wo_price_per_hour"
          );
          setColor("3");
        }
      } else if (type === "hub" && priceIcon === "day") {
        if (search[searchTerm]["standard_price_per_day"]) {
          sPrice(
            search[searchTerm]["standard_price_per_day"],
            "day",
            "standard_price_per_day"
          );
          setColor("1");
        } else if (search[searchTerm]["w_price_per_day"]) {
          sPrice(
            search[searchTerm]["w_price_per_day"],
            "day",
            "w_price_per_day"
          );
          setColor("2");
        } else if (search[searchTerm]["wo_price_per_day"]) {
          sPrice(
            search[searchTerm]["wo_price_per_day"],
            "day",
            "wo_price_per_day"
          );
          setColor("3");
        }
      } else if (type === "hub" && priceIcon === "acre") {
        if (search[searchTerm]["standard_price_per_acre"]) {
          sPrice(
            search[searchTerm]["standard_price_per_acre"],
            "acre",
            "standard_price_per_acre"
          );
          setColor("1");
        } else if (search[searchTerm]["w_price_per_acre"]) {
          sPrice(
            search[searchTerm]["w_price_per_acre"],
            "acre",
            "w_price_per_acre"
          );
          setColor("2");
        } else if (search[searchTerm]["wo_price_per_acre"]) {
          sPrice(
            search[searchTerm]["wo_price_per_acre"],
            "acre",
            "wo_price_per_acre"
          );
          setColor("3");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, search]);

  const handleArea = (event) => {
    console.log(event.target.value);

    if (unit === "Acre") {
      if (burdwan) {
        if (parseFloat(event.target.value) <= 3 || event.target.value === "") {
          formik.values.area = parseFloat(event.target.value);
          setArea(event.target.value);
        }
      } else {
        if (parseInt(event.target.value) <= 50 || event.target.value === "") {
          formik.values.area = parseFloat(event.target.value);
          setArea(event.target.value);
        }
      }
    }
    if (unit === "Hectare") {
      if (parseInt(event.target.value) <= 20 || event.target.value === "") {
        formik.values.area = parseFloat(event.target.value);
        setArea(event.target.value);
      }
    }
    if (unit === "Guntha") {
      if (parseInt(event.target.value) <= 2000 || event.target.value === "") {
        formik.values.area = parseFloat(event.target.value);
        setArea(event.target.value);
      }
    }
    if (unit === "Bigha") {
      if (parseInt(event.target.value) <= 125 || event.target.value === "") {
        formik.values.area = parseFloat(event.target.value);
        setArea(event.target.value);
      }
    }
  };

  const handleNPatches = (event) => {
    if (event.target.value === "" || parseInt(event.target.value) <= 20) {
      setNPatches(event.target.value);
    }
  };

  const handleComments = (event) => {
    setComments(event.target.value);
    formik.setFieldValue("comments", event.target.value, false);
  };

  const getTotalPrice = (unit, price, area, duration) => {
    switch (unit) {
      case "day":
        return price;
      case "acre":
        return (parseFloat(price) * parseFloat(area)).toFixed(2);
      case "hour":
        return ((parseFloat(price) * parseFloat(duration)) / 3600).toFixed(2);
      default:
        break;
    }
    return price;
  };

  const handleChange = (event) => {
    setFarmLand(event.target.value);
    console.log("=====" + event.target.value);
    if (event.target.value === "addFarm2") {
      setNewFarm(true);
      setModalIsOpen(true);
    }
    if (event.target.value === "addFarm") {
      setNewFarm(true);
      setModalIsOpen(true);
    }
  };
  // const submit = () => {
  //   if (
  //     Object.keys(farm_land || {}).length !== 0 &&
  //     (searchTerm !== "" || inputValue !== "") &&
  //     area !== "" &&
  //     duration !== ""
  //   )
  //     setSubmitIsOpen(true);
  // };
  const handleUnit = (event) => {
    event.target.value = event.target.value.toLowerCase();
    if (event.target.value === "hectare") setUnit("Hectare");
    else if (event.target.value === "acre") setUnit("Acre");
    else if (event.target.value === "bigha") setUnit("Bigha");
    else setUnit("Guntha");
  };
  const handleSearch = (val) => {
    if (type === "hub") {
      let index = Object.keys(search)?.indexOf(val);

      let v = Object.keys(
        Object.values(props?.value?.available_services || {})[index] || {}
      )[0]?.split("_")[3];

      setPriceIcon(v);
    }

    setSearchTerm(val);

    formik.values.category = val;
  };
  const handleSearch2 = (val) => {
    setInputValue(val);
    formik.values.category = val;
  };

  const handleTimeUnit = (e) => {
    e.target.value = e.target.value.toLowerCase();
    if (e.target.value === "hour") setTimeUnit("Hour");
    else setTimeUnit("Minute");
  };
  const handleCoupon = (event) => {
    setCoupon(event.target.value);
    formik.values.coupon = event.target.value;
  };
  const handleDuration = (event) => {
    setDuration(event.target.value);
    formik.values.duration = parseFloat(event.target.value);
  };

  const handleJobTime = (event) => {
    if (event < new Date().setHours(new Date().getHours() + 6)) {
    } else if (event < benchMark) {
    } else {
      if (
        (event.getHours() >= 19 && event.getHours() < 24) ||
        (event.getHours() >= 0 && event.getHours() <= 6)
      ) {
        setTimeModal(true);
      } else {
        console.log(event.toTimeString());
        let month = date.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let date_unit = date.getDate();

        if (date_unit < 10) {
          date_unit = "0" + date_unit;
        }
        setDate(event);
        formik.values.job_start_time =
          event.getFullYear() +
          "-" +
          month +
          "-" +
          date_unit +
          " " +
          event.toTimeString().split(" ")[0];
      }
    }
  };

  const handleDate = (event) => {
    if (burdwan) {
      setDateData(
        event.getFullYear() + "-" + event.getMonth() + "-" + event.getDate()
      );
      setDate(event);
    } else {
      if (event < new Date().setHours(new Date().getHours() + 6)) {
      } else {
        setDateData(
          event.getFullYear() + "-" + event.getMonth() + "-" + event.getDate()
        );
        setDate(event);
      }
    }
  };

  const getAdditionalDate = (nd) => {
    let curDate = new Date();
    curDate.setDate(new Date().getDate() + nd);
    return curDate;
  };

  function sPrice(price, unit, rateCard) {
    formik.values.price = parseFloat(price);
    setCheckUnit(unit);
    formik.values.price_unit = unit;
    formik.values.rate_card = rateCard;
  }
  const handleFlag = () => {
    setFlag(true);
  };
  const handleFlag2 = () => {
    setFlag(false);
  };
  //   const [url,setUrl]=useState('');
  if (!props.value) {
    return <CircularProgress></CircularProgress>;
  }
  return (
    <div className={classes.row}>
      <Card
        style={{
          width: "100%",
          height: "100%",
          margin: "auto 80px",
          marginTop: "1.5em",
          overflow: "scroll",
          backgroundColor: "white",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", margin: "20px" }}>
          <div style={{ zIndex: "3" }}>
            <ArrowBackIcon
              onClick={() => history.push({ pathname: "/nav" })}
              style={{
                float: "left",
                // zIndex: "3",
                margin: "10px",
                width: "30px",
                color: "#652078",
                cursor: "pointer",
              }}
            />
            <span
              onClick={() => history.push({ pathname: "/nav" })}
              style={{
                float: "left",
                cursor: "pointer",
                verticalAlign: "middle",
                color: "#652078",
                fontFamily: "Lucida Grande, sans-serif",
                margin: "10px",
              }}
            >
              Back To List
            </span>
          </div>
          <Formnav val={val} setVal={setVal} />
        </div>
        <hr
          style={{ border: "none", backgroundColor: "#DADADA", height: "1px" }}
        />
        <form onSubmit={formik.handleSubmit} autoComplete="none">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <main style={{ height: "100%", width: "70%" }}>
              <div
                style={{
                  maxWidth: "90%",
                  width: "80%",
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "space-evenly",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-evenly",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "20px",
                    marginBottom: "20px",
                    alignItems: "center",
                  }}
                >
                  {showSelectFarm && (
                    <FormControl
                      variant="outlined"
                      size="small"
                      required="true"
                      style={{
                        width: 260,
                        // float: "center",
                        margin: "10px 0",
                        // width: "20%",
                      }}
                    >
                      {" "}
                      <InputLabel

                      // htmlFor="outlined-age-native-simple"
                      >
                        Select Farm
                      </InputLabel>
                      <Select
                        labelId="demo-customized-select-label"
                        id="demo-simple-select-outlined"
                        multiline={2}
                        autoWidth={true}
                        value={farm_land.farm_name}
                        onOpen={() => handleFlag()}
                        onClose={() => handleFlag2()}
                        input={
                          <OutlinedInput
                            labelWidth={94}
                            name="members"
                            id="members"
                          />
                        }
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                        onChange={handleChange}
                        style={{ width: "340px", height: "50px" }}
                      >
                        {farms?.map((val) => {
                          return (
                            <MenuItem
                              style={{
                                width: "571px",
                                height: "61px",
                                whiteSpace: "normal",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                              value={val}
                            >
                              {flag && (
                                <b
                                  style={{
                                    fontSize: "16px",
                                    width: "276px",
                                    height: "18px",
                                    color: "#575756",
                                  }}
                                >
                                  {" "}
                                  {val.farm_name}
                                </b>
                              )}

                              {flag && (
                                <p
                                  style={{
                                    fontSize: "14px",
                                    width: "534px",
                                    height: "15px",
                                    color: "#575756",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  Nearest Village: {val.nearest_village}, Area:{" "}
                                  {area !== null ? val.area : "0"} Acre, Crops:{" "}
                                  {val.crops.join(", ")}
                                  {area !== null ? area : "0"}
                                </p>
                              )}
                              {!flag && (
                                <b
                                  style={{
                                    fontSize: "16px",
                                    width: "276px",
                                    height: "18px",
                                    color: "#575756",
                                  }}
                                >
                                  {" "}
                                  {val.farm_name}
                                </b>
                              )}
                            </MenuItem>
                          );
                        })}
                        <hr />
                        <MenuItem
                          value="addFarm"
                          style={{
                            color: "rgb(243, 112, 33)",
                            width: "571px",
                            height: "61px",
                          }}
                        >
                          {addedFarm === "" ? "ADD NEW FARM" : addedFarm}
                        </MenuItem>
                        {addedFarm !== "" && (
                          <MenuItem
                            value="addFarm2"
                            style={{
                              color: "rgb(243, 112, 33)",
                              width: "571px",
                              height: "61px",
                            }}
                          >
                            ADD NEW FARM
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  )}
                  {data?.is_servicable && (
                    <>
                      {data?.hub_details &&
                      data?.servicable_by === "hub" &&
                      Object.keys(data?.hub_details).length !== 0 ? (
                        <div className={classes.hubDetails}>
                          <h3 className={classes.hubManager}>
                            {data?.hub_details?.manager}{" "}
                            <span>
                              ({`${data?.hub_details?.type} Manager`})
                            </span>
                          </h3>
                          <div>
                            <p>
                              <span>
                                <PhoneIcon />
                              </span>
                              {parseInt(data?.hub_details?.phone)}
                            </p>
                            <p>
                              <span>
                                <LocationOnIcon />
                              </span>
                              {data?.hub_details?.name}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className={classes.hubDetails}>
                          <h3 className={classes.hubManager}>
                            {centerDetails?.manager}{" "}
                            <span>({`${centerDetails?.type} Manager`})</span>
                          </h3>
                          <div>
                            <p>
                              <span>
                                <PhoneIcon />
                              </span>
                              {parseInt(centerDetails?.phone)}
                            </p>
                            <p>
                              <span>
                                <LocationOnIcon />
                              </span>
                              {centerDetails?.name}
                            </p>
                          </div>
                        </div>
                        // <b
                        //   className={classes.text1}
                        //   style={{
                        //     textTransform: "capitalize",
                        //     fontSize: "16px",
                        //     width: "200px",
                        //   }}
                        // >
                        //   Served By: {data?.servicable_by} (
                        //   {data?.servicable_centers && data?.servicable_centers[0]})
                        // </b>
                      )}
                    </>
                  )}

                  {burdwan && (
                    <div className={classes.pulseToggler}>
                      <p>My RE</p>
                      <Switch
                        checked={pulseOrder}
                        onChange={(event) =>
                          setPulseOrder((prev) => event.target.checked)
                        }
                        color="primary"
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                      <p>Pulse Order</p>
                    </div>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    // justifyContent: "space-evenly",
                    justifyContent: "space-between",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                >
                  <b className={classes.text} style={{ float: "left" }}>
                    Select Implement:
                  </b>

                  {/* <FormControl
                  variant="outlined"
                  
                  size="small"
                  style={{
                    width: 740,
                    // float: "center",
                    margin: "10px auto",
                    // width: "20%",
                  }}
                >  <InputLabel id="demo-simple-select-outlined-label">Machinery Name</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={farm_land.name}
                    onChange={handleChange}
                    style={{ width: "740px",height:"50px" }}
                  >


                      </Select>
                </FormControl> */}
                  <div>
                    {type === "hub" ? (
                      <FormControl
                        variant="outlined"
                        required="true"
                        size="medium"
                      >
                        {" "}
                        <InputLabel id="demo-simple-select-outlined-label">
                          Machinery Name
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={(event, newValue) => {
                            handleSearch(event.target.value);
                          }}
                          value={searchTerm}
                          style={{
                            // display: "flex",
                            margin: "10px auto",
                            //  width: "42rem",
                            // width: "100%",
                            height: "50px",
                          }}
                          className={classes.select}
                          input={
                            <OutlinedInput
                              labelWidth={0}
                              name="members"
                              id="members"
                            />
                          }
                        >
                          {Object.keys(search)?.map((val) => {
                            return <MenuItem value={val}>{val}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      <Autocomplete
                        freeSolo
                        id="combo-box-demo"
                        disableClearable
                        options={dot}
                        getOptionLabel={(option) => option}
                        value={searchTerm}
                        onChange={(event, newValue) => {
                          handleSearch(newValue);
                        }}
                        disabled={burdwan}
                        inputValue={inputValue}
                        onInputChange={(event, newValue) => {
                          handleSearch2(newValue);
                        }}
                        style={{
                          margin: "10px auto",
                          // width: "44rem",
                          width: "100%",
                          height: "50px",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose Implements"
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            //   onChange={(event)=>{
                            //     handleSearch2(event.target.value);
                            //  }}
                          />
                        )}
                      />
                    )}
                  </div>
                </div>

                {searchTerm !== "" && type === "hub" && priceIcon === "hour" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-evenly",
                      justifyContent: "space-between",
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                  >
                    <b className={classes.text} style={{ float: "left" }}>
                      Charging as per:
                    </b>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly ",
                        width: "auto",
                      }}
                    >
                      {search[searchTerm]["standard_price_per_hour"] && (
                        <Button
                          variant="outlined"
                          style={{
                            color: color === "1" ? "#F37021" : "",
                            height: "50px",
                            width: "200px",
                          }}
                          onClick={() => {
                            sPrice(
                              search[searchTerm]["standard_price_per_hour"],
                              "hour",
                              "standard_price_per_hour"
                            );
                            setColor("1");
                          }}
                        >
                          ₹{search[searchTerm]["standard_price_per_hour"]} per
                          hour
                        </Button>
                      )}

                      {search[searchTerm]["w_price_per_hour"] && (
                        <Button
                          variant="outlined"
                          style={{
                            color: color === "2" ? "#F37021" : "",
                            height: "50px",
                            width: "200px",
                          }}
                          onClick={() => {
                            sPrice(
                              search[searchTerm]["w_price_per_hour"],
                              "hour",
                              "w_price_per_hour"
                            );
                            setColor("2");
                          }}
                        >
                          ₹{search[searchTerm]["w_price_per_hour"]} with
                          operator per hour
                        </Button>
                      )}
                      {search[searchTerm]["wo_price_per_hour"] && (
                        <Button
                          variant="outlined"
                          style={{
                            color: color === "3" ? "#F37021" : "",
                            height: "50px",
                            width: "200px",
                          }}
                          onClick={() => {
                            sPrice(
                              search[searchTerm]["wo_price_per_hour"],
                              "hour",
                              "wo_price_per_hour"
                            );
                            setColor("3");
                          }}
                        >
                          ₹{search[searchTerm]["wo_price_per_hour"]} without
                          operator per hour
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                {searchTerm !== "" && type === "hub" && priceIcon === "day" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-evenly",
                      justifyContent: "space-between",
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                  >
                    <b className={classes.text} style={{ float: "left" }}>
                      Charging as per:
                    </b>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly ",
                        width: "auto",
                      }}
                    >
                      {search[searchTerm]["standard_price_per_day"] && (
                        <Button
                          variant="outlined"
                          style={{
                            color: color === "1" ? "#F37021" : "",
                            height: "50px",
                            width: "200px",
                          }}
                          onClick={() => {
                            sPrice(
                              search[searchTerm]["standard_price_per_day"],
                              "day",
                              "standard_price_per_day"
                            );
                            setColor("1");
                          }}
                        >
                          ₹{search[searchTerm]["standard_price_per_day"]} per
                          day
                        </Button>
                      )}

                      {search[searchTerm]["w_price_per_day"] && (
                        <Button
                          variant="outlined"
                          style={{
                            color: color === "2" ? "#F37021" : "",
                            height: "50px",
                            width: "200px",
                          }}
                          onClick={() => {
                            sPrice(
                              search[searchTerm]["w_price_per_day"],
                              "day",
                              "w_price_per_day"
                            );
                            setColor("2");
                          }}
                        >
                          ₹{search[searchTerm]["w_price_per_day"]} with operator
                          per day
                        </Button>
                      )}
                      {search[searchTerm]["wo_price_per_day"] && (
                        <Button
                          variant="outlined"
                          style={{
                            color: color === "3" ? "#F37021" : "",
                            height: "50px",
                            width: "200px",
                          }}
                          onClick={() => {
                            sPrice(
                              search[searchTerm]["wo_price_per_day"],
                              "day",
                              "wo_price_per_day"
                            );
                            setColor("3");
                          }}
                        >
                          ₹{search[searchTerm]["wo_price_per_day"]} without
                          operator per day
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                {searchTerm !== "" && type === "hub" && priceIcon === "acre" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-evenly",
                      justifyContent: "space-between",
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                  >
                    <b className={classes.text} style={{ float: "left" }}>
                      Charging as per:
                    </b>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly ",
                        width: "auto",
                      }}
                    >
                      {search[searchTerm]["standard_price_per_acre"] && (
                        <Button
                          variant="outlined"
                          style={{
                            color: color === "1" ? "#F37021" : "",
                            height: "50px",
                            width: "200px",
                          }}
                          onClick={() => {
                            sPrice(
                              search[searchTerm]["standard_price_per_acre"],
                              "acre",
                              "standard_price_per_acre"
                            );
                            setColor("1");
                          }}
                        >
                          ₹{search[searchTerm]["standard_price_per_acre"]} per
                          acre
                        </Button>
                      )}

                      {search[searchTerm]["w_price_per_acre"] && (
                        <Button
                          variant="outlined"
                          style={{
                            color: color === "2" ? "#F37021" : "",
                            height: "50px",
                            width: "200px",
                          }}
                          onClick={() => {
                            sPrice(
                              search[searchTerm]["w_price_per_acre"],
                              "acre",
                              "w_price_per_acre"
                            );
                            setColor("2");
                          }}
                        >
                          ₹{search[searchTerm]["w_price_per_acre"]} with
                          operator per acre
                        </Button>
                      )}
                      {search[searchTerm]["wo_price_per_acre"] && (
                        <Button
                          variant="outlined"
                          style={{
                            color: color === "3" ? "#F37021" : "",
                            height: "50px",
                            width: "200px",
                          }}
                          onClick={() => {
                            sPrice(
                              search[searchTerm]["wo_price_per_acre"],
                              "acre",
                              "wo_price_per_acre"
                            );
                            setColor("3");
                          }}
                        >
                          ₹{search[searchTerm]["wo_price_per_acre"]} without
                          operator per acre
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                <b className={classes.text}>Operation Details: </b>
                {burdwan && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "space-evenly ",
                      justifyContent: "space-between",
                      width: "auto",
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                  >
                    <TextField
                      id="input-with-icon-grid"
                      type="number"
                      autoComplete="off"
                      onChange={handleNPatches}
                      value={nPatches}
                      style={{ width: "100%" }}
                      variant="outlined"
                      required="true"
                      // onKeyDown={(e)=>keyPress(e)}
                      size="large"
                      label="Total Number of Patches"
                    />
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginBottom: "16px",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "space-evenly ",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "16px",
                    }}
                  >
                    <div>
                      <TextField
                        id="input-with-icon-grid"
                        type="text"
                        autoComplete="off"
                        onChange={handleArea}
                        value={area}
                        style={{ width: "300px" }}
                        variant="outlined"
                        required="true"
                        // onKeyDown={(e)=>keyPress(e)}
                        size="large"
                        label={burdwan ? "Total Area" : "Area of Farm"}
                      />
                    </div>
                    <FormControl
                      variant="outlined"
                      required="true"
                      size="medium"
                    >
                      {" "}
                      <InputLabel id="demo-simple-select-outlined-label">
                        Unit
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={unit}
                        disabled={burdwan}
                        onChange={handleUnit}
                        style={{ width: "300px", height: "50px" }}
                        input={
                          <OutlinedInput
                            labelWidth={34}
                            name="members"
                            id="members"
                          />
                        }
                      >
                        {unitArr.map((val) => {
                          return <MenuItem value={val}>{val}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  {burdwan && (
                    <span
                      style={{ fontSize: "0.8em", color: "rgba(255,0,0,0.8)" }}
                    >
                      *total acres are {props?.value?.burdwan_area.toFixed(2)}
                      {/* and the remaining
                      acre is{" "}
                      {Math.max((3 - props?.value?.burdwan_area).toFixed(2), 0)} */}
                    </span>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-evenly ",
                    justifyContent: "space-between ",
                    width: "auto",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                >
                  <div>
                    <TextField
                      id="input-with-icon-grid"
                      type="text"
                      autoComplete="off"
                      required={burdwan ? false : true}
                      value={duration}
                      onChange={handleDuration}
                      style={{ width: "300px" }}
                      variant="outlined"
                      // onKeyDown={(e)=>keyPress(e)}
                      size="large"
                      label="Duration"
                    />
                  </div>
                  <FormControl variant="outlined" required="true" size="medium">
                    {" "}
                    <InputLabel id="demo-simple-select-outlined-label">
                      Unit
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      onChange={handleTimeUnit}
                      value={timeUnit}
                      style={{ width: "300px", height: "50px" }}
                      input={
                        <OutlinedInput
                          labelWidth={34}
                          name="members"
                          id="members"
                        />
                      }
                    >
                      {unit_time.map((val) => {
                        return <MenuItem value={val}>{val}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-evenly ",
                    justifyContent: "space-between",
                    width: "auto",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div>
                      <KeyboardDatePicker
                        margin="normal"
                        disablePast
                        id="date-picker-dialog"
                        inputVariant="outlined"
                        label="Date"
                        format="dd/MM/yyyy"
                        onChange={handleDate}
                        minDate={
                          burdwan
                            ? pulseOrder
                              ? getAdditionalDate(1)
                              : new Date()
                            : new Date()
                        }
                        style={{ width: "300px", margin: "auto" }}
                        value={date}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </div>
                    <div>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Time picker"
                        inputVariant="outlined"
                        className={classes.textField}
                        onChange={handleJobTime}
                        value={date}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        style={{ width: "300px", margin: "auto" }}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </div>
                {burdwan && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "space-evenly ",
                      justifyContent: "space-between",
                      width: "auto",
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                  >
                    <TextField
                      id="input-for-comments"
                      name="comments"
                      type="text"
                      multiline
                      rows={4}
                      autoComplete="off"
                      onChange={handleComments}
                      value={comments}
                      style={{ width: "100%" }}
                      variant="outlined"
                      size="large"
                      label="Comments"
                    />
                  </div>
                )}
                <b className={classes.text}>Got a Coupon Code?: </b>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-evenly ",
                    justifyContent: "space-between",
                    width: "auto",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                >
                  <div>
                    <TextField
                      id="input-with-icon-grid"
                      type="text"
                      autoComplete="off"
                      onChange={handleCoupon}
                      value={coupon}
                      style={{ width: "300px" }}
                      variant="outlined"
                      // onKeyDown={(e)=>keyPress(e)}
                      size="large"
                      label="Coupon Code"
                    />
                  </div>
                  <Button
                    variant="outlined"
                    style={{
                      color: "#F37021",
                      height: "50px",
                      width: "300px",
                      fontWeight: "700",
                      borderColor: "#F37021",
                    }}
                  >
                    Apply Coupon
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-evenly ",
                    justifyContent: "space-between",
                    width: "auto",
                  }}
                >
                  <Button
                    variant="outlined"
                    disable={true}
                    style={{
                      color: "#F37021",
                      height: "50px",
                      width: "300px",
                      fontWeight: "700",
                      marginBottom: "16px",
                    }}
                    onClick={() =>
                      history.push({
                        pathname: "/cancelOrder",
                        state: { orderId: -1 },
                      })
                    }
                  >
                    Cancel Order
                  </Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    style={{
                      color: "white",
                      backgroundColor: "#F37021",
                      height: "50px",
                      width: "300px",
                      fontWeight: "700",
                      marginBottom: "16px",
                    }}
                    // onClick={() => submit()}
                  >
                    Place Order
                  </Button>
                </div>
              </div>
            </main>
            <div
              className={classes.verticalLine}
              style={{ height: `120vh` }}
            ></div>
            {data?.orders && (
              <SideOrder
                orders={data?.orders
                  ?.sort((a, b) => (a.crm_order_id > b.crm_order_id ? -1 : 1))
                  .slice(0, 8)}
              />
            )}
          </div>
        </form>
      </Card>
      <AddFarm
        dataList={list}
        isModalOpen={modalIsOpen}
        setAddedFarm={setAddedFarm}
        setModalIsOpen={setModalIsOpen}
        setFarmLand={setFarmLand}
        handleChange={handleChange}
        type={type}
      />
      {/* <Modal
        style={{
          content: {
            top: "10%",
            width: "673px",
            height: "596px",
            left: "25%",
            right: "0",
            backgroundColor: "white",
          },
          overlay: { zIndex: "4" },
        }}
        isOpen={modalIsOpen}
      >
        <AddFarm2
          dataList={list}
          setAddedFarm={setAddedFarm}
          isModalOpen={setModalIsOpen}
          setFarmLand={setFarmLand}
          handleChange={handleChange}
          type={type}
        />
      </Modal> */}
      <Modal
        style={{
          content: {
            top: "30%",
            width: "62%",
            height: "80px",
            left: "22%",
            right: "0",
            margin: "auto 20px",
            backgroundColor: "white",
            position: "relative",
          },
          overlay: { zIndex: "4" },
        }}
        isOpen={timeModal}
      >
        <span
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => setTimeModal(false)}
        >
          Close X
        </span>
        <h2 style={{ textAlign: "center" }}>
          Selected time should be between working hours 7am-7pm
        </h2>
      </Modal>
      <Modal
        style={{
          content: {
            top: "14%",
            width: "62%",
            height: "560px",
            left: "16%",
            right: "0",
            margin: "auto 20px",
            backgroundColor: "white",
            position: "relative",
          },
          overlay: { zIndex: "4" },
        }}
        isOpen={submitIsOpen}
      >
        {/* <Card
          style={{
            backgroundColor: "white",
            
            height: "280px",
            margin: "10px auto",
          }}
        > */}
        <h1 style={{ textAlign: "center" }}> Thank You!!</h1>

        <h2 style={{ textAlign: "center" }}>
          Your Order Has been Placed Successfully !!{" "}
        </h2>
        <div className={classes.otpContainer}>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.2em",
            }}
          >
            <span>Ref. ID: </span>
            {orderIdFromResponse ? (
              `${orderIdFromResponse}`
            ) : (
              <Skeleton width="100px" />
            )}
          </p>
          {burdwan && <p>{otp ? `OTP: ${otp}` : <Skeleton width="100px" />}</p>}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "60px 20px",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                backgroundColor: "#FAF3FF",
                borderRadius: "50%",
                width: "80px",
                height: "84px",
                textAlign: "center",
              }}
            >
              <img alt="" src={machine} className={classes.image} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p style={{ margin: 0, width: "61px" }}>Machine</p>
              <b style={{ width: "61px", display: "inline-flex" }}>
                {data?.category}
              </b>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                backgroundColor: "#FAF3FF",
                borderRadius: "50%",
                width: "80px",
                height: "84px",
                textAlign: "center",
              }}
            >
              <img alt="" src={Calander} className={classes.image} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p style={{ margin: 0, width: "61px", whiteSpace: "nowrap" }}>
                Date & Time
              </p>
              <b style={{ width: "61px", whiteSpace: "nowrap" }}>
                {data?.job_start_time
                  ?.split(" ")[0]
                  .split("-")
                  .reverse()
                  .join("-")}{" "}
                {data?.job_start_time?.split(" ")[1]}
              </b>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                backgroundColor: "#FAF3FF",
                borderRadius: "50%",
                width: "80px",
                height: "84px",
                textAlign: "center",
              }}
            >
              <img alt="" src={Clock} className={classes.image} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p style={{ margin: 0, width: "61px" }}>Duration</p>
              <b style={{ width: "61px" }}>
                {data?.duration / (60 * 60) >= 1
                  ? data?.duration / (60 * 60)
                  : (data?.duration / 60).toFixed(2)}{" "}
                {data?.duration / (60 * 60) >= 1 ? <>hours</> : <>minutes</>}
              </b>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "60px 20px",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                backgroundColor: "#FAF3FF",
                borderRadius: "50%",
                width: "80px",

                textAlign: "center",
              }}
            >
              <img alt="" src={person} className={classes.image} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p style={{ margin: 0, width: "61px" }}>Owner</p>
              <b style={{ width: "61px" }}>
                {sessionStorage.getItem("farmer_name")}
              </b>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                backgroundColor: "#FAF3FF",
                borderRadius: "50%",
                width: "80px",
                textAlign: "center",
              }}
            >
              <img alt="" src={wheel} className={classes.image} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p style={{ margin: 0, width: "61px" }}>Area</p>
              <b style={{ width: "61px", whiteSpace: "nowrap" }}>
                {data?.area && data?.area.toFixed(2)} acre{" "}
              </b>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                backgroundColor: "#FAF3FF",
                borderRadius: "50%",
                width: "80px",
                textAlign: "center",
              }}
            >
              <img alt="" src={group} className={classes.image} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p style={{ margin: 0, width: "61px" }}>
                {data?.price === 0 ? "Discuss with RE" : "Charges"}
              </p>
              <b style={{ width: "61px" }}>
                {data?.price === 0
                  ? ""
                  : "Rs." +
                    getTotalPrice(
                      data?.price_unit,
                      data?.price,
                      data?.area,
                      data?.duration
                    )}
                {/* {totalPriceLoading ? (
                  <Skeleton width="40px" />
                ) : totalPrice === 0 ? (
                  ""
                ) : (
                  "Rs." + totalPrice
                )} */}
                {/* {data?.price === 0 ? "" : "Rs." + data?.price} */}
              </b>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            variant="outlined"
            style={{
              color: "#F37021",
              height: "50px",
              width: "300px",
              fontWeight: "700",
              marginBottom: "16px",
            }}
            onClick={() => {
              window.location.reload();
            }}
          >
            Place A New Order
          </Button>

          <Button
            variant="outlined"
            style={{
              color: "#F37021",
              height: "50px",
              width: "300px",
              fontWeight: "700",
              marginBottom: "16px",
            }}
            onClick={() => {
              sessionStorage.clear();
              history.push("/");
            }}
          >
            Return to Home screen
          </Button>
          <Button
            variant="outlined"
            style={{
              color: "#F37021",
              height: "50px",
              width: "300px",
              fontWeight: "700",
              marginBottom: "16px",
            }}
            onClick={() => {
              history.push({
                pathname: "/cancelOrder",
                state: { orderId: orderId },
              });
            }}
          >
            Cancel Order
          </Button>
        </div>
        {/* </Card> */}
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  value: state.fetchDataReducer.data,
  servicableLoading: state.fetchDataReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  sendRequest: (body) => {
    dispatch(fetchDataRequest({ body }));
  },
  // getData:(body)=>{
  //   dispatch(fetchDataSuccess({ body }));
  // }
});

export default connect(mapStateToProps, mapDispatchToProps)(RentalOrder2);
