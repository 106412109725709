import React, { useState } from "react";
import json from "../../data/loaction.json";
import queryString from "query-string";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link } from "react-router-dom";
import Crop_arr from "../../data/crop.json";
import { cameFromHome } from "../../store/action/cmsActions/fetchTicketAction";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import commonInfoStyles from "./commoninfo.styles";
import { getDateTime } from "../../utils";

import { fetchDataReset } from "../../store/action/fetchDataAction";
import { Skeleton } from "@material-ui/lab";

import Swal from "sweetalert2";
import { API_ROUTES, API_BASE_URL } from "../../config";

function CommonInfo({ setUrl, getNumber, setLoggedIn, username, ...props }) {
  const classes = commonInfoStyles();
  const [invalid, setInvalid] = useState(false);
  const [data, SetData] = useState({});
  const [name, setName] = useState("");
  const [village, setVillage] = useState("");
  const [state, setState] = useState("");
  const [taluka, setTaluka] = useState("");
  const [phone, setPhone] = useState("");
  const [alternatePhone, setAlternatePhone] = useState("");
  const [dataState, setDataState] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataTaluka, setDataTaluka] = useState([]);
  const [dataVillage, setDataVillage] = useState([]);
  const [district, setDistrict] = useState("");
  const [loading, isLoading] = useState(false);
  const [loading2, isLoading2] = useState(false);
  const [loading3, isLoading3] = useState(false);
  const [area, setArea] = useState("");
  const [unit, setUnit] = useState("Acre");
  const [crops, setCrops] = useState([]);
  const [cust_type, setCust_Type] = useState("");
  const location = useLocation();
  const token = localStorage.getItem("token");
  const history = useHistory();

  const [healthHistory, setHealthHistory] = useState(false);
  const [callHealthLoading, setCallHealthLoading] = useState(false);
  const [callHealthData, setCallHealthData] = useState([]);
  const [matchedPhoneType, setMatchedPhoneType] = useState("");
  const [showAlternate, setShowAlternate] = useState(true);
  const [phoneInSecondary, setPhoneInSecondary] = useState("");
  const [alternatePhoneError, setAlternatePhoneError] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.redirect === `outbound`) {
      sessionStorage.setItem("number", parsed.number);
      history.push({
        pathname: "/outbound",
        search: `?number=${parsed.number}`,
      });
    } else {
      if (parsed.Module) {
        switch (parsed.Module) {
          case "1":
            setCust_Type("Krish-e");
            formik.values.farmer_type = "Krish-e";
            break;
          case "2":
            setCust_Type("Tringo");
            formik.values.farmer_type = "Tringo";
            break;
          case "3":
            setCust_Type("Swaraj");
            formik.values.farmer_type = "Swaraj";
            break;
          default:
            break;
        }
      }
      setDataState(Object.keys(json));
      if (Object.keys(parsed).length > 0 && parsed?.number !== "") {
        setHealthHistory(true);
        let config = {
          ApiKey: `41k59h2PEWniwk3QD0IjnYFYC7X92qPz`,
          Authorization: `Token ${token}`,
        };
        let newQuery = location.search;
        if (parsed.number.length > 10 && parsed.number.startsWith("91")) {
          let newNumber = parsed.number.substring(2);
          newQuery = newQuery.replace(parsed.number, newNumber);
          history.replace({ pathname: "/", search: newQuery });
        }

        const newParsed = queryString.parse(newQuery);

        let body = { phone: newParsed.number };
        isLoading2(true);

        setCallHealthLoading(true);
        axios
          .get(
            `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.FETCH_CALL_HEALTH}?phone=${parsed.number}`
          )
          .then((res) => {
            if (res && Array.isArray(res.data)) {
              setCallHealthData(res.data);
            } else {
              setCallHealthData([]);
            }
            setCallHealthLoading(false);
          });

        axios
          .post(
            `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.CHECK_USER}`,
            body,
            {
              headers: config,
            }
          )
          .then((val) => {
            if (!val.data.data.existing) {
              setName("");
              setState("");
              setDistrict("");
              setTaluka("");
              setVillage("");
              setPhone(parsed.number);
              getNumber(parsed.number);
              formik.values.phone = parsed.number;
              if (parsed.number !== undefined && parsed.number !== "") {
                setInvalid(true);
              }

              isLoading2(false);
            } else {
              if (val?.data?.data?.matched_phone === "secondary") {
                setPhone(val.data.data.secondary_phone ?? parsed.number);
                formik.values.phone =
                  val?.data?.data?.secondary_phone ?? parsed.number;
                setPhoneInSecondary(val?.data?.data?.phone);
                setShowAlternate(false);
              } else {
                setPhone(val.data.data.phone ?? parsed.number);
                formik.values.phone = val?.data?.data?.phone ?? parsed.number;
                setShowAlternate(true);
              }
              setInvalid(false);
              setName(val.data.data.name || "");
              setState(val.data.data?.state || "");
              setDistrict(val.data.data?.district || "");
              setTaluka(val.data.data?.taluka || "");
              setVillage(val.data.data?.village || "");
              setCrops(val.data.data?.crops ?? []);
              setArea(val.data.data?.farm_area || "");
              setCust_Type(val.data.data?.farmer_type || "");
              setAlternatePhone(val?.data?.data?.secondary_phone || "");
              setMatchedPhoneType(val?.data?.data?.matched_phone || "");
              setUnit((prev) =>
                val.data.data.area_units
                  ? val.data.data.area_units[0].toUpperCase() +
                    val.data.data.area_units.slice(1)
                  : "Acre"
              );
              formik.values.name = val?.data?.data?.name || "";
              formik.values.state = val?.data?.data?.state || "";
              formik.values.district = val?.data?.data?.district || "";
              formik.values.taluka = val?.data?.data?.taluka || "";
              formik.values.village = val?.data?.data?.village || "";
              formik.values.key_crop_1 = val?.data?.data?.key_crop_1 || "";
              formik.values.crops = val?.data?.data?.crops || [];
              formik.values.farm_area = val?.data?.data?.farm_area || "";
              formik.values.area_units = val?.data?.data?.area_units || "";
              formik.values.farmer_type = val?.data?.data?.farmer_type || "";
              formik.values.secondary_phone =
                val?.data?.data?.secondary_phone || "";
              if (val.data.data.state !== null) {
                getDistrict(val.data.data.state);

                getTaluka(val.data.data.state, val.data.data.district);
                getVillage(
                  val.data.data.state,
                  val.data.data.district,
                  val.data.data.taluka
                );
              }
              isLoading2(false);
            }
          })
          .catch((err) => {
            isLoading2(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    let config = {
      ApiKey: `41k59h2PEWniwk3QD0IjnYFYC7X92qPz`,
      Authorization: `Token ${token}`,
    };
    sessionStorage.setItem("formData", JSON.stringify(data));
    if (Object.keys(data).length > 0) {
      isLoading(true);
      axios
        .post(`${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.SAVE_USER}`, data, {
          headers: config,
        })
        .then((val) => {
          if (val.data.status) {
            isLoading(false);
            setUrl(val.data.data.form, val.data.data.name, val.data.data.phone);
            props.serviceDataReset();
            history.push("/nav");
          } else {
            isLoading(false);
            Swal.fire({
              title: val.data.message,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          isLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const formik = useFormik({
    initialValues: {
      phone: "",
      secondary_phone: "",
      name: "",
      state: "",
      district: "",
      taluka: "",
      village: "",
      key_crop_1: "",
      crops: [],
      farm_area: "",
      area_units: "Acre",
      farmer_type: "",
    },
    onSubmit: (values, e) => {
      if (values.phone.length !== 10) {
        setInvalid(true);
      } else if (
        values.secondary_phone.length > 0 &&
        values.secondary_phone.length !== 10
      ) {
        setAlternatePhoneError(true);
      } else {
        setInvalid(false);
        setAlternatePhoneError(false);
        let saveUserBody = {
          phone: values.phone,
          name: values.name,
          state: values.state,
          district: values.district,
          taluka: values.taluka,
          village: values.village,
          farm_area: values.farm_area,
          area_units: values.area_units.toLowerCase(),
          key_crop_1: values.key_crop_1,
          crops: values.crops,
          farmer_type: values.farmer_type,
          secondary_phone: values.secondary_phone,
          matched_phone: matchedPhoneType,
        };
        if (matchedPhoneType === "secondary") {
          saveUserBody.phone = phoneInSecondary;
        }
        SetData((prev) => saveUserBody);
      }
    },
  });
  let unit_arr = ["Acre", "Hectare", "Guntha"];

  function getDistrict(state) {
    setDataDistrict(Object.keys(json[state]));
  }

  function getTaluka(state, district) {
    setDataTaluka(Object.keys(json[state][district]));
  }
  function getVillage(state, district, data) {
    setDataVillage(json[state][district][data]);
  }

  const handleChange = (event) => {
    if (event.target.value.length <= 10) {
      setPhone(event.target.value);
      formik.values.phone = event.target.value;
    }
  };
  const handleArea = (event) => {
    setArea(event.target.value);
    formik.values.farm_area = event.target.value;
  };
  const handleUnit = (event) => {
    setUnit(event.target.value);
    formik.values.area_units = event.target.value.toLowerCase();
  };
  const handleName = (event) => {
    //  isLoading3(true);
    setName(event.target.value);
    formik.values.name = event.target.value;
    //  isLoading3(false);
  };
  const handleCustomerType = (event) => {
    setCust_Type(event.target.value);
    formik.values.farmer_type = event.target.value;
  };

  const handleAlternatePhoneChange = (event) => {
    if (event.target.value.length <= 10) {
      setAlternatePhone(event.target.value);
      formik.values.secondary_phone = event.target.value;
    }
  };

  const handleState = (event) => {
    isLoading3(true);
    setDistrict("");
    setVillage("");
    setTaluka("");
    setArea("");
    setUnit("Acre");

    setState(event.target.value);
    formik.values.state = event.target.value;
    getDistrict(formik.values.state);

    isLoading3(false);
  };
  const handleDistrict = (event) => {
    isLoading3(true);
    setVillage("");
    setTaluka("");
    setArea("");
    setUnit("Acre");
    setDistrict(event.target.value);
    formik.values.district = event.target.value;
    getTaluka(formik.values.state, event.target.value);
    isLoading3(false);
  };
  const handleTaluka = (event) => {
    setVillage("");
    setArea("");
    setUnit("Acre");
    isLoading3(true);
    setTaluka(event.target.value);
    formik.values.taluka = event.target.value;

    getVillage(formik.values.state, formik.values.district, event.target.value);
    isLoading3(false);
  };
  const handleVillage = (e) => {
    setArea("");
    setUnit("Acre");
    setVillage(e.target.value);
    formik.values.village = e.target.value;
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          minHeight: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const renderHealthLoader = (n = 5) => {
    let ar = [];
    for (let i = 0; i < 5; i++) {
      ar.push(
        <div style={{ alignSelf: "flex-start", padding: "1em 0" }}>
          <Skeleton width="250px" />
          <Skeleton width="180px" />
          <Skeleton width="200px" />
          <Skeleton width="250px" />
        </div>
      );
    }
    return ar;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.dataContainer}>
          <form
            onSubmit={formik.handleSubmit}
            autoComplete="none"
            className={classes.form}
          >
            <div className={classes.formTitle}>
              <p>Capture Farmer Details</p>
            </div>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{ width: "230px" }}
                    ></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="right" className={classes.text}>
                      Phone Number:
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {!invalid ? (
                        <TextField
                          id="input-with-icon-grid"
                          type="number"
                          autoComplete="off"
                          onChange={handleChange}
                          maxLength={10}
                          value={phone}
                          variant="outlined"
                          // onKeyDown={(e)=>keyPress(e)}
                          size="small"
                        />
                      ) : (
                        <TextField
                          error
                          id="outlined-error"
                          type="number"
                          label="The User Does Not Exist"
                          onChange={handleChange}
                          value={phone}
                          // onKeyDown={(e)=>keyPress(e)}
                          inputProps={{
                            maxLength: 10,
                          }}
                          autoComplete="off"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {!loading2 && (
                        <Link
                          to={{
                            pathname: "/",
                            search:
                              formik.values.phone &&
                              `?number=${formik.values.phone}`,
                          }}
                        >
                          <Button
                            style={{
                              backgroundColor: "#F37021",
                              color: "white",
                              width: "90px",
                            }}
                          >
                            <SearchIcon color="white" />
                          </Button>
                        </Link>
                      )}
                      {loading2 && <CircularProgress />}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" className={classes.text}>
                      Name:
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <TextField
                        id="input-with-icon-grid"
                        onChange={handleName}
                        value={name}
                        variant="outlined"
                        autoComplete="none"
                        size="small"
                        required
                      />
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" className={classes.text}>
                      State:
                    </TableCell>
                    {!loading3 && (
                      <TableCell style={{ textAlign: "center" }}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          size="small"
                        >
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={handleState}
                            style={{ width: "220px" }}
                            required={true}
                            value={state}
                          >
                            {dataState.map((val) => {
                              return <MenuItem value={val}>{val}</MenuItem>;
                            })}
                          </Select>
                        </FormControl>
                      </TableCell>
                    )}
                    {loading3 && <CircularProgress />}
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" className={classes.text}>
                      District:
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {!loading3 && (
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          size="small"
                        >
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={handleDistrict}
                            style={{ width: "220px" }}
                            size="small"
                            required={true}
                            value={district}
                          >
                            {dataDistrict.sort().map((val) => {
                              return <MenuItem value={val}>{val}</MenuItem>;
                            })}
                          </Select>
                        </FormControl>
                      )}
                      {loading3 && <CircularProgress />}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" className={classes.text}>
                      Taluka:
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {!loading3 && (
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          size="small"
                        >
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={handleTaluka}
                            style={{ width: "220px" }}
                            size="small"
                            required={true}
                            value={taluka}
                          >
                            {dataTaluka.sort().map((val) => {
                              return <MenuItem value={val}>{val}</MenuItem>;
                            })}
                          </Select>
                        </FormControl>
                      )}
                      {loading3 && <CircularProgress />}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" className={classes.text}>
                      Village:
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {!loading3 && (
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          size="small"
                        >
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={handleVillage}
                            style={{ width: "220px" }}
                            value={village}
                            required={true}
                          >
                            {dataVillage.sort().map((val) => {
                              return <MenuItem value={val}>{val}</MenuItem>;
                            })}
                          </Select>
                        </FormControl>
                      )}
                      {loading3 && <CircularProgress />}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  {showAlternate && (
                    <TableRow>
                      <TableCell align="right" className={classes.text}>
                        Alternate Number:
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <TextField
                          id="input-with-icon-grid"
                          type="number"
                          label={
                            alternatePhoneError ? "*Enter Valid Number" : ""
                          }
                          onChange={handleAlternatePhoneChange}
                          value={alternatePhone}
                          variant="outlined"
                          autoComplete="none"
                          size="small"
                          error={alternatePhoneError}
                        />
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell align="right" className={classes.text}>
                      Total Farm Area:
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <TextField
                        id="input-with-icon-grid"
                        onChange={handleArea}
                        variant="outlined"
                        autoComplete="none"
                        size="small"
                        value={area}
                      />

                      {loading3 && <CircularProgress />}
                    </TableCell>
                    <TableCell align="left">
                      {!loading3 && (
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                          size="small"
                        >
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={handleUnit}
                            defaultValue="Acre"
                            style={{ width: "100px" }}
                            size="small"
                            value={unit}
                          >
                            {unit_arr.map((val) => {
                              return <MenuItem value={val}>{val}</MenuItem>;
                            })}
                          </Select>
                        </FormControl>
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="right" className={classes.text}>
                      Primary Crop:
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {!loading3 && (
                        <FormControl className={classes.formControl}>
                          <Autocomplete
                            multiple
                            id="primary-crops"
                            value={crops}
                            onChange={(event, newValue) => {
                              setCrops(newValue);
                              formik.values.crops = newValue;
                            }}
                            style={{ width: "220px" }}
                            options={Crop_arr.crop_arr.sort((a, b) =>
                              a > b ? 1 : -1
                            )}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                {...params}
                                size="small"
                              />
                            )}
                          />
                        </FormControl>
                      )}
                      {loading3 && <CircularProgress />}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right" className={classes.text}>
                      Customer Type:
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        size="small"
                      >
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={handleCustomerType}
                          style={{ width: "220px" }}
                          value={cust_type}
                        >
                          <MenuItem value="Krish-e">Krish-e</MenuItem>
                          <MenuItem value="Tringo">Tringo</MenuItem>
                          <MenuItem value="Swaraj">Swaraj</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: "#F37021",
                        color: "white",
                        width: "200px",
                        height: "40px",
                      }}
                      onClick={() => props.cameFromHomeHandler(false)}
                    >
                      Submit
                    </Button>
                    {/* <Button
                      style={{
                        backgroundColor: "#F37021",
                        color: "white",
                        width: "200px",
                        height: "40px",
                        marginLeft: ".3em",
                      }}
                      onClick={() => {
                        history.push("/cms");
                        props.cameFromHomeHandler(true);
                      }}
                    >
                      Go To CMS
                    </Button> */}
                  </TableCell>
                  <TableCell align="right"></TableCell>
                  {/* </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </form>
          <div className={classes.callHealthContainer}>
            <p>Previous Call Health</p>
            <div className={classes.callHealths}>
              {healthHistory ? (
                <>
                  {!callHealthLoading && callHealthData ? (
                    <>
                      {callHealthData.length > 0 ? (
                        callHealthData
                          .sort((a, b) =>
                            new Date(a.ts) > new Date(b.ts) ? -1 : 1
                          )
                          .map((health) => (
                            <div className={classes.health}>
                              <p>
                                <span>Call Type :</span> {health?.call_type}
                              </p>
                              <p>
                                <span>Status :</span>{" "}
                                <span
                                  className={`${classes.healthStatus} ${
                                    health?.health === "Satisfied"
                                      ? classes.healthHappy
                                      : health?.health === "Neutral"
                                      ? classes.healthNeutral
                                      : classes.healthSad
                                  }`}
                                >
                                  {health?.health}
                                </span>
                              </p>
                              {health?.comments && health?.comments !== "" && (
                                <p>
                                  <span>Comment :</span> {health?.comments}
                                </p>
                              )}
                              <p>
                                <span>Time :</span> {getDateTime(health?.ts)}
                              </p>
                            </div>
                          ))
                      ) : (
                        <h2 className={classes.noPhoneNumber}>
                          No Previous Call Health Data Found
                        </h2>
                      )}
                    </>
                  ) : (
                    renderHealthLoader()
                  )}
                </>
              ) : (
                <h2 className={classes.noPhoneNumber}>
                  Please Enter <span>Phone Number</span> on the left to check
                  the previous call health.
                </h2>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  cameFromHomeHandler: (cf) => {
    dispatch(cameFromHome(cf));
  },

  serviceDataReset: () => {
    dispatch(fetchDataReset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonInfo);
