import * as types from "../../types";

const initialState = {
  loading: false,
  tickets: null,
  allTickets: null,
  searchTerms: null,
  spoc: null,
  selectedTicket: null,
  cameFromHome: false,
  error: {},
};

const fetchTicketReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TICKET_REQUEST:
      return {
        ...state,
        loading: true,
        spoc: action.payload.spoc,
        tickets: null,
        allTickets: null,
        error: {},
      };
    case types.FETCH_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        tickets: action.payload.tickets,
        allTickets: action.payload.tickets,
        selectedTicket: action.payload.tickets[0],
        error: {},
      };
    case types.FETCH_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        tickets: null,
        allTickets: null,
        error: action.error,
      };
    case types.UPDATE_TICKETS_REQUEST:
      return {
        ...state,
        spoc: action.payload.spoc,
      };
    case types.SEARCH_TICKET_REQUEST:
      return {
        ...state,
        searchTerms: action.payload.searchTerms,
        searchField: action.payload.searchField,
      };
    case types.SEARCH_TICKET_SUCCESS:
      return {
        ...state,
        tickets: action.payload.tickets,
      };
    case types.SEARCH_TICKET_FAILURE:
      return {
        ...state,
        searchTerms: null,
        searchField: null,
        error: action.error,
      };
    case types.SELECT_TICKET:
      return {
        ...state,
        selectedTicket: action.payload.ticket,
      };
    case types.CAME_FROM_HOME:
      return {
        ...state,
        cameFromHome: action.payload.cameFromHome,
      };
    default:
      return state;
  }
};

export default fetchTicketReducer;
