import { all } from "redux-saga/effects";
import fetchDataSaga from "./fetchDataSaga";

import ticketRequestSaga, {
  updateTicketsSaga,
} from "./cmsSagas/fetchTicketSaga";
import searchTicketSaga from "./cmsSagas/searchTicketSaga";
import tractorRequestSaga from "./cmsSagas/fetchTractorSaga";
import issueTypeSaga from "./cmsSagas/issueTypeSaga";
import welcomeSaga from "./cmsSagas/welcomeSaga";
import feedbackSaga from "./cmsSagas/feedbackSaga";
import fetchServicesSaga from "./fetchServicesSaga";
import forceCloseSaga from "./cmsSagas/forceCloseSaga";
import uploadExcelSaga from "./uploadExcelSaga";
import createCampaignSaga from "./createCampaignSaga";
import fetchFilterCallsSaga, {
  fetchFilterCallsSelectSaga,
} from "./fetchFilterCallsSaga";

import campaignRequestSaga, { updateCampaignSaga } from "./campaignSaga";
import callRatingSaga from "./callRatingSaga";

import campaignRequestSagaOutbound, {
  updateCampaignSagaOutbound,
} from "./outboundSagas/campaignSaga";

import fetchSmsTemplatesSaga from "./fetchSmsTemplatesSaga";
import smsTriggerSaga from "./smsTriggerSaga";

export default function* rootSaga() {
  yield all([
    fetchDataSaga(),
    ticketRequestSaga(),
    searchTicketSaga(),
    tractorRequestSaga(),
    issueTypeSaga(),
    welcomeSaga(),
    feedbackSaga(),
    updateTicketsSaga(),
    fetchServicesSaga(),
    forceCloseSaga(),
    uploadExcelSaga(),
    createCampaignSaga(),
    campaignRequestSaga(),
    updateCampaignSaga(),
    callRatingSaga(),
    campaignRequestSagaOutbound(),
    updateCampaignSagaOutbound(),
    fetchFilterCallsSaga(),
    fetchFilterCallsSelectSaga(),
    fetchSmsTemplatesSaga(),
    smsTriggerSaga(),
  ]);
}
