import React from "react";
import { connect } from "react-redux";
import adminStyles from "./admin.styles";
import { Button } from "../../elements";
import {
  uploadExcelRequest,
  resetExcel,
} from "../../store/action/uploadExcelAction";
import { createCampaignRequest } from "../../store/action/createCampaignAction";
import CampaignTable from "./CampaignTable";

import { useHistory } from "react-router-dom";

function Admin({ setUrl, getNumber, setLoggedIn, username, ...props }) {
  const classes = adminStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.innerbox}>
          <div className={classes.topBar}>
            <h1>
              {/* <span>
                <IconButton
                  onClick={() => {
                    history.push("/nav");
                  }}
                >
                  <KeyboardBackspaceIcon />
                </IconButton>
              </span> */}
              Campaigns
            </h1>
            <Button
              type="button"
              variant="contained"
              className={classes.submitBtn}
              disabled={props.campaingLoading}
              onClick={() => {
                history.push({
                  pathname: "/admin/create",
                  state: { campaign: {} },
                });
              }}
            >
              New Campaign
            </Button>
          </div>
          <CampaignTable />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  excelLoading: state.uploadExcelReducer.loading,
  excelUploaded: state.uploadExcelReducer.uploaded,
  excelValid: state.uploadExcelReducer.isValid,
  excelError: state.uploadExcelReducer.error,
  excelData: state.uploadExcelReducer.data,
  campaignRes: state.createCampaignReducer.res,
  campaignLoading: state.createCampaignReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  uploadExcel: ({ file }) => {
    dispatch(uploadExcelRequest({ file }));
  },
  resetExcelData: () => {
    dispatch(resetExcel());
  },
  submitCampaign: ({ data }) => {
    dispatch(createCampaignRequest({ data }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
