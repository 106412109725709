import * as types from "../types";
const initialState = {
  loading: false,
  data: null,
  res: null,
  error: {},
};

const createCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
        data: action.payload.data,
        error: {},
      };
    case types.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        res: action.payload.res,
        error: {},
      };
    case types.CREATE_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.error,
      };
    case types.RESET_CREATE_CAMPAIGN:
      return {
        loading: false,
        data: null,
        res: null,
        error: {},
      };
    default:
      return state;
  }
};

export default createCampaignReducer;
