import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types";
import { createCampaign } from "../../api";
import {
  createCampaignSuccess,
  createCampaignFailure,
} from "../action/createCampaignAction";
import { returnErrors, clearErrors } from "../action/errorAction";

function* asyncCreateCampaignSaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(createCampaign, { body: action.payload.data });
    yield put(createCampaignSuccess({ res }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(createCampaignFailure(e));
  }
}
export default function* createCampaignSaga() {
  yield takeLatest(types.CREATE_CAMPAIGN_REQUEST, asyncCreateCampaignSaga);
}
