import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withTheme,
  withStyles,
  useTheme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Skeleton from "@material-ui/lab/Skeleton";

import { connect } from "react-redux";
import { fetchCampaignRequest } from "../../store/action/fetchCampaignAction";
import { selectCampaignRequest } from "../../store/action/selectCampaignAction";
import { getData, formatDate } from "../../utils";
import { TableFooter } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from "@material-ui/icons/GetApp";

import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { API_ROUTES, API_BASE_URL } from "../../config";

// import CampaignForm from "./CampaignForm";

const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  tableOptions: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "2em",
    borderBottom: `2px solid ${theme.palette.grey.main}`,
    "& > h2": {
      fontSize: "2.1em",
      color: theme.palette.dark.main,
    },

    // [theme.breakpoints.down("md")]: {
    //   width: "100%",
    //   flexDirection: "column",
    //   alignItems: "center",
    //   justifyContent: "center",
    // },
  },

  submitBtn: {
    // width: 140,
    lineHeight: "44px",
    backgroundColor: `${theme.palette.accent.main} !important`,
    color: `${theme.palette.light.main} !important`,
    boxShadow: "none !important",
    margin: "1.3em 0 0 0",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: theme.palette.grey.main,
    color: "#706F6F",
  },
  body: {
    fontSize: 14,
    color: theme.palette.dark.main,
    fontWeight: "bold",
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);

const renderLoading = (row, col) => {
  let range = [...Array(row).keys()];
  let range2 = [...Array(col).keys()];
  return range.map((i) => (
    <StyledTableRow key={i}>
      {range2.map((i) => (
        <StyledTableCell
          key={i + 10}
          style={{
            height: 60,
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Skeleton
            animation="wave"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </StyledTableCell>
      ))}
    </StyledTableRow>
  ));
};

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const CampaignTable = ({ theme, ...props }) => {
  const classes = useStyles();
  // const [showModal, setShowModal] = useState(false);
  // const formData = JSON.parse(sessionStorage.getItem("formData"));
  const history = useHistory();

  useEffect(() => {
    let user = localStorage.getItem("username");
    if (user !== null) {
      if (!props.campaigns) {
        props.campaignRequest({ phone: 1413914 });
      }
    } else {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Not authorized",
        showConfirmButton: true,
        confirmButtonText: "Log In",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((isConfirm) => {
        if (isConfirm) {
          window.location.assign(`http://localhost:3000/`);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleModalOpen = () => {
  //   setShowModal((prev) => !prev);
  // };

  let priorityMap = { Active: 3, Upcoming: "2", Archived: 1 };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  let sortedData = props.campaigns
    ? props.campaigns.sort((a, b) => {
        return priorityMap[a.status] > priorityMap[b.status] ? -1 : 1;
      })
    : null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const downloadCsv = (data) => {
    const fileType = "text/csv;charset=utf-8;";
    const fileExtension = ".csv";
    const ws = XLSX.utils.json_to_sheet(data ?? []);
    var csv = XLSX.utils.sheet_to_csv(ws);
    const data2 = new Blob([s2ab(csv)], { type: fileType });
    saveAs(data2, "campign-list" + fileExtension);
    Swal.fire({
      title: "Campaign Data Downloaded Successfully",
      icon: "success",
    });
  };

  const downloadExcel = (data) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(data ?? []);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data2 = new Blob([excelBuffer], { type: fileType });
    saveAs(data2, "campign-list" + fileExtension);
    Swal.fire({
      title: "Campaign Data Downloaded Successfully",
      icon: "success",
    });
  };

  const downloadCampaignData = (campaignCode) => {
    Swal.fire({
      title: "Select the type of file",
      input: "radio",
      inputOptions: {
        excel: "Excel",
        csv: "Csv",
      },
      inputValue: "excel",
      showCancelButton: true,
      confirmButtonText: "Download",
      showLoaderOnConfirm: true,
      preConfirm: (fileType) => {
        console.log(fileType);
        return fetch(
          `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.GET_ALL_LIST_OF_CAMPAIGNS}?code=${campaignCode}`
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return { res: response.json(), fileType };
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        result.value.res.then((data) => {
          if (result.value.fileType === "excel") {
            downloadExcel(data);
          } else {
            downloadCsv(data);
          }
        });
      }
    });
  };

  return (
    <>
      <div style={{ marginTop: "2em" }}>
        <TableContainer style={{ marginTop: "1em" }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Campaign Code</StyledTableCell>
                <StyledTableCell align="left">Campaign Name</StyledTableCell>
                <StyledTableCell align="left">Start Date</StyledTableCell>
                <StyledTableCell align="left">End Date</StyledTableCell>
                <StyledTableCell align="left">Generation Date</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Edit Campaign</StyledTableCell>
                <StyledTableCell align="left">Download</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!props.loading && props.campaigns
                ? (rowsPerPage > 0
                    ? sortedData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : sortedData
                  ).map((row) => (
                    <StyledTableRow key={row.campaign_code}>
                      <StyledTableCell align="left">
                        {getData(row.campaign_code)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {getData(row.campaign_name)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {formatDate(row.start_date, "/")}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {formatDate(row.end_date, "/")}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {formatDate(row.generation_time, "/")}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.end_date) < new Date()
                          ? "Ended"
                          : "Ongoing"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton
                          onClick={() => {
                            props.selectCampaign({ campaign: row });
                            history.push({
                              pathname: "/admin/create",
                              state: { campaign: row, editing: true },
                            });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <IconButton
                          onClick={() =>
                            downloadCampaignData(row.campaign_code)
                          }
                        >
                          <GetAppIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : renderLoading(8, 8)}
            </TableBody>
            {props.campaigns && (
              <TableFooter
                style={{
                  background: theme.palette.grey.main,
                }}
              >
                <StyledTableRow>
                  <TablePagination
                    style={{ borderBottom: "none" }}
                    rowsPerPageOptions={[
                      15,
                      25,
                      50,
                      { label: "All", value: -1 },
                    ]}
                    count={props.campaigns.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </StyledTableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </div>
      {/* <CampaignForm
        showModal={showModal}
        handleModalOpen={handleModalOpen}
        // campaign={atob(localStorage.getItem("campaignId"))}
      /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.campaignReducer.loading,
  campaigns: state.campaignReducer.campaigns,
});

const mapDispatchToProps = (dispatch) => ({
  campaignRequest: ({ phone }) => {
    dispatch(fetchCampaignRequest({ phone }));
  },
  selectCampaign: ({ campaign }) => {
    dispatch(selectCampaignRequest({ campaign }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(CampaignTable));
