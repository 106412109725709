export const getData = (data) => {
  return data ? data : "NA";
};

export const getDateTime = (t) => {
  if (!t) {
    return "NA";
  }
  let time = new Date(t);
  let date = time.toLocaleDateString("en-US");
  let hour = time.getHours();
  let min = time.getMinutes();
  let timeFormat = hour >= 12 ? "PM" : "AM";
  hour = hour % 12;
  hour = hour < 10 ? "0" + hour : hour;
  hour = hour ? hour : "12";
  min = min < 10 ? "0" + min : min;
  return date + " " + hour + ":" + min + " " + timeFormat;
};

export const formatTime = (t) => {
  if (!t) {
    return "NA";
  }
  let time = new Date(t);
  let hour = time.getHours();
  let min = time.getMinutes();
  hour = hour < 10 ? "0" + hour : hour;
  min = min < 10 ? "0" + min : min;
  console.log(hour + ":" + min);
  return hour + ":" + min;
};

export const formatDate = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
