import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles((theme) => ({}));

function InputField({ field, form,width,size, onChangeUtil,...other }) {
  const classes = useStyles();
  const currentError = form.errors[field.name];

  return (
    <FormControl
      variant="outlined"
      // error={errors.actualArea && touched.actualArea ? true : false}
      error={currentError ? true : false}
      style={{
        flex: 1,
        width: width ?? "100%",
      }}
      size={size ?? "medium"}
      disabled={other.disabled}
    >
      <InputLabel htmlFor="outlined-implement-pin">{other.label}</InputLabel>
      <OutlinedInput
        className={classes.searchInput}
        id={other.id}
        type={other.type ? other.type : "number"}
        name={field.name}
        value={field.value}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.value, false);
          if(onChangeUtil){
            onChangeUtil(e.target.value)
          }
        }}
        onError={(error) => {
          if (error !== currentError) {
            form.setFieldError(field.name, error);
          }
        }}
        labelWidth={other.label ? other.label.length * 8.2 : 0}
        // labelWidth={other.labelWidth}
      />
      {currentError && <FormHelperText>{currentError}</FormHelperText>}
    </FormControl>
  );
}

export default InputField;
