import React, { useState } from "react";
import burdwanCmsStyles from "./burdwancms.styles";

import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";

import krishiLogo from "../../assets/Krish-logo.svg";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import CircularProgress from "@material-ui/core/CircularProgress";

import { JOTFORM_LOOKUP } from "../../config";

const BurdwanCMS = ({ username, ...props }) => {
  const classes = burdwanCmsStyles();
  const [loading, setLoading] = useState(true);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        style={{ backgroundColor: "#652078" }}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: false,
        })}
      >
        <Toolbar>
          <div style={{ margin: "auto 56px" }}></div>

          <img
            alt=""
            src={krishiLogo}
            width="140px"
            height="50px"
            style={{
              backgroundColor: "green",
              alignItems: "center",
              margin: "auto",
            }}
          />

          <Typography
            style={{
              margin: "auto 20px",
              fontSize: "24px",

              fontFamily: "Lucida Grande, sans-serif",
            }}
          >
            Hello {username}
          </Typography>
          <PowerSettingsNewIcon
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
            style={{ float: "right", cursor: "pointer" }}
          />
        </Toolbar>
      </AppBar>
      <div className={classes.jotformContainer}>
        <a
          href="http://krishe-metabase.centralindia.azurecontainer.io:3000/public/dashboard/faf44fbb-4e8e-4b5f-a681-f2d33624abfe"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.metabaseLink}
        >
          Click Here to View Past Orders and Complaints
        </a>
        <div className={classes.jotForm}>
          {loading ? (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          ) : null}

          <iframe
            title="frame"
            id="JotFormIFrame"
            onLoad={() => {
              setLoading(false);
            }}
            allowtransparency="true"
            src={JOTFORM_LOOKUP.burdwanCMS}
            frameBorder="0"
            style={{ width: "100%", height: "100%", border: "none" }}
            scrolling="yes"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default BurdwanCMS;
