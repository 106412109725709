import React, { useEffect, useState } from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import FormNav from "../../components/FormNav";
import { Button } from "../../elements";
import InputBase from "@material-ui/core/InputBase";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { FormHelperText } from "@material-ui/core";

import MenuItem from "@material-ui/core/MenuItem";

import CheckBoxes from "../../components/CheckBoxes";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchTractorRequest,
  resetTractors,
} from "../../../store/action/cmsActions/fetchTractorAction";
import {
  feebackRequest,
  resetFeedback,
} from "../../../store/action/cmsActions/feedbackAction";
import { resetQuestions } from "../../../store/action/cmsActions/questionsAction";
import { updateTicketsRequest } from "../../../store/action/cmsActions/fetchTicketAction";
import RenderQuestions from "../../components/Questions/RenderQuestions";

import { Formik, Form } from "formik";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Spinner from "../../assets/img/loader.svg";
import ExistingTickets from "../../components/ExistingTickets";

const useStyles = makeStyles((theme) => ({
  feedbackForm: {
    maxWidth: "60%",
    margin: "auto",
    height: "auto",

    [theme.breakpoints.down("md")]: {
      maxWidth: "80%",
    },
  },
  tractorForm: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "80%",
    width: "650px",
    margin: "2em auto",
  },
  searchInput: {
    borderRadius: theme.shape.borderRadius,
    minWidth: "400px",
    height: "55px",
    background: theme.palette.grey.main,
    outline: "none",
    padding: "0.5em 1em",
  },
  issueBlock: {
    width: "100%",
    margin: "2em 0",
    // "& > h3": {
    //   marginBottom: "2em",
    // },
  },
  questionBox: {
    margin: "2em 0",
  },
  submitBtnWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  submitBtn: {
    width: "270px",
    margin: "2em 0",
  },
  additionRemarks: {
    width: "100%",
    "& > div": {
      width: "100%",
    },
  },
}));

const MySwal = withReactContent(Swal);

const Feedback = ({ theme, ...props }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const [tractorSelected, setTractor] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const phone = params.get("phone");
  useEffect(() => {
    if (phone) {
      props.fetchTractors({ phone });
    } else {
      props.resetPrevTractors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  useEffect(() => {
    if (!props.formLoading && props.formSubmitted) {
      console.log("final");
      MySwal.fire({
        icon: "success",
        title:
          props.requestType === "Inbound Call"
            ? "Ticket Submitted"
            : "Ticket Closed",
        showConfirmButton: true,
        confirmButtonText: "Back to Home Screen",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((isConfirm) => {
        if (isConfirm) {
          props.resetFeedbackForm();
          props.resetQuestionArray();
          props.updateTickets({
            spoc: localStorage.getItem("username") || props.curSpoc,
          });
          history.push("/cms");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formLoading, props.formSubmitted]);
  return (
    <>
      <FormNav />
      <div className={classes.feedbackForm}>
        <Formik
          initialValues={{
            phoneNumber: phone || "",
          }}
          validate={(values) => {
            const errors = {};
            if (values.phoneNumber === "") {
              errors.phoneNumber = "*Required Field";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            props.fetchTractors({ phone: values.phoneNumber });
            setUserPhoneNumber(values.phoneNumber);
            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <div className={classes.tractorForm}>
                <FormControl error={errors.phoneNumber ? true : false}>
                  <InputBase
                    type="number"
                    name="phoneNumber"
                    className={classes.searchInput}
                    placeholder="Search by Device ID or Mobile No."
                    value={values.phoneNumber}
                    onChange={(e) =>
                      setFieldValue(e.target.name, e.target.value, false)
                    }
                  />
                  {errors.phoneNumber && touched.phoneNumber && (
                    <FormHelperText>{errors.phoneNumber}</FormHelperText>
                  )}
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    width: 230,
                    lineHeight: "55px",
                    backgroundColor: `${theme.palette.accent.main}`,
                    color: `${theme.palette.light.main}`,
                    boxShadow: "none",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  {props.tractorLoading ? (
                    <img
                      src={Spinner}
                      style={{ width: "55px" }}
                      alt="loading..."
                    />
                  ) : (
                    "GET TRACTORS"
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <FormControl
          variant="outlined"
          style={{
            margin: "2em 0",
            width: "100%",
          }}
          disabled={!props.tractors}
        >
          <InputLabel htmlFor="outlined-tractor-name-simple">
            Tractor Name
          </InputLabel>
          <Select
            value={tractorSelected}
            onChange={(e) => setTractor(e.target.value)}
            // value={state.age}
            // onChange={handleChange}
            label="Tractor Name"
            inputProps={{
              name: "Tractor Name",
              id: "outlined-tractor-name-simple",
            }}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}

            {props.tractors &&
              props.tractors.map((tractor) => (
                <MenuItem key={tractor.device_id} value={tractor.tractor_name}>
                  {tractor.tractor_name} ({tractor.device_id})
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {props.tractors && tractorSelected !== "" && (
          <ExistingTickets
            tractor={props.tractors.find(
              (tractor) => tractor.tractor_name === tractorSelected
            )}
          />
        )}
        <div className={classes.issueBlock}>
          <h3>Issues</h3>
          <CheckBoxes />
        </div>
        <div className={classes.questionBox}>
          <RenderQuestions />
        </div>
        <Formik
          initialValues={{
            remarks: "",
          }}
          // validate={(values) => {
          //   const errors = {};
          //   if (values.remarks === "") {
          //     errors.remarks = "*Required Field";
          //   }
          //   return errors;
          // }}
          onSubmit={(values, { setSubmitting }) => {
            let didNotSubmittedAnswer = false;
            for (let issue of props.issues) {
              console.log(issue);
              let { answers } = props;
              switch (issue) {
                case "area":
                  if (!answers.areaAnswers) {
                    didNotSubmittedAnswer = true;
                  }
                  break;
                case "distance":
                  if (!answers.distanceAnswers) {
                    didNotSubmittedAnswer = true;
                  }
                  break;
                case "fuel_consumption":
                  if (!answers.fuelConsumptionAnswers) {
                    didNotSubmittedAnswer = true;
                  }
                  break;
                case "fuel_level":
                  if (!answers.fuelLevelAnswers) {
                    didNotSubmittedAnswer = true;
                  }
                  break;
                case "job_time":
                  if (!answers.jobTimeAnswers) {
                    didNotSubmittedAnswer = true;
                  }
                  break;
                case "location":
                  if (!answers.locationAnswers) {
                    didNotSubmittedAnswer = true;
                  }
                  break;
                case "missing_alerts":
                  if (!answers.missingAlertsAnswers) {
                    didNotSubmittedAnswer = true;
                  }
                  break;
                case "others":
                  if (!answers.othersAnswers) {
                    didNotSubmittedAnswer = true;
                  }
                  break;
                case "recharge_request":
                  if (!answers.rechargeRequestAnswers) {
                    didNotSubmittedAnswer = true;
                  }
                  break;
                case "total_time":
                  if (!answers.totalTimeAnswers) {
                    didNotSubmittedAnswer = true;
                  }
                  break;
                case "trip_miss":
                  if (!answers.tripMissAnswers) {
                    didNotSubmittedAnswer = true;
                  }
                  break;
                case "too_many_alerts":
                  if (!answers.tooManyAlertsAnswers) {
                    didNotSubmittedAnswer = true;
                  }
                  break;
                default:
                  break;
              }
              if (didNotSubmittedAnswer) {
                break;
              }
            }
            if (didNotSubmittedAnswer) {
              MySwal.fire({
                icon: "warning",
                title: "Submit Answers",
                text:
                  "Please press Next in every question block to save the answers.",
              });
            } else {
              if (phone) {
                props.feedbackFormSubmit({
                  id: props.selectedTicket.id,
                  callType: "Callback Request",
                  device_id: props.tractors.find(
                    (tractor) => tractor.tractor_name === tractorSelected
                  ).device_id,
                  // device_id: 11800,
                  additional_remarks: values.remarks,
                  user_phone: props.selectedTicket.user_phone,
                  // spoc: props.selectedTicket.spoc,
                  spoc:
                    localStorage.getItem("username") ||
                    props.selectedTicket.spoc,
                });
              } else {
                props.feedbackFormSubmit({
                  id: "0",
                  callType: "Inbound Call",
                  device_id: props.tractors.find(
                    (tractor) => tractor.tractor_name === tractorSelected
                  ).device_id,
                  // device_id: 11800,
                  additional_remarks: values.remarks,
                  user_phone: userPhoneNumber,
                  spoc: localStorage.getItem("username") || props.curSpoc,
                });
              }
              setSubmitting(false);
            }
          }}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form style={{ width: "100%" }}>
              <div className={classes.additionRemarks}>
                <TextField
                  name="remarks"
                  id="outlined-multiline-static"
                  label="Additional Remarks"
                  multiline
                  rows={4}
                  value={values.remarks}
                  onChange={(e) =>
                    setFieldValue(e.target.name, e.target.value, false)
                  }
                  variant="outlined"
                />
              </div>
              <div className={classes.submitBtnWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submitBtn}
                  style={{
                    boxShadow: "none",
                    background: theme.palette.accent.main,
                    color: theme.palette.light.main,
                    lineHeight: "55px",
                  }}
                >
                  {props.formLoading ? (
                    <img
                      src={Spinner}
                      style={{ width: "55px" }}
                      alt="loading..."
                    />
                  ) : (
                    "SUBMIT"
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  tractors: state.tractorReducer.tractors,
  tractorLoading: state.tractorReducer.loading,
  selectedTicket: state.ticketReducer.selectedTicket,
  curSpoc: state.ticketReducer.spoc,
  formLoading: state.feedbackReducer.loading,
  formSubmitted: state.feedbackReducer.res,
  requestType: state.feedbackReducer.reqType,
  issues: state.issuesReducer.issues,
  answers: state.questionsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTractors: ({ phone }) => {
    dispatch(fetchTractorRequest({ phone }));
  },
  resetPrevTractors: () => {
    dispatch(resetTractors());
  },
  resetFeedbackForm: () => {
    dispatch(resetFeedback());
  },
  feedbackFormSubmit: ({
    id,
    callType,
    device_id,
    additional_remarks,
    user_phone,
    spoc,
  }) => {
    dispatch(
      feebackRequest({
        id,
        callType,
        device_id,
        additional_remarks,
        user_phone,
        spoc,
      })
    );
  },
  resetQuestionArray: () => {
    dispatch(resetQuestions());
  },
  updateTickets: ({ spoc }) => {
    dispatch(updateTicketsRequest({ spoc }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(Feedback));
