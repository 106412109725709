import { makeStyles } from "@material-ui/core/styles";

const createCampaignStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "2000px",
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    margin: "0 auto",
    background: theme.palette.grey.main,
    paddingTop: "1em",
    textAlign: "initial !important",
    "& h1,h2,h3,h4,h5,p": {
      margin: "0 ",
      padding: "0",
    },
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
    "& input[type=`number`]": {
      "-moz-appearance": "textfield",
    },
  },
  innerbox: {
    width: "60%",
    minHeight: "200px",
    margin: "3em auto",
    height: "auto",
    background: "#FFFFFF",
    borderRadius: "20px 20px 0 0 ",
    padding: "2em 4em",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      padding: "2em",
    },
  },
  questionBox: {
    width: "100%",
    margin: "1em 0",
    "& > h4": {
      marginBottom: "1em !important",
    },
  },
  sampleExcel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h4": {
      marginBottom: "1em !important",
    },
    "& > a": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "1em !important",
      cursor: "pointer",
    },
  },
  heading: {
    margin: "1em 0 4em 0",
    display: "flex",
    alignItems: "center",
    gap: ".7em",
  },
  backIcon: {
    cursor: "pointer",
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
  submitBtn: {
    // width: 140,
    lineHeight: "44px",
    backgroundColor: `${theme.palette.accent.main} !important`,
    color: `${theme.palette.light.main} !important`,
    boxShadow: "none !important",
    margin: "1.3em 0 0 0",
  },
  actionGroup: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
}));

export default createCampaignStyles;
