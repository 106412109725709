import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Loader from "../assets/img/loader.svg";
import { connect } from "react-redux";

import Skeleton from "@material-ui/lab/Skeleton";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "auto",
  },
  formNav: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1em 2em 2em 2em",
  },
  jotForm: {
    width: "100%",
    height: "100%",
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "0.4s",
    borderRadius: "20px 20px 0 0 ",
    background: theme.palette.light.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > img": {
      width: "100px",
    },
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
    cursor: "pointer",
    "&:hover": {
      background: "rgba(0,0,0,0.1)",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: theme.palette.grey.main,
    color: "#706F6F",
  },
  body: {
    fontSize: 14,
    color: theme.palette.dark.main,
    fontWeight: "bold",
  },
}))(TableCell);

const CampaignForm = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const { campaign } = location.state;
  const [jotformUrl, setJotformUrl] = useState("");
  let phoneNumber = JSON.parse(sessionStorage.getItem("number"));
  let agentName = localStorage.getItem("agent_name") ?? "";

  useEffect(() => {
    if (campaign) {
      setJotformUrl(
        `${campaign?.jotform_url}?codeHidden=${
          campaign.campaign_code
        }&campaignHidden=${
          campaign.campaign_name
        }&phoneHidden=${phoneNumber}&mobileNumber=${phoneNumber}&state=${
          campaign?.meta_info?.state ?? ""
        }&district=${campaign?.meta_info?.district ?? ""}&village=${
          campaign?.meta_info?.village ?? ""
        }&taluka=${campaign?.meta_info?.taluka ?? ""}&source=${
          campaign?.meta_info?.source ?? ""
        }&name=${campaign?.name ?? ""}&agentName=${agentName ?? ""}`
      );
    }
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  const renderLoading = (row, col) => {
    let range = [...Array(row).keys()];
    let range2 = [...Array(col).keys()];
    return range.map((i) => (
      <StyledTableRow key={i}>
        {range2.map((j) => (
          <StyledTableCell
            key={j + 10}
            style={{
              height: 60,
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Skeleton
              animation="wave"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </StyledTableCell>
        ))}
      </StyledTableRow>
    ));
  };

  const renderMetaInfoTableHead = (rest) => {
    return (
      <TableHead>
        <TableRow>
          {rest.selectedCampaign.meta_info &&
            Object.keys(rest.selectedCampaign.meta_info).map((item, index) => (
              <TableCell align="left" key={index} style={{ fontWeight: "600" }}>
                {item}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  const renderMetaInfoTableBody = (rest) => {
    if (rest.selectedCampaign && rest.selectedCampaign?.meta_info) {
      return (
        <TableRow>
          {Object.values(rest.selectedCampaign.meta_info).map((item, index) => (
            <TableCell align="left" key={index}>
              {item}
            </TableCell>
          ))}
        </TableRow>
      );
    } else {
      return (
        <p style={{ padding: "1em 0", marginBottom: "2em" }}>
          No Meta info available for this user.
        </p>
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.formNav}>
        <Link
          to="/outbound"
          style={{
            display: "flex",
            aligItem: "center",
            gap: ".2em",
          }}
        >
          <ArrowBackIosIcon style={{ fontSize: 20 }} />
          Back To List
        </Link>
        <h3>
          {campaign &&
            campaign.name !== "Unknown" &&
            `Campaign For : ${campaign.name}`}
        </h3>
      </div>
      <div style={{ marginTop: "2em" }}>
        <h4>META INFO:</h4>
        <TableContainer
          style={{
            marginTop: "1em",
          }}
        >
          <Table>
            {props.selectedCampaign && renderMetaInfoTableHead(props)}
            <TableBody>
              {props.selectedCampaign
                ? renderMetaInfoTableBody(props)
                : renderLoading(2, 3)}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={classes.jotForm}>
        {loading ? (
          <div className={classes.loader}>
            <img src={Loader} alt="loading..." />
          </div>
        ) : null}
        <iframe
          title="frame"
          id="JotFormIFrame"
          onSubmit={() => {
            history.push({ pathname: "/" });
          }}
          onLoad={() => {
            setLoading(false);
          }}
          allowtransparency="true"
          src={jotformUrl}
          frameBorder="0"
          style={{
            width: "100%",
            height: "66vh",
            border: "none",
          }}
          scrolling="yes"
        ></iframe>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedCampaign: state.campaignReducer.selectedCampaign,
});

export default connect(mapStateToProps, {})(CampaignForm);
