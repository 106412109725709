import { makeStyles } from "@material-ui/core/styles";

const embedformStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formContainerWrapper: {
    width: "100%",
    height: "100%",
    margin: "auto 80px",
    marginTop: "1em",
    backgroundColor: "#FAF3FF",
    borderRadius: "4px",
    boxShadow: "2px 2px 20px rgba(0,0,0,0.14)",
  },
  backIcon: {
    color: theme.palette.primary.main,
  },
  formNavContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1em 2em",
    borderBottom: `2px solid #dedede`,
  },
  formContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    position: "relative",
    minHeight: "100vh",
  },
  jotformContainer: {
    flex: 1,
    height: "100vh",
  },
  divider: {
    width: "2px",
    height: "100vh",
    background: "#dedede",
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: "10",
    display: "flex",
    justifyContent: "center",
    paddingTop: "5em",
    background: "#FAF3FF",
  },
}));

export default embedformStyles;
