import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../types";
import {
  fetchTicketSuccess,
  fetchTicketFailure,
} from "../../action/cmsActions/fetchTicketAction";
import { returnErrors, clearErrors } from "../../action/errorAction";
import * as _api from "../../../cmsApi";

function* asyncTicketRequestSaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(_api.fetchTickets, { spoc: action.payload.spoc });
    yield put(fetchTicketSuccess({ tickets: res }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(fetchTicketFailure(e));
  }
}

export default function* ticketRequestSaga() {
  yield takeLatest(types.FETCH_TICKET_REQUEST, asyncTicketRequestSaga);
}

export function* updateTicketsSaga() {
  yield takeLatest(types.UPDATE_TICKETS_REQUEST, asyncTicketRequestSaga);
}
