import React, { useState, useEffect } from "react";
import "./App.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";

import MainRoutes from "./router/MainRoute.jsx";
import Login from "../src/login/login";
import { Navbar, PrivateRoute } from "./elements";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#652078",
    },
    secondary: {
      main: "#FFFFFF",
    },
    accent: {
      main: "#F37021",
    },
    light: {
      main: "#FFFFFF",
    },
    grey: {
      main: "#FAF3FF",
    },
    dark: {
      main: "#575756",
    },
    black: {
      main: "#000000",
    },
    typography: {
      button: {
        textTransform: "none",
      },
      fontFamily: "'Noto Sans JP', sans-serif",
    },
  },
});

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [number, setNumber] = useState("");

  useEffect(() => {
    if (localStorage.getItem("user") > 1 && localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      let payload = jwt_decode(token);
      let expDate = new Date(0);
      expDate.setUTCSeconds(payload.exp);
      setLoggedIn(expDate > new Date() ? true : false);
    } else if (localStorage.getItem("user") === undefined) {
      setLoggedIn(false);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/login">
              <Login setLoggedIn={setLoggedIn} isLoggedIn={loggedIn} />
            </Route>
            <PrivateRoute isLoggedIn={loggedIn} exact path="*">
              <>
                <Navbar isLoggedIn={loggedIn} setLoggedIn={setLoggedIn} />
                <MainRoutes
                  number={number}
                  isLoggedIn={loggedIn}
                  setNumber={setNumber}
                />
              </>
            </PrivateRoute>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
