import * as types from "../../types";

export const fetchTicketRequest = ({ spoc }) => {
  return {
    type: types.FETCH_TICKET_REQUEST,
    payload: { spoc },
  };
};

export const fetchTicketSuccess = ({ tickets }) => {
  return {
    type: types.FETCH_TICKET_SUCCESS,
    payload: { tickets },
  };
};

export const fetchTicketFailure = (error) => {
  return {
    type: types.FETCH_TICKET_FAILURE,
    payload: {},
    error: error,
  };
};

export const updateTicketsRequest = ({ spoc }) => {
  return {
    type: types.UPDATE_TICKETS_REQUEST,
    payload: { spoc },
  };
};

// export const updateTickets = ({ tickets }) => {
//   return {
//     type: types.UPDATE_TICKETS,
//     payload: { tickets },
//   };
// };

export const cameFromHome = (cf) => {
  return {
    type: types.CAME_FROM_HOME,
    payload: { cameFromHome: cf },
  };
};
