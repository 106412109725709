import { makeStyles } from "@material-ui/core/styles";

const userRatingStyles = makeStyles((theme) => ({
  modalContainer: {
    width: "700px",
    minHeight: "400px",
    background: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    outline: "none",
    border: "none",
    borderRadius: "8px",
    padding: "2em",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "1em",
    },
    "& > * + *": {
      marginTop: "2em",
    },
  },
  modalTitle: {
    fontSize: "2em",
  },
  submitBtn: {
    lineHeight: "44px",
    backgroundColor: `${theme.palette.accent.main} !important`,
    color: `${theme.palette.light.main} !important`,
    boxShadow: "none !important",
    margin: "auto 0 0 0",
    width: "200px",
  },
  sentimentIcons: {
    width: "100px",
    background: "red",
    margin: "0 3em",
    // "& svg": {
    //   fontSize: "3em",
    // },
  },
}));

export default userRatingStyles;
