import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types";
import {
  fetchCampaignSuccess,
  fetchCampaignFailure,
} from "../action/fetchCampaignAction";
import { returnErrors, clearErrors } from "../action/errorAction";
import * as _api from "../../api";

function* asyncCampaignRequestSaga(action) {
  try {
    yield put(clearErrors());
    // let res = yield call(_api.fetchCampaigns, { spoc: action.payload.spoc });
    let res = yield call(_api.fetchCampaigns, { phone: action.payload.phone });
    yield put(fetchCampaignSuccess({ campaigns: res }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(fetchCampaignFailure(e));
  }
}

export default function* campaignRequestSaga() {
  yield takeLatest(types.FETCH_CAMPAIGN_REQUEST, asyncCampaignRequestSaga);
}

export function* updateCampaignSaga() {
  yield takeLatest(types.UPDATE_CAMPAIGN_REQUEST, asyncCampaignRequestSaga);
}
