import * as types from "../types";

export const fetchDataRequest = ({ body }) => {
  return {
    type: types.FETCH_DATA_REQUEST,
    payload: { body },
  };
};

export const fetchDataSuccess = ({ data }) => {
  return {
    type: types.FETCH_DATA_SUCCESS,
    payload: { data },
  };
};

export const fetchDataFailure = (error) => {
  return {
    type: types.FETCH_DATA_FAILURE,
    payload: {},
    error: error,
  };
};

export const fetchDataReset = () => {
  return {
    type: types.FETCH_DATA_RESET,
  };
};
