import React, { useState, useEffect } from "react";
import sideCallStyles from "./sidecall.styles";
import { useTheme } from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Button } from "../../elements";
import { useHistory } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

import Swal from "sweetalert2";
import { API_ROUTES, API_BASE_URL } from "../../config";

// import { formatDate, formatTimeInTwelve } from "../../utils";

const Sidecall = ({ url }) => {
  const classes = sideCallStyles();
  const theme = useTheme();
  const history = useHistory();

  const [data, setData] = useState("");
  const [formData, setformData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.JOTFORM_HISTORY}${url}/`)
      .then((val) => {
        setData(val.data.data);
        function getData(value, name) {
          let arr = [];
          val.data.data[value].forEach((val, idx) => {
            let obj = {
              id: val.ticket_id,
              form: name,
              day: val.created_at.split(" ")[0].split("-")[2],
              month: val.created_at.split(" ")[0].split("-")[1],
              year: val.created_at.split(" ")[0].split("-")[0],
              time:
                val.created_at.split(" ")[1].split(":")[0] +
                ":" +
                val.created_at.split(" ")[1].split(":")[1],
            };
            arr.push(obj);
          });
          arr = arr.sort((a, b) => a.year - b.year);
          arr = arr.sort((a, b) => a.month - b.month);
          arr = arr.sort((a, b) => a.day - b.day);
          setformData((prevState) => [...prevState, ...arr]);
        }

        getData("generalinquiry", "General Understanding of Krish-e");
        getData("implementinquiry", "Implement Inquiry");
        getData("miscellaneous", "Miscellaneous Inquiry");
        getData("rentalinquiry", "Krishe Rental Inquiry or Order Booking");
        getData("tractorinquiry", "Tractor Inquiry");
        getData("complaint", "Complaint");
        getData("advisory", "Advisory Inquiry");
        getData("ecommerce","Inbound Ecommerce");

        //   setformData([...formData].slice(0,4));
        setIsLoading(true);
      })
      .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEndCall = () => {
    const body = [
      {
        Mobile:
          data?.farmer_data?.phone.length === 10
            ? "91" + data?.farmer_data?.phone
            : data?.farmer_data?.phone,
        State: data?.farmer_data?.state,
        Activity_Name: sessionStorage.getItem("form") ?? "",
        Call_Type: "Inbound",
      },
    ];
    let agentName = localStorage.getItem("agent_name");
    if (agentName) {
      body[0].Agent_Name = agentName;
    }
    Swal.fire({
      title: "Do you want to make the IVR Call?",
      showDenyButton: true,
      icon: "info",
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${API_BASE_URL.CRM_FAST_API}${API_ROUTES.TRIGGER_IVR}`, body)
          .then((res) => console.log(res))
          .catch((err) => {
            axios
              .post(
                `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.TRIGGER_IVR}`,
                body
              )
              .then((res) => console.log(res))
              .catch((err) => console.log(err));
          });
      }
    });
    let phoneNumber = sessionStorage.getItem("number");
    sessionStorage.clear();
    history.push({ pathname: "/sms-trigger", state: { phone: phoneNumber } });
  };

  if (!isLoading) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.sideBarWrapper}>
      <div className={classes.sideBarTop}>
        <div className={classes.customerInfo}>{data?.farmer_data?.name}</div>
        <div className={classes.customerLocation}>
          <span>
            <LocationOnIcon />
          </span>
          <p>
            {data?.farmer_data?.taluka},{data?.farmer_data?.district},{" "}
            {data?.farmer_data?.state}
          </p>
        </div>
        <p className={classes.phoneNumber}>
          <span>
            <PhoneIcon />
          </span>{" "}
          {data?.farmer_data?.phone}
        </p>
        <Button
          variant="contained"
          style={{
            width: "100%",
            margin: "auto",
            boxShadow: "none",
            background: theme.palette.accent.main,
            color: theme.palette.light.main,
            lineHeight: "40px",
          }}
          onClick={handleEndCall}
        >
          END CALL
        </Button>
      </div>
      <div className={classes.recentOrders}>
        <h3>All Orders</h3>
        <div className={classes.orderList}>
          {formData
            .slice(1)
            .slice(-6)
            .map((val) => (
              <div className={classes.order} key={val.id}>
                <div className={classes.orderInfo}>
                  <div className={classes.farmName}>{val?.form}</div>
                  <p>
                    <DateRangeIcon
                      style={{
                        fontSize: "1em",
                        color: theme.palette.primary.main,
                      }}
                    />{" "}
                    {/* {formatDate(order.job_start_time)} */}
                    {val.day}/{val.month}/{val.year}
                  </p>
                  <p>{val.time}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Sidecall;
