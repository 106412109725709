import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Form, Field, Formik } from "formik";
import logo from "../assets/Krish-Hindi.svg";
import Button from "@material-ui/core/Button";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";
import InputField from "../elements/InputField";
import { Redirect, useLocation } from "react-router-dom";
import { API_ROUTES, API_BASE_URL } from "../config";

const useStyles = makeStyles((theme) => ({
  loginWrapper: {
    width: "100%",
    height: "100vh",
    display: "grid",
    placeContent: "center",
    backgroundColor: "#652278",
  },
  login: {
    margin: "auto",
    width: "500px",
    background: "#ffffff",
    padding: "2em 3em 4em 3em",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "2em",
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
  },
  logo: {
    width: "100%",
    "& > img": {
      maxWidth: "240px",
    },
  },
  loginForm: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "2em",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function Login({ setLoggedIn, isLoggedIn }) {
  const classes = useStyles();
  const location = useLocation();
  const [error, setError] = useState(false);
  const [data, SetData] = useState({});
  const [loading, setLoading] = useState(false);
  let config = { ApiKey: `41k59h2PEWniwk3QD0IjnYFYC7X92qPz` };

  useEffect(() => {
    if (data.user_name !== undefined && data.password !== undefined) {
      setLoading(true);
      axios
        .post(`${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.LOGIN_API}`, data, {
          headers: config,
        })
        .then((val) => {
          setLoading(false);
          if (val.data.status) {
            localStorage.setItem("user", val.data.data.user_id);
            localStorage.setItem("token", val.data.data.token);
            localStorage.setItem("username", data.user_name);
            localStorage.setItem("analytics", val.data.data.show_analytics);
            localStorage.setItem("agent_name", val.data.data.agent_name);
            setLoggedIn(true);
          } else {
            setError(true);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoggedIn) {
    return <Redirect to={location.state?.from ?? "/"} />;
  }

  return (
    <div className={classes.loginWrapper}>
      <div className={classes.login}>
        {error && (
          <Alert severity="error">Invalid Username or Password !</Alert>
        )}
        <div className={classes.logo}>
          <img src={logo} alt="Krishe Logo" className={classes.icon} />
        </div>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validate={(values) => {
            const errors = {};
            if (values.username === "") {
              errors.username = "*Username is Required!!";
            }
            if (values.password === "") {
              errors.password = "*Password is Required!!";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setError(false);
            SetData({
              user_name: values.username,
              password: values.password,
            });
          }}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form className={classes.loginForm}>
              <Field
                name="username"
                type="text"
                id="username-input"
                label="Username"
                labelWidth={70}
                component={InputField}
              />
              <Field
                name="password"
                type="password"
                id="password-input"
                label="Password"
                labelWidth={70}
                component={InputField}
              />
              <Button
                variant="contained"
                color="primary"
                disableElevation
                disabled={loading}
                style={{
                  backgroundColor: "rgb(243, 112, 33)",
                  color: "white",
                  width: "100%",
                  padding: "1em",
                }}
                type="submit"
              >
                {loading ? "Logging In" : "LogIn"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
