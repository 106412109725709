import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Card from "@material-ui/core/Card";
import PhoneIcon from "@material-ui/icons/Phone";
import CircularProgress from "@material-ui/core/CircularProgress";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_ROUTES, API_BASE_URL } from "../config";
const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: "#652078",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  text: {
    fontSize: "30px",
    margin: "20px auto",
    color: "white",
    marginTop: "auto",
    border: "1px solid white",
    width: "400px",
  },
  card: {
    width: "400px",
    height: "auto",
    margin: "auto",
    backgroundColor: "",
  },
  subText: {
    fontSize: "20px",
    margin: "12px",
  },
  subCard: {
    width: "300px",
    margin: "16px auto",
    border: "1px solid black",
  },
}));

export default function NewOrder({ number, username }) {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState({});
  const [loading, isLoading] = useState(false);
  const [list, setList] = useState([]);
  const [farms, setFarms] = useState([]);
  const [farm_land, setFarmLand] = useState("");
  const [submitFarm, setSubmitFarm] = useState("");

  useEffect(() => {
    let body = {
      farmer_phone: sessionStorage.getItem("number"),
    };
    axios
      .post(
        `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.CHECK_SERVICABILITY}`,
        body
      )
      .then((val) => {
        if (val.data.data.is_servicable === false) {
          history.push("/cancelOrder");
        }
        console.log(JSON.stringify(val.data) + " " + JSON.stringify(body));
        // console.log(val.data.body.data[0]);
        setData(val.data.data);
        setFarms(Object.values(val.data.data.farms));
        setList(val.data.data.available_services);

        isLoading(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSubmitFarm(farm_land);
  }, [farm_land, farms]);

  if (!loading) {
    return <CircularProgress></CircularProgress>;
  }
  const handleChange = (event) => {
    // setFarmLand(event.target.value);
    // // if (event.target.value === "addFarm") {
    // //   history.push({
    // //     pathname: "/addfarm",
    // //     state: { type: data.servicable_by, dataList: list },
    // //   });
    // }
  };
  return (
    <div className={classes.row}>
      {/* <Card> */}
      <p className={classes.text}>
        Servicablity: {data.is_servicable ? "true" : "false"}
        <br />
      </p>
      <p className={classes.text}>Served By: {data.servicable_by}</p>
      <Card className={classes.card}>
        <h2>Farm Details:</h2>
        <p style={{ fontSize: "20px" }}>
          {" "}
          <PermIdentityIcon />
          <b>Name:</b> {sessionStorage.getItem("farmer_name")}
        </p>
        <p style={{ fontSize: "20px" }}>
          <PhoneIcon />
          <b>Number:</b> {sessionStorage.getItem("number")}
        </p>
        <span>
          <b>Select Farmland:</b>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={farm_land.name}
            onChange={handleChange}
            style={{ width: "200px" }}
          >
            {farms.map((val) => {
              return <MenuItem value={val}>{val.farm_name}</MenuItem>;
            })}
            <MenuItem value="addFarm">ADD FARM</MenuItem>
          </Select>
        </span>
        <Card className={classes.subCard}>
          <p className={classes.subText}>
            <b>name:</b>
            {farm_land.farm_name}
            <br />
          </p>
          <p className={classes.subText}>
            <b>address:</b>
            {farm_land.address}
          </p>
          <p className={classes.subText}>
            <b>village:</b>
            {farm_land.village}
          </p>
          <p className={classes.subText}>
            <b>taluka:</b>
            {farm_land.taluka}
          </p>
          <p className={classes.subText}>
            <b>district:</b>
            {farm_land.district}
          </p>
          <p className={classes.subText}>
            <b>state:</b>
            {farm_land.state}
          </p>
        </Card>
        {farm_land !== "" && (
          <Button
            onClick={() => {
              data.servicable_by === "hub"
                ? history.push({
                    pathname: "/submitOrder",
                    state: {
                      data: list,
                      farm: submitFarm,
                      type: data.servicable_by,
                      new_farm: false,
                    },
                  })
                : history.push({
                    pathname: "/submitOrder",
                    state: {
                      data: list,
                      farm: submitFarm,
                      type: data.servicable_by,
                      new_farm: false,
                    },
                  });
            }}
            style={{
              backgroundColor: "#F37021",
              color: "white",
              fontSize: "16px",
              margin: "20px auto",
              width: "200px",
              height: "48px",
            }}
          >
            Next
          </Button>
        )}
      </Card>
      {/* </Card> */}
    </div>
  );
}
