import React, { useState, useEffect } from "react";
import emojiRatingStyles from "./emojirating.styles";
import Happy from "../../assets/emojis/happy.png";
import Sad from "../../assets/emojis/sad.png";
import Neutral from "../../assets/emojis/neutral.png";
import Tooltip from "@material-ui/core/Tooltip";

const emojis = [
  {
    rank: 1,
    icon: Sad,
    label: "Sad",
  },
  {
    rank: 2,
    icon: Neutral,
    label: "Neutral",
  },
  {
    rank: 3,
    icon: Happy,
    label: "Happy",
  },
];

const EmojiRating = ({ onChange, ...rest }) => {
  const classes = emojiRatingStyles();
  let [rank, setRank] = useState({ label: "Neutral", count: 2 });

  useEffect(() => {
    onChange("Neutral", 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIconClick = (el, er) => {
    setRank({ label: el, count: er });
    onChange(el, er);
  };
  return (
    <div className={classes.ratingContainer}>
      {emojis.map((emoji) => (
        <Tooltip title={emoji.label} key={emoji.rank}>
          <img
            src={emoji.icon}
            alt={emoji.lable}
            className={
              emoji.rank <= rank.count ? classes.active : classes.inactive
            }
            onClick={() => handleIconClick(emoji.label, emoji.rank)}
          />
        </Tooltip>
      ))}
    </div>
  );
};

export default EmojiRating;
