import * as types from "../../types";
import { formatDateInYYMMDD, formatTime } from "../../../utils";

export const areaQuestions = ({ actualArea, areaInApp, date }) => {
  return {
    type: types.AREA_ANSWERS,
    payload: {
      areaQna: [
        ["On Which Day is the Area Wrong?", formatDateInYYMMDD(date)],
        ["What is the Area shown in app?", areaInApp.toString()],
        ["How much should area be?", actualArea.toString()],
      ],
    },
  };
};

export const distanceQuestions = ({ date, curDistance, actualDistance }) => {
  return {
    type: types.DISTANCE_ANSWERS,
    payload: {
      distanceQna: [
        ["On Which Day is the Distance Wrong?", formatDateInYYMMDD(date)],
        ["What is the Distance shown currently?", curDistance.toString()],
        ["How much should distance be?", actualDistance.toString()],
      ],
    },
  };
};

export const fuelConsumptionQuestions = ({
  date,
  fuelConsumed,
  consumedInApp,
}) => {
  return {
    type: types.FUEL_CONSUMPTION_ANSWERS,
    payload: {
      fuelConsumedQna: [
        [
          "On Which Day is the Fuel Consumption Wrong?",
          formatDateInYYMMDD(date),
        ],
        [
          "How much fuel was consumed as per the customer?",
          fuelConsumed.toString(),
        ],
        [
          "How much fuel consumption is shown in app?",
          consumedInApp.toString(),
        ],
      ],
    },
  };
};

export const FuelLevelQuestions = ({
  date,
  fuelMeterWorks,
  fuelAvailable,
  fuelInApp,
}) => {
  return {
    type: types.FUEL_LEVEL_ANSWERS,
    payload: {
      fuelLevelQna: [
        ["Is the Fuel Meter on dashboard working properly?", fuelMeterWorks],
        [
          "How much fuel is there in the tank as per customer?",
          fuelAvailable.toString(),
        ],
        ["How much fuel is shown in the app?", fuelInApp.toString()],
        ["On Which Day is the Fuel Wrong?", formatDateInYYMMDD(date)],
      ],
    },
  };
};

export const jobTimeQuestions = ({ date, actualPatchTime, patchTime }) => {
  return {
    type: types.JOB_TIME_ANSWERS,
    payload: {
      jobTimeQna: [
        ["On Which Day is the Patch Time Wrong?", formatDateInYYMMDD(date)],
        ["How much should patch time be?", formatTime(actualPatchTime)],
        ["What is the patch time shown currently?", formatTime(patchTime)],
      ],
    },
  };
};

export const locationQuestions = ({ lastUsed }) => {
  return {
    type: types.LOCATION_ANSWERS,
    payload: {
      locationQna: [
        ["When was the tractor last used?", formatDateInYYMMDD(lastUsed)],
      ],
    },
  };
};

export const missingAlertsQuestions = ({
  date,
  alertIssue,
  missedAlertType,
}) => {
  return {
    type: types.MISSING_ALERTS_ANSWERS,
    payload: {
      missingAlertsQna: [
        ["On Which Day alert is missing?", formatDateInYYMMDD(date)],
        ["Which type of alert is missed?", missedAlertType],
        ["What is the source of alert notification issue?", alertIssue],
      ],
    },
  };
};

export const othersQuestions = ({ date, issue }) => {
  console.log(date);
  return {
    type: types.OTHERS_ANSWERS,
    payload: {
      othersQna: [
        ["Please describe the issue", issue],
        ["On Which Day is the Issue Seen?", formatDateInYYMMDD(date)],
      ],
    },
  };
};

export const rechargeRequestQuestions = ({
  paymentToBeRecieved,
  customerAddress,
  rechargeType,
  freeMonths,
}) => {
  console.log(paymentToBeRecieved);
  return {
    type: types.RECHARGE_REQUEST_ANSWERS,
    payload: {
      rechargeRequestQna: [
        ["Payment to be received?", paymentToBeRecieved.toString()],
        ["Customer's Address details", customerAddress],
        ["Enter Recharge Type", rechargeType],
        ["Enter additional free months(If Any)", freeMonths.toString()],
      ],
    },
  };
};

export const tooManyAlertsQuestions = ({
  date,
  missedAlertType,
  alertIssue,
}) => {
  return {
    type: types.TOO_MANY_ANSWERS,
    payload: {
      tooManyAlertsQna: [
        [
          "On Which Day too many alerts are received ?",
          formatDateInYYMMDD(date),
        ],
        [
          "Which type of alert is received more than expected?",
          missedAlertType,
        ],
        ["What is the source of alert notification issue?", alertIssue],
      ],
    },
  };
};

export const totalTimeQuestions = ({ date, actualTime, time }) => {
  return {
    type: types.TOTAL_TIME_ANSWERS,
    payload: {
      totalTimeQna: [
        ["On Which Day is the Time Wrong?", formatDateInYYMMDD(date)],
        ["What is the time shown currently?", formatTime(time)],
        ["How much should time be?", formatTime(actualTime)],
      ],
    },
  };
};

export const tripMissQuestions = ({ lastDrivenCustomer, lastDrivenApp }) => {
  console.log(lastDrivenApp);
  return {
    type: types.TRIP_MISS_ANSWERS,
    payload: {
      tripMissQna: [
        [
          "When was the tractor last driven as per customer?",
          formatDateInYYMMDD(lastDrivenCustomer),
        ],
        [
          "When was the tractor last driven as per App?",
          formatDateInYYMMDD(lastDrivenApp),
        ],
      ],
    },
  };
};

export const resetQuestions = () => {
  return {
    type: types.RESET_QUESTIONS,
  };
};
