import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types";
import { fetchServices } from "../../api";
import {
  fetchServicesSuccess,
  fetchServicesFailure,
} from "../action/fetchServicesAction";
import { returnErrors, clearErrors } from "../action/errorAction";

function* asyncfetchServicesSaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(fetchServices);
    yield put(fetchServicesSuccess({ data: res.data }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(fetchServicesFailure(e));
  }
}
export default function* fetchServicesSaga() {
  yield takeLatest(types.FETCH_SERVICES_REQUEST, asyncfetchServicesSaga);
}
