import * as types from "../types";

export const createCampaignRequest = ({ data }) => {
  return {
    type: types.CREATE_CAMPAIGN_REQUEST,
    payload: { data },
  };
};

export const createCampaignSuccess = ({ res }) => {
  return {
    type: types.CREATE_CAMPAIGN_SUCCESS,
    payload: { res },
  };
};

export const createCampaignFailure = (error) => {
  return {
    type: types.CREATE_CAMPAIGN_FAILURE,
    payload: {},
    error: error,
  };
};

export const resetCreateCampaign = () => {
  return {
    type: types.RESET_CREATE_CAMPAIGN,
  };
};
