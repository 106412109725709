import React, { useEffect, useState } from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import FormNav from "../../components/FormNav";

import { Button } from "../../elements";
import LocationData from "../../../data/loaction.json";

import Rating from "@material-ui/lab/Rating";
import DatePicker from "../../components/DatePicker";
import InputField from "../../components/Fields/InputField";
import InputText from "../../components/Fields/InputText";
import InputSelect from "../../components/Fields/InputSelect";
import InputAutocomplete from "../../components/Fields/InputAutocomplete";

import { useLocation, useHistory } from "react-router-dom";
import Spinner from "../../assets/img/loader.svg";

import { Formik, Form, Field, useFormikContext } from "formik";
import { connect } from "react-redux";
import {
  welcomeCallRequest,
  resetWelcome,
} from "../../../store/action/cmsActions/welcomeAction";
import { formatDateInYYMMDD } from "../../../utils";
import { updateTicketsRequest } from "../../../store/action/cmsActions/fetchTicketAction";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { LocalActivity } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  welcomeForm: {
    maxWidth: "60%",
    margin: "auto",

    [theme.breakpoints.down("md")]: {
      maxWidth: "80%",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "1.3em 0 !important",

    "& > h3": {
      margin: "1.3em 0 !important",
    },
  },
  ratingBlock: {
    display: "flex",
    alignItems: "center",
    gap: "1em",
    // margin: "1.3em 0",
    "& > p": {
      fontSize: "1em",
    },
  },
  inputGroup: {
    // display: "flex",
    // alignItems: "flex-start",
    // justifyContent: "flex-start",
    // flexWrap: "wrap",
    // "& > *": {
    //   width: "200px",
    //   margin: "0 2em 2em 0 !important",
    // },
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gap: "1em",
  },
  datePickerWrapper: {
    width: "240px",
    display: "flex",
    flexDirection: "column",
    gap: "1em",

    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  submitBtnWrapper: {
    display: "flex",
    alignItems: "center",
  },
  submitBtn: {
    width: "270px",
    marginBottom: "2em !important",
  },
}));

const MySwal = withReactContent(Swal);

const SetData = ({ setDistrictArr, setTalukaArr, districtArr }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (Object.keys(LocationData).includes(values.state)) {
      let districts = Object.keys(LocationData[values.state]);
      setDistrictArr(districts);
    }
    setFieldValue("district", "", false);
    setFieldValue("taluka", "", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.state]);

  useEffect(() => {
    if (
      Object.keys(LocationData).includes(values.state) &&
      districtArr.includes(values.district)
    ) {
      let talukas = Object.keys(LocationData[values.state][values.district]);
      setTalukaArr(talukas);
    }
    setFieldValue("taluka", "", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.district]);

  return null;
};

const Welcome = ({ theme, ...props }) => {
  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const id = params.get("id");

  const [stateArr, setStateArr] = useState([]);
  const [districtArr, setDistrictArr] = useState([]);
  const [talukaArr, setTalukaArr] = useState([]);

  useEffect(() => {
    let data = Object.keys(LocationData);
    setStateArr(data);
    // let data = Object.keys(LocationData);
    // data = data.map((item) => ({ value: item }));
    // setStateArr(data);
  }, []);

  useEffect(() => {
    if (!props.formLoading && props.formSubmitted) {
      console.log("final");
      MySwal.fire({
        icon: "success",
        title: "Ticket Closed",
        showConfirmButton: true,
        confirmButtonText: "Back to Home Screen",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((isConfirm) => {
        if (isConfirm) {
          props.resetWelcomeForm();
          props.updateTickets({
            spoc: localStorage.getItem("username") || props.curSpoc,
          });
          history.push("/cms");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formLoading, props.formSubmitted]);
  return (
    <>
      <FormNav />
      <Formik
        initialValues={{
          customer_type: "",
          pincode: "",
          address: "",
          village: "",
          taluka: "",
          district: "",
          state: "",
          happiness_index: "",
          installation_experience: "",
          installation_experience_other: "",
          krishe_rental_partner_program: "",
          program_name: "",
          existing_program_name: "",
          n_tractors: "",
          ksk_installation_tractor_year: "",
          going_to_mh_dealership: "",
          existing_program: "",
          appx_tractor_buying_date: new Date(),
          appx_implement_buying_date: new Date(),
        }}
        validate={(values) => {
          const errors = {};
          if (values.customer_type === "") {
            errors.customer_type = "*Required Field!";
          }
          if (values.district === "") {
            errors.district = "*Required Field";
          }
          if (values.state === "") {
            errors.state = "*Required Field";
          }
          if (values.appx_tractor_buying_date === "") {
            errors.appx_tractor_buying_date = "*Required Field";
          }
          if (values.appx_implement_buying_date === "") {
            errors.appx_implement_buying_date = "*Required Field";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          let {
            program_name,
            existing_program_name,
            installation_experience_other,
            ...formData
          } = values;

          let requestData = {
            id: id,
            type: "Welcome Call",
            response: [
              {
                ...formData,
                krishe_rental_partner_program:
                  values.krishe_rental_partner_program === "other"
                    ? values.program_name
                    : values.krishe_rental_partner_program,
                existing_program:
                  values.existing_program === "other"
                    ? values.existing_program_name
                    : values.existing_program,
                installation_experience:
                  values.installation_experience === "other"
                    ? values.installation_experience_other
                    : values.installation_experience,
                appx_implement_buying_date: formatDateInYYMMDD(
                  values.appx_implement_buying_date
                ),
                appx_tractor_buying_date: formatDateInYYMMDD(
                  values.appx_tractor_buying_date
                ),
              },
            ],
          };
          props.submitWelcomeForm({ data: requestData });
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, errors, touched, handleChange }) => (
          <Form>
            <div className={classes.welcomeForm}>
              <div style={{ marginTop: "2em" }}>
                <Field
                  name="customer_type"
                  id="customer-type"
                  label="Please Select Customer Type"
                  labelWidth={100}
                  options={[
                    { label: "Self", value: "self" },
                    { label: "Rental trolley", value: "rental_trolley" },
                    { label: "Rental ploughing", value: "rental_ploughing" },
                    {
                      label: "Rental trolley + Rental farming",
                      value: "rental_trolley+rental_farming",
                    },
                  ]}
                  component={InputSelect}
                />
              </div>
              <div className={classes.wrapper}>
                <h3>Happiness Index</h3>
                <div className={classes.ratingBlock}>
                  <Rating
                    name="happiness_index"
                    value={values.happiness_index}
                    onChange={(event, newValue) => {
                      setFieldValue("happiness_index", newValue, false);
                    }}
                    size="large"
                  />
                  <p>Rate between 1 to 5 (1 Sad and 5 Happiest)</p>
                </div>
              </div>
              <div className={classes.wrapper}>
                <h3>
                  Are you looking to change Tractor soon? If Yes, Mention date
                  below
                </h3>
                <div className={classes.datePickerWrapper}>
                  <Field
                    name="appx_tractor_buying_date"
                    disablePast={true}
                    component={DatePicker}
                  />
                </div>
              </div>
              <div className={classes.wrapper}>
                <h3>
                  Are you looking to buy any new Implement soon? If Yes, Mention
                  date below
                </h3>

                <div className={classes.datePickerWrapper}>
                  <Field
                    name="appx_implement_buying_date"
                    disablePast={true}
                    component={DatePicker}
                  />
                </div>
              </div>
              <div className={classes.wrapper}>
                <h3>Overall installation experience</h3>

                <div className={classes.datePickerWrapper}>
                  <Field
                    name="installation_experience"
                    id="overall_customer_experience"
                    label="Overall Experience"
                    labelWidth={140}
                    options={[
                      { label: "Good", value: "good" },
                      { label: "Bad", value: "bad" },
                      { label: "Other", value: "other" },
                    ]}
                    component={InputSelect}
                  />
                  {values.installation_experience === "other" && (
                    <Field
                      name="installation_experience_other"
                      type="text"
                      id="customer_installation_experience_other"
                      label="Experience"
                      labelWidth={70}
                      component={InputField}
                    />
                  )}
                </div>
              </div>
              <div className={classes.wrapper}>
                <h3>krishi-e rental partner program</h3>

                <div className={classes.datePickerWrapper}>
                  <Field
                    name="krishe_rental_partner_program"
                    id="customer_partner_program"
                    label="Partner Program"
                    labelWidth={140}
                    options={[
                      { label: "Dealership", value: "dealership" },
                      {
                        label: "Mahindra FCM/ FRM",
                        value: "Mahindra FCM/ FRM",
                      },
                      {
                        label: "Marketing campaign",
                        value: "Marketing campaign",
                      },
                      { label: "Friend", value: "friend" },
                      { label: "Other", value: "other" },
                    ]}
                    component={InputSelect}
                  />
                  {values.krishe_rental_partner_program === "other" && (
                    <Field
                      name="program_name"
                      type="text"
                      id="customer_krishe_rental_partner_program"
                      label="Program Name"
                      labelWidth={110}
                      component={InputField}
                    />
                  )}
                </div>
              </div>
              <div className={classes.wrapper}>
                <h3>How many tractor</h3>

                <div className={classes.datePickerWrapper}>
                  <Field
                    name="n_tractors"
                    type="number"
                    id="customer_number_of_tractors"
                    label="Tractors"
                    labelWidth={50}
                    component={InputField}
                  />
                </div>
              </div>
              <div className={classes.wrapper}>
                <h3>KSK installation Tractor year ?</h3>

                <div className={classes.datePickerWrapper}>
                  <Field
                    name="ksk_installation_tractor_year"
                    type="number"
                    id="customer_ksk_installation_year"
                    label="KSK Installation Year"
                    labelWidth={140}
                    component={InputField}
                  />
                </div>
              </div>
              <div className={classes.wrapper}>
                <h3>Have you visited Mahindra dealership in last 1 year?</h3>

                <div className={classes.datePickerWrapper}>
                  <Field
                    name="going_to_mh_dealership"
                    id="more_than_year_visit_customer"
                    label="Dealership Visit"
                    labelWidth={140}
                    options={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ]}
                    component={InputSelect}
                  />
                </div>
              </div>
              <div className={classes.wrapper}>
                <h3>Did you join any existing program ?</h3>
                {/* Shree,samrat,raja,other,non */}
                <div className={classes.datePickerWrapper}>
                  <Field
                    name="existing_program"
                    id="existing_program_customer"
                    label="Existing Program"
                    labelWidth={140}
                    options={[
                      { label: "Shree", value: "shree" },
                      { label: "Samrat", value: "samrat" },
                      { label: "Raja", value: "raja" },
                      { label: "Other", value: "other" },
                      { label: "None", value: "none" },
                    ]}
                    component={InputSelect}
                  />
                  {values.existing_program === "other" && (
                    <Field
                      name="existing_program_name"
                      type="text"
                      id="customer_krishe_rental_partner_program"
                      label="Program Name"
                      labelWidth={110}
                      component={InputField}
                    />
                  )}
                </div>
              </div>
              <div className={classes.wrapper}>
                <h3>Customer Residential Details</h3>
                <div className={classes.inputGroup}>
                  <Field
                    name="state"
                    type="text"
                    id="customer-state"
                    label="State"
                    labelWidth={45}
                    data={stateArr}
                    component={InputAutocomplete}
                  />
                  {/* <Field
                    name="state"
                    type="text"
                    id="customer-state"
                    label="State"
                    labelWidth={45}
                    component={InputField}
                  /> */}
                  <Field
                    name="district"
                    type="text"
                    id="customer-district"
                    label="District"
                    labelWidth={45}
                    data={districtArr}
                    component={InputAutocomplete}
                  />
                  {/* <Field
                    name="district"
                    type="text"
                    id="customer-district"
                    label="District"
                    labelWidth={45}
                    component={InputField}
                  /> */}
                  <Field
                    name="taluka"
                    type="text"
                    id="customer-taluka"
                    label="Taluka"
                    labelWidth={45}
                    data={talukaArr}
                    component={InputAutocomplete}
                  />
                  {/* <Field
                    name="taluka"
                    type="text"
                    id="customer-taluka"
                    label="Taluka"
                    labelWidth={45}
                    component={InputField}
                  /> */}
                  <Field
                    name="village"
                    type="text"
                    id="customer-village"
                    label="Village"
                    labelWidth={45}
                    component={InputField}
                  />
                  <Field
                    name="pincode"
                    id="customer-pin-code"
                    label="Pin Code"
                    labelWidth={70}
                    component={InputField}
                  />
                  <Field
                    name="address"
                    type="text"
                    id="customer-address-details"
                    label="Address"
                    elStyle={{ gridColumn: "1 / span 3" }}
                    component={InputText}
                  />
                </div>
              </div>
              <SetData
                districtArr={districtArr}
                setDistrictArr={setDistrictArr}
                setTalukaArr={setTalukaArr}
              />
              <div className={classes.submitBtnWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submitBtn}
                  style={{
                    boxShadow: "none",
                    background: theme.palette.accent.main,
                    color: theme.palette.light.main,
                    lineHeight: "55px",
                  }}
                >
                  {props.formLoading ? (
                    <img
                      src={Spinner}
                      style={{ width: "55px" }}
                      alt="loading..."
                    />
                  ) : (
                    "SUBMIT"
                  )}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  formLoading: state.welcomeReducer.loading,
  formSubmitted: state.welcomeReducer.res,
});
const mapDispatchToProps = (dispatch) => ({
  submitWelcomeForm: ({ data }) => {
    dispatch(welcomeCallRequest({ data }));
  },
  resetWelcomeForm: () => {
    dispatch(resetWelcome());
  },
  updateTickets: ({ spoc }) => {
    dispatch(updateTicketsRequest({ spoc }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Welcome));
