export const FETCH_DATA = "FETCH_DATA";
export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_ORDER = "FETCH_ORDER";
export const FETCH_ORDER_DATA = "FETCH_ORDER_DATA";
export const CREATE_ORDER = "CREATE_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_ERRORS = "GET_ERRORS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";

// FETCH TICKETS
export const FETCH_TICKET_REQUEST = "FETCH_TICKET_REQUEST";
export const FETCH_TICKET_SUCCESS = "FETCH_TICKET_SUCCESS";
export const FETCH_TICKET_FAILURE = "FETCH_TICKET_FAILURE";
export const FETCH_DATA_RESET = "FETCH_DATA_RESET";
export const UPDATE_TICKETS_REQUEST = "UPDATE_TICKETS_REQUEST";
export const UPDATE_TICKETS = "UPDATE_TICKETS";

// FETCH TRACTORS
export const FETCH_TRACTOR_REQUEST = "FETCH_TRACTOR_REQUEST";
export const FETCH_TRACTOR_SUCCESS = "FETCH_TRACTOR_SUCCESS";
export const FETCH_TRACTOR_FAILURE = "FETCH_TRACTOR_FAILURE";
export const RESET_TRACTORS = "RESET_TRACTORS";

// SELECT TICKET
export const SELECT_TICKET = "SELECT_TICKET";
// export const SELECT_TICKET_REQUEST = "SELECT_TICKET_REQUEST";
// export const SELECT_TICKET_SUCCESS = "SELECT_TICKET_SUCCESS";
// export const SELECT_TICKET_FAILURE = "SELECT_TICKET_FAILURE";

// SEARCH REQUEST
export const SEARCH_TICKET_REQUEST = "SEARCH_TICKET_REQUEST";
export const SEARCH_TICKET_SUCCESS = "SEARCH_TICKET_SUCCESS";
export const SEARCH_TICKET_FAILURE = "SEARCH_TICKET_FAILURE";

// ISSUE TYPE
export const ISSUE_TYPE_REQUEST = "ISSUE_TYPE_REQUEST";
export const ADD_ISSUE_TYPE = "ADD_ISSUE_TYPE";
export const REMOVE_ISSUE_TYPE = "REMOVE_ISSUE_TYPE";
export const RESET_ISSUE_TYPE = "RESET_ISSUE_TYPE";
// export const ISSUE_TYPE_SUCCESS = "ISSUE_TYPE_SUCCESS";
// export const ISSUE_TYPE_FAILURE = "ISSUE_TYPE_FAILURE";

//QUESTION TYPE
export const AREA_ANSWERS = "AREA_ANSWERS";
export const DISTANCE_ANSWERS = "DISTANCE_ANSWERS";
export const FUEL_CONSUMPTION_ANSWERS = "FUEL_CONSUMPTION_ANSWERS";
export const FUEL_LEVEL_ANSWERS = "FUEL_LEVEL_ANSWERS";
export const JOB_TIME_ANSWERS = "JOB_TIME_ANSWERS";
export const LOCATION_ANSWERS = "LOCATION_ANSWERS";
export const MISSING_ALERTS_ANSWERS = "MISSING_ALERTS_ANSWERS";
export const OTHERS_ANSWERS = "OTHERS_ANSWERS";
export const RECHARGE_REQUEST_ANSWERS = "RECHARGE_REQUEST_ANSWERS";
export const TOO_MANY_ANSWERS = "TOO_MANY_ANSWERS";
export const TOTAL_TIME_ANSWERS = "TOTAL_TIME_ANSWERS";
export const TRIP_MISS_ANSWERS = "TRIP_MISS_ANSWERS";
export const RESET_QUESTIONS = "RESET_QUESTIONS";

//WELCOME CALL TYPE
export const WELCOME_CALL_REQUEST = "WELCOME_CALL_REQUEST";
export const WELCOME_CALL_SUCCESS = "WELCOME_CALL_SUCCESS";
export const WELCOME_CALL_FAILURE = "WELCOME_CALL_FAILURE";
export const RESET_WELCOME = "RESET_WELCOME";

//FEEDBACK TYPES

export const FEEDBACK_REQUEST = "FEEDBACK_REQUEST";
export const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS";
export const FEEDBACK_FAILURE = "FEEDBACK_FAILURE";
export const RESET_FEEDBACK = "RESET_FEEDBACK";

export const CAME_FROM_HOME = "CAME_FROM_HOME";

// FETCH SERVICES TYPES
export const FETCH_SERVICES_REQUEST = "FETCH_SERVICES_REQUEST";
export const FETCH_SERVICES_SUCCESS = "FETCH_SERVICES_SUCCESS";
export const FETCH_SERVICES_FAILURE = "FETCH_SERVICES_FAILURE";

// FORCE CLOSE TICKET
export const FORCE_CLOSE_REQUEST = "FORCE_CLOSE_REQUEST";
export const FORCE_CLOSE_SUCCESS = "FORCE_CLOSE_SUCCESS";
export const FORCE_CLOSE_FAILURE = "FORCE_CLOSE_FAILURE";

// UPLOAD EXCEL TYPES
export const UPLOAD_EXCEL_REQUEST = "UPLOAD_EXCEL_REQUEST";
export const UPLOAD_EXCEL_SUCCESS = "UPLOAD_EXCEL_SUCCESS";
export const UPLOAD_EXCEL_FAILURE = "UPLOAD_EXCEL_FAILURE";
export const RESET_EXCEL = "RESET_EXCEL";

// CREATE CAMPAIGN TYPES
export const CREATE_CAMPAIGN_REQUEST = "CREATE_CAMPAIGN_REQUEST";
export const CREATE_CAMPAIGN_FAILURE = "CREATE_CAMPAIGN_FAILURE";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const RESET_CREATE_CAMPAIGN = "RESET_CREATE_CAMPAIGN";

// FETCH CAMPAIGNS
export const FETCH_CAMPAIGN_REQUEST = "FETCH_CAMPAIGN_REQUEST";
export const FETCH_CAMPAIGN_SUCCESS = "FETCH_CAMPAIGN_SUCCESS";
export const FETCH_CAMPAIGN_FAILURE = "FETCH_CAMPAIGN_FAILURE";
export const UPDATE_CAMPAIGN_REQUEST = "UPDATE_CAMPAIGNS_REQUEST";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGNS";
export const SELECT_CAMPAIGN = "SELECT_CAMPAIGN";

//CALL RATING TYPES
export const CALL_RATING_REQUEST = "CALL_RATING_REQUEST";
export const CALL_RATING_SUCCESS = "CALL_RATING_SUCCESS";
export const CALL_RATING_FAILURE = "CALL_RATING_FAILURE";
export const CALL_RATING_RESET = "CALL_RATING_RESET";
// FETCH USERS
export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const UPDATE_USERS_REQUEST = "UPDATE_USERS_REQUEST";
export const UPDATE_USERS = "UPDATE_USERS";
export const SELECT_USER = "SELECT_USER";
export const SEARCH_USERS_REQUEST = "SEARCH_USERS_REQUEST";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAILURE = "SEARCH_USERS_FAILURE";

// FETCH FILTER CALLS TYPES
export const FETCH_FILTER_CALLS_REQUEST = "FETCH_FITLER_CALLS_REQUEST";
export const FETCH_FILTER_CALLS_SUCCESS = "FETCH_FITLER_CALLS_SUCCESS";
export const FETCH_FILTER_CALLS_FAILURE = "FETCH_FITLER_CALLS_FAILURE";
export const FETCH_FILTER_CALLS_SELECT_REQUEST =
  "FETCH_FILTER_CALLS_SELECT_REQUEST";
export const FETCH_FILTER_CALLS_SELECT_SUCCESS =
  "FETCH_FILTER_CALLS_SELECT_SUCCESS";
export const FETCH_FILTER_CALLS_SELECT_FAILURE =
  "FETCH_FILTER_CALLS_SELECT_FAILURE";

// FETCH SMS TEMPLATES
export const FETCH_SMS_TEMPLATE_REQUEST = "FETCH_SMS_TEMPLATE_REQUEST";
export const FETCH_SMS_TEMPLATE_SUCCESS = "FETCH_SMS_TEMPLATE_SUCCESS";
export const FETCH_SMS_TEMPLATE_FAILURE = "FETCH_SMS_TEMPLATE_FAILURE";

// SMS TRIGGER
export const SMS_TRIGGER_REQUEST = "SMS_TRIGGER_REQUEST";
export const SMS_TRIGGER_SUCCESS = "SMS_TRIGGER_SUCCESS";
export const SMS_TRIGGER_FAILURE = "SMS_TRIGGER_FAILURE";
