import * as types from "../types";

export const uploadExcelRequest = ({ file }) => {
  return {
    type: types.UPLOAD_EXCEL_REQUEST,
    payload: {
      file,
    },
  };
};

export const uploadExcelSuccess = ({ data, msg }) => {
  return {
    type: types.UPLOAD_EXCEL_SUCCESS,
    payload: {
      data,
      msg,
    },
  };
};

export const uploadExcelFailure = (error) => {
  return {
    type: types.UPLOAD_EXCEL_FAILURE,
    payload: {},
    error: error,
  };
};

export const resetExcel = () => {
  return {
    type: types.RESET_EXCEL,
  };
};
