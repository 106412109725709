export const getData = (data) => {
  return data ? data : "NA";
};

export const getDateTime = (t) => {
  if (!t) {
    return "NA";
  }
  let date = formatDate(t);
  let time = formatTimeInTwelve(t);
  return date + " " + time;
};

export const formatTimeInTwelve = (t) => {
  let date = new Date(t);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  hours = hours < 10 ? "0" + hours : hours;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const formatTime = (t) => {
  if (!t) {
    return "NA";
  }
  let time = new Date(t);
  let hour = time.getHours();
  let min = time.getMinutes();
  hour = hour < 10 ? "0" + hour : hour;
  min = min < 10 ? "0" + min : min;
  console.log(hour + ":" + min);
  return hour + ":" + min;
};

export const formatDate = (date, saperator = "-") => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join(saperator);
};

export const formatDateInYYMMDD = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const secondsToMH = (s) => {
  s = Number(s);
  var h = Math.floor(s / 3600);
  if (h >= 1) {
    return (s / 3600).toFixed(2) + " " + (h === 1 ? "Hour" : "Hours");
  } else {
    return (s / 60).toFixed(2) + " Minutes";
  }
};

export const generateOrderId = (phone) => {
  let orderId = "c";
  // orderId += phone[phone.length - 1];
  orderId += phone.slice(-3);
  let curDate = new Date();
  let prevDate = new Date("1 jan 2000");
  let secondsElapsed = parseInt((curDate - prevDate) / 1000);
  let base36 = parseInt(secondsElapsed + "", 10).toString(36);
  orderId += base36;
  return orderId;
};
