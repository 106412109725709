import * as types from "../../types";

const initialState = {
  loading: false,
  res: null,
  error: {},
};

const welcomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.WELCOME_CALL_REQUEST:
      return {
        ...state,
        loading: true,
        res: null,
      };
    case types.WELCOME_CALL_SUCCESS:
      return {
        ...state,
        res: action.payload,
        loading: false,
      };
    case types.WELCOME_CALL_FAILURE:
      return {
        ...state,
        loading: false,
        res: null,
        error: action.error,
      };
    case types.RESET_WELCOME:
      return {
        ...state,
        loading: false,
        res: null,
      };
    default:
      return state;
  }
};

export default welcomeReducer;
