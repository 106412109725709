import React, { useEffect } from "react";
import "./App.css";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
  withTheme,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dashboard from "./screens/Dashboard";
import Feedback from "./screens/Feedback";
import Welcome from "./screens/Welcome";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const theme = createMuiTheme({
  palette: {
    // type: "dark",
    primary: {
      main: "#652078",
    },
    secondary: {
      main: "#FFFFFF",
    },
    accent: {
      main: "#F37021",
    },
    light: {
      main: "#FFFFFF",
    },
    grey: {
      main: "#FAF3FF",
    },
    dark: {
      main: "#575756",
    },
    typography: {
      button: {
        textTransform: "none",
      },
      fontFamily: "'Noto Sans JP', sans-serif",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "2000px",
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    margin: "0 auto",
    background: theme.palette.grey.main,
    textAlign: "initial !important",
    "& h1,h2,h3,h4,h5,p": {
      margin: "0 ",
      padding: "0",
    },
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
    "& input[type=`number`]": {
      "-moz-appearance": "textfield",
    },
  },
  root: {
    width: "80%",
    minHeight: "200px",
    margin: "3em auto",
    height: "auto",
    background: "#FFFFFF",
    borderRadius: "20px 20px 0 0 ",
    padding: "2em",
    position: "relative",
  },
}));

const App = ({ ...props }) => {
  const classes = useStyles();
  useEffect(() => {
    if (props.errorStatus) {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: "Network Call Failed. Please Refresh Page",
        showConfirmButton: true,
        confirmButtonText: "Refresh",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((isConfirm) => {
        if (isConfirm) {
          window.location.reload();
        }
      });
    }
  }, [props.error, props.errorStatus]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.container}>
        <div className={classes.root}>
          <Route exact path="/cms" render={() => <Dashboard />} />
          <Route exact path="/cms/welcome" render={() => <Welcome />} />
          <Route exact path="/cms/feedback" render={() => <Feedback />} />
        </div>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  error: state.errorReducer.msg,
  errorStatus: state.errorReducer.status,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(App));
