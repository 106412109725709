import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const InputSelect = ({ field, form,size,width,onChangeUtil, ...other }) => {
  const currentError = form.errors[field.name];
  const touched = form.touched[field.name];
  return (
    <FormControl
      variant="outlined"
      error={touched && currentError ? true : false}
      style={{
        flex: 1,
        width: width ?? "100%",
      }}
      size={size ?? "medium"}
      disabled={other.disabled ? true : false}
    >
      <InputLabel htmlFor={other.id}>{other.label}</InputLabel>
      <Select
        value={field.value}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.value, false);
          if(onChangeUtil){
            onChangeUtil(e.target.value);
          }
        }}
        label={other.label}
        inputProps={{
          name: other.name,
          id: other.id,
        }}
      >
        {other.options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {touched && currentError && <FormHelperText>{currentError}</FormHelperText>}
    </FormControl>
  );
};

export default InputSelect;
