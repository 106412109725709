import * as types from "../../types";

export const fetchCampaignRequest = ({ phone }) => {
  return {
    type: types.FETCH_CAMPAIGN_REQUEST,
    payload: { phone },
  };
};

export const fetchCampaignSuccess = ({ campaigns }) => {
  return {
    type: types.FETCH_CAMPAIGN_SUCCESS,
    payload: { campaigns },
  };
};

export const fetchCampaignFailure = (error) => {
  return {
    type: types.FETCH_CAMPAIGN_FAILURE,
    payload: {},
    error: error,
  };
};

export const updateCampaignRequest = ({ phone }) => {
  return {
    type: types.UPDATE_CAMPAIGN_REQUEST,
    payload: { phone },
  };
};
