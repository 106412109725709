import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types";
import * as XLSX from "xlsx";

import {
  uploadExcelSuccess,
  uploadExcelFailure,
} from "../action/uploadExcelAction";
import { returnErrors, clearErrors } from "../action/errorAction";

const isValidJson = (data) => {
  let valid = true;
  let invalidRow = null;
  for (let index = 0; index < data.length; index++) {
    let row = data[index];
    if (!row["mobile"]) {
      valid = false;
      invalidRow = {
        valid: false,
        msg: `*Mobile number not found in row ${index + 2}`,
        row,
      };
      break;
    } else if (row["mobile"].toString().length !== 10) {
      valid = false;
      invalidRow = {
        valid: false,
        msg: `*Mobile number has to be of 10 digits in row ${index + 2}`,
        row,
      };
      break;
    } else if (
      isNaN(row["mobile"]) ||
      row["mobile"] < 1111111111 ||
      row["mobile"] > 9999999999
    ) {
      valid = false;
      invalidRow = {
        valid: false,
        msg: `*Mobile number not Valid in row ${index + 2}`,
        row,
      };
      break;
    } else if (!row["name"]) {
      valid = false;
      invalidRow = {
        valid: false,
        msg: `*Name is not found in row ${index + 2}`,
        row,
      };
      break;
    }
  }
  if (valid) {
    return { valid: true, msg: "*Excel is Valid!" };
  } else {
    return invalidRow;
  }
};

const getJsonOutOfExcel = ({ file }) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem Parsing the File."));
    };
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      let farmer_json = [];

      let workbook = XLSX.read(bstr, {
        type: "binary",
      });
      workbook.SheetNames.forEach(function (sheetName) {
        let XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName]
        );
        let json_object = XL_row_object;
        farmer_json = [...farmer_json, ...json_object];
      });
      resolve(farmer_json);
    };
    reader.readAsBinaryString(file);
  });
};

const deformData = (data) => {
  let length = data.length;
  for (let index = 0; index < length; index++) {
    for (let key of Object.keys(data[index])) {
      if (key !== key.toLowerCase()) {
        data[index][key.toLowerCase()] = data[index][key];
        delete data[index][key];
      }
    }
  }
  return data;
};

function* asyncUploadExcelSaga(action) {
  try {
    yield put(clearErrors());
    let { file } = action.payload;

    let farmerData = yield call(getJsonOutOfExcel, { file: file });
    console.log(farmerData);
    farmerData = deformData(farmerData);
    let validRes = isValidJson(farmerData);
    console.log(validRes);
    let revampedData = [];
    for (let index = 0; index < farmerData.length; index++) {
      let { name, mobile, ...metaData } = farmerData[index];
      revampedData.push({ name, phone: mobile, meta_info: metaData });
    }
    if (validRes.valid) {
      yield put(
        uploadExcelSuccess({
          data: revampedData,
          msg: "Validated Farmers's Excel",
          isValid: true,
        })
      );
    } else {
      yield put(returnErrors({ msg: validRes.msg, status: true }));
      yield put(uploadExcelFailure({ msg: validRes.msg }));
    }
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(uploadExcelFailure(e));
  }
}
export default function* uploadExcelSaga() {
  yield takeLatest(types.UPLOAD_EXCEL_REQUEST, asyncUploadExcelSaga);
}
