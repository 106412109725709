import axios from "axios";
import { API_BASE_URL, API_ROUTES } from "../config";

function getConfigData(token) {
  return {
    ApiKey: `41k59h2PEWniwk3QD0IjnYFYC7X92qPz`,
    Authorization: `Token ${token}`,
  };
}

export const fetchData = async ({ phone }) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.CHECK_SERVICABILITY}`,
      phone,
      {
        headers: getConfigData(localStorage.getItem("token")),
      }
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchOrderData = async ({ orderId }) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.FETCH_ORDERS}${orderId}/`,
      {
        headers: getConfigData(localStorage.getItem("token")),
      }
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const cancelOrder = async ({ orderId }) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.CANCEL_ORDERS}`,
      orderId,
      {
        headers: getConfigData(localStorage.getItem("token")),
      }
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const createOrder = async ({ body }) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.CREATE_NEW_ORDER}`,
      body,
      {
        headers: getConfigData(localStorage.getItem("token")),
      }
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchServices = async () => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.FETCH_SERVICES}`,
      { headers: getConfigData(localStorage.getItem("token")) }
    );
    return data;
  } catch (e) {
    throw e;
  }
};

export const createCampaign = async ({ body }) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.CREATE_CAMPAIGN}`,
      body
    );
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchCampaigns = async ({ phone }) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.GET_CAMPAIGNS_FOR_GIVEN_PHONE}?phone=${phone}`
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const submitCallRating = async ({ body }) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.RECORD_CALL_HEALTH}`,
      body
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchFilterCalls = async ({ searchKey, searchValue }) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.FETCH_FILTER_CALLS}?${searchKey}=${searchValue}`,
      {
        headers: getConfigData(localStorage.getItem("token")),
      }
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchFilterCallsSelected = async ({ ticket_id, category }) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.FETCH_FILTER_CALLS_SELECTED}`,
      { ticket_id, category },
      { headers: getConfigData(localStorage.getItem("token")) }
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchSmsTemplates = async () => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.FETCH_SMS_TEMPLATES}`,
      {
        headers: getConfigData(localStorage.getItem("token")),
      }
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const triggerSms = async ({ body }) => {
  try {
    const { data } = await axios.post(
      `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.TRIGGER_SMS}`,
      body,
      {
        headers: getConfigData(localStorage.getItem("token")),
      }
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
