import * as types from "../../types";

const initialState = {
  areaAnswers: null,
  distanceAnswers: null,
  fuelConsumptionAnswers: null,
  fuelLevelAnswers: null,
  jobTimeAnswers: null,
  locationAnswers: null,
  missingAlertsAnswers: null,
  othersAnswers: null,
  rechargeRequestAnswers: null,
  tooManyAlertsAnswers: null,
  totalTimeAnswers: null,
  tripMissAnswers: null,
};

const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AREA_ANSWERS:
      return {
        ...state,
        areaAnswers: action.payload.areaQna,
      };
    case types.DISTANCE_ANSWERS:
      return {
        ...state,
        distanceAnswers: action.payload.distanceQna,
      };
    case types.FUEL_CONSUMPTION_ANSWERS:
      return {
        ...state,
        fuelConsumptionAnswers: action.payload.fuelConsumedQna,
      };
    case types.FUEL_LEVEL_ANSWERS:
      return {
        ...state,
        fuelLevelAnswers: action.payload.fuelLevelQna,
      };
    case types.JOB_TIME_ANSWERS:
      return {
        ...state,
        jobTimeAnswers: action.payload.jobTimeQna,
      };
    case types.LOCATION_ANSWERS:
      return {
        ...state,
        locationAnswers: action.payload.locationQna,
      };
    case types.MISSING_ALERTS_ANSWERS:
      return {
        ...state,
        missingAlertsAnswers: action.payload.missingAlertsQna,
      };
    case types.OTHERS_ANSWERS:
      return {
        ...state,
        othersAnswers: action.payload.othersQna,
      };
    case types.RECHARGE_REQUEST_ANSWERS:
      return {
        ...state,
        rechargeRequestAnswers: action.payload.rechargeRequestQna,
      };
    case types.TOO_MANY_ANSWERS:
      return {
        ...state,
        tooManyAlertsAnswers: action.payload.tooManyAlertsQna,
      };
    case types.TOTAL_TIME_ANSWERS:
      return {
        ...state,
        totalTimeAnswers: action.payload.totalTimeQna,
      };
    case types.TRIP_MISS_ANSWERS:
      return {
        ...state,
        tripMissAnswers: action.payload.tripMissQna,
      };
    case types.RESET_QUESTIONS:
      return {
        ...state,
        areaAnswers: null,
        distanceAnswers: null,
        fuelConsumptionAnswers: null,
        fuelLevelAnswers: null,
        jobTimeAnswers: null,
        locationAnswers: null,
        missingAlertsAnswers: null,
        othersAnswers: null,
        rechargeRequestAnswers: null,
        tooManyAlertsAnswers: null,
        totalTimeAnswers: null,
        tripMissAnswers: null,
      };
    default:
      return state;
  }
};

export default questionsReducer;
