import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { getDateTime, secondsToMH } from "../utils";
import { Button } from "../elements";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

import Clock from "../assets/rental_clock.svg";
import Calander from "../assets/rental_calander.svg";
import machine from "../../src/assets/rental_machine.svg";
import person from "../assets/rental_person.svg";
import wheel from "../assets/rental_wheel.svg";
import group from "../assets/rental_group.svg";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    background: theme.palette.light.main,
    outline: "none",
    border: "none",
    width: "70%",
    // maxWidth: "800px",
    minHeight: "400px",
    height: "auto",
    padding: "2em",
    borderRadius: ".4em",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  modalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2em",
    paddingBottom: ".5em",
    "& > h2": {
      fontSize: "1em",
      color: theme.palette.dark.main,
    },
    "& > span": {
      cursor: "pointer",
      color: "red",
    },
    borderBottom: `2px solid ${theme.palette.grey.main}`,
  },
  order: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  },
  orderData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "1em",
  },
  data: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "1em",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  dataLogo: {
    display: "grid",
    placeContent: "center",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    background: theme.palette.grey.main,
    "& > img": {
      width: "40px",
      height: "auto",
    },
  },
  thankMessage: {
    textAlign: "center",
    color: theme.palette.dark.main,
    fontSize: "1em",
    width: "60%",
    margin: "2em auto",
  },
  btnWrapper: {
    display: "grid",
    placeContent: "center",
  },
  commentSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "2em 0",
    "& > * + *": {
      marginLeft: "1em",
    },
    "& > h4": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontSize: "1.2em",
    },
    "& > p": {
      fontSize: "1em",
    },
  },
}));

const OrderDesc = ({
  data,
  setModalIsOpen,
  modalIsOpen,
  name,
  setViewData,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  return (
    <Modal open={modalIsOpen}>
      <div className={classes.modalStyle}>
        <div className={classes.modalTop}>
          <h2>
            {/* ORDER ID: {data?.crm_order_id} ({data?.status}
            {data?.order_id && `, Ref ID: ${data?.order_id}`}) */}
            {data ? (
              <>
                {data?.order_id
                  ? `Ref. ID: ${data?.order_id} ${
                      data.status && `(${data?.status})`
                    }`
                  : `ORDER ID: ${data?.crm_order_id} ${
                      data.status && `(${data.status})`
                    }`}
                {data?.service_details?.otp &&
                  ` - OTP: ${data?.service_details?.otp}`}
              </>
            ) : (
              <Skeleton variant="rect" width={150} height={20} />
            )}
          </h2>
          <span
            onClick={() => {
              setViewData(null);
              setModalIsOpen((prev) => !prev);
            }}
          >
            Close X
          </span>
        </div>
        <div className={classes.order}>
          <div className={classes.orderData}>
            <div className={classes.data}>
              <div className={classes.dataLogo}>
                <img src={machine} alt="machine" />
              </div>
              <div className={classes.info}>
                <p>Machine</p>
                {data ? (
                  <b>{data.service_details.category}</b>
                ) : (
                  <Skeleton variant="rect" width={150} height={20} />
                )}
              </div>
            </div>
            <div className={classes.data}>
              <div className={classes.dataLogo}>
                <img src={Calander} alt="calander" />
              </div>
              <div className={classes.info}>
                <p>Date & Time</p>
                {data ? (
                  <b>{getDateTime(data.service_details.job_start_time)}</b>
                ) : (
                  <Skeleton variant="rect" width={150} height={20} />
                )}
              </div>
            </div>
            <div className={classes.data}>
              <div className={classes.dataLogo}>
                <img src={Clock} alt="clock" />
              </div>
              <div className={classes.info}>
                <p>Duration</p>
                {data ? (
                  <b>{secondsToMH(data.service_details.duration)}</b>
                ) : (
                  <Skeleton variant="rect" width={150} height={20} />
                )}
              </div>
            </div>
          </div>
          <div className={classes.orderData}>
            <div className={classes.data}>
              <div className={classes.dataLogo}>
                <img src={person} className={classes.image} alt="owner" />
              </div>
              <div className={classes.info}>
                <p>Owner</p>
                {data ? (
                  <b>{name}</b>
                ) : (
                  <Skeleton variant="rect" width={150} height={20} />
                )}
              </div>
            </div>
            <div className={classes.data}>
              <div className={classes.dataLogo}>
                <img src={wheel} className={classes.image} alt="area" />
              </div>
              <div className={classes.info}>
                <p style={{ margin: 0, width: "61px", whiteSpace: "nowrap" }}>
                  Area
                </p>
                {data ? (
                  <b>
                    {data?.service_details?.area !== null &&
                      data?.service_details?.area.toFixed(2)}{" "}
                    acre{" "}
                  </b>
                ) : (
                  <Skeleton variant="rect" width={150} height={20} />
                )}
              </div>
            </div>
            <div className={classes.data}>
              <div className={classes.dataLogo}>
                <img src={group} className={classes.image} alt="charger" />
              </div>
              <div className={classes.info}>
                <p>
                  {data?.service_details?.price === 0
                    ? "Discuss with RE"
                    : "Charges"}
                </p>
                {data ? (
                  <b>
                    {data?.service_details?.price === 0
                      ? ""
                      : "Rs." + data?.service_details?.total_price.toFixed(2)}
                  </b>
                ) : (
                  <Skeleton variant="rect" width={150} height={20} />
                )}
              </div>
            </div>
          </div>
        </div>
        {data && data?.service_details?.comments !== "" && (
          <div className={classes.commentSection}>
            <h4>Comment :</h4> <p>{data?.service_details?.comments}</p>
          </div>
        )}
           {data?.status !== "cancelled" && (
          <>
        <p className={classes.thankMessage}>
          Thank You for booking your order with us. We are searching our
          inventory to find best tractor and implement for you. We will get back
          to you shortly.
        </p>
        <div className={classes.btnWrapper}>
          <Button
            variant="outlined"
            color="secondary"
            style={{
              margin: "auto",
              width: "300px",
              // boxShadow: "none",
              // background: theme.palette.accent.main,
              color: theme.palette.accent.main,
              border: `1px solid ${theme.palette.accent.main}`,
              lineHeight: "55px",
            }}
            onClick={() => {
              history.push({
                pathname: "/cancelOrder",
                state: { orderId: data.crm_order_id },
              });
            }}
          >
            Cancel Order
          </Button>
        </div>
         </>
        )}
      </div>
    </Modal>
  );
};

export default OrderDesc;
