import React, { useState, useEffect } from "react";
import userRatingStyles from "./userrating.styles";
// import ReactStars from "react-rating-stars-component";

import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Modal from "@material-ui/core/Modal";

import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import krishiLogo from "../../assets/Krish-logo.svg";

import { useLocation } from "react-router-dom";
import qs from "query-string";

import { Button } from "../../elements";
import EmojiRating from "../../elements/EmojiRating";

import { connect } from "react-redux";
import {
  callRatingRequest,
  resetCallRating,
} from "../../store/action/callRatingAction";

import { useHistory } from "react-router-dom";

const UserRating = ({ username, response, ...props }) => {
  const classes = userRatingStyles();
  const location = useLocation();
  const params = qs.parse(location.search);
  let phoneNumber = sessionStorage.getItem("number");
  const history = useHistory();
  const [rank, setRank] = useState({ label: "Sad", rank: 1 });

  useEffect(() => {
    if (response) {
      props.resetCallHealth();
      history.replace("/nav");
    }
  }, [response]);

  const handleRankChange = (label, rank) => {
    setRank({ label, rank });
  };

  const handleSubmit = () => {
    let body = {
      call_type: params.call_type,
      phone: phoneNumber,
      health: rank.label,
    };
    console.log(body);
    props.submitCallHealth({ body });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ backgroundColor: "#652078" }}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: false,
        })}
      >
        <Toolbar>
          <div style={{ margin: "auto 56px" }}></div>
          <img
            alt=""
            src={krishiLogo}
            width="140px"
            height="50px"
            style={{
              alignItems: "center",
              margin: "auto",
            }}
          />

          <Typography
            style={{
              margin: "auto 20px",
              fontSize: "24px",

              fontFamily: "Lucida Grande, sans-serif",
            }}
          >
            Hello {username}
          </Typography>
          <PowerSettingsNewIcon
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
            style={{ float: "right", cursor: "pointer" }}
          />
        </Toolbar>
      </AppBar>
      <Modal
        open={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalContainer}>
          <h2 className={classes.modalTitle}>
            Call Health ({params.call_type})
          </h2>

          {/* <ReactStars
            count={5}
            size={64}
            onChange={(rating) => alert(rating)}
            activeColor="#ffd700"
          /> */}
          {/* <Rating
            name="customized-icons"
            defaultValue={2}
            max={3}
            getLabelText={(value) => customIcons[value].label}
            IconContainerComponent={IconContainer}
            size="large"
          /> */}
          <EmojiRating onChange={handleRankChange} />
          {params.call_type && <p>*Rating for the {params.call_type} tile.</p>}
          <Button
            type="button"
            variant="contained"
            className={classes.submitBtn}
            onClick={handleSubmit}
          >
            {props.loading ? "Submitting..." : "Submit"}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.callRating.loading,
  response: state.callRating.res,
});

const mapDispatchToProps = (dispatch) => ({
  submitCallHealth: ({ body }) => {
    dispatch(callRatingRequest({ body }));
  },
  resetCallHealth: () => {
    dispatch(resetCallRating());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRating);
