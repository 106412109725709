import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../types";
import {
  welcomeCallSuccess,
  welcomeCallFailure,
} from "../../action/cmsActions/welcomeAction";
import { returnErrors, clearErrors } from "../../action/errorAction";
import * as _api from "../../../cmsApi";

function* asyncWelcomeSaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(_api.welcomeFormSubmission, {
      requestData: action.payload,
    });
    yield put(welcomeCallSuccess({ res }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(welcomeCallFailure(e));
  }
}

export default function* welcomeSaga() {
  yield takeLatest(types.WELCOME_CALL_REQUEST, asyncWelcomeSaga);
}
