import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withTheme,
  withStyles,
  useTheme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Skeleton from "@material-ui/lab/Skeleton";

import { connect } from "react-redux";
import { fetchCampaignRequest } from "../../store/action/outboundActions/fetchCampaignAction";
import { selectCampaignRequest } from "../../store/action/outboundActions/selectCampaignAction";
import { getData } from "../utils";
import { TableFooter } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useHistory, useLocation } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import queryString from "query-string";

const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  tableOptions: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "2em",
    borderBottom: `2px solid ${theme.palette.grey.main}`,
    "& > h2": {
      fontSize: "2.1em",
      color: theme.palette.dark.main,
    },

    // [theme.breakpoints.down("md")]: {
    //   width: "100%",
    //   flexDirection: "column",
    //   alignItems: "center",
    //   justifyContent: "center",
    // },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: theme.palette.grey.main,
    color: "#706F6F",
  },
  body: {
    fontSize: 14,
    color: theme.palette.dark.main,
    fontWeight: "bold",
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
    cursor: "pointer",
    "&:hover": {
      background: "rgba(0,0,0,0.1)",
    },
  },
}))(TableRow);

const renderLoading = (row, col) => {
  let range = [...Array(row).keys()];
  let range2 = [...Array(col).keys()];
  return range.map((i) => (
    <StyledTableRow key={i}>
      {range2.map((j) => (
        <StyledTableCell
          key={j + 10}
          style={{
            height: 60,
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Skeleton
            animation="wave"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </StyledTableCell>
      ))}
    </StyledTableRow>
  ));
};

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const CampaignTable = ({ theme, ...props }) => {
  const classes = useStyles();
  let phoneNumber = sessionStorage.getItem("number");
  const history = useHistory();
  const location = useLocation();

  const parsed = queryString.parse(location.search);

  useEffect(() => {
    let user = localStorage.getItem("username");
    sessionStorage.removeItem("state");
    if (parsed.number) {
      sessionStorage.setItem("number", parsed.number);
    }
    if (user !== null) {
      if (!props.campaigns) {
        props.campaignRequest({ phone: phoneNumber || parsed.number });
      }
    } else {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Not authorized",
        showConfirmButton: true,
        confirmButtonText: "Log In",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((isConfirm) => {
        if (isConfirm) {
          window.location.assign(`http://localhost:3000/`);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let priorityMap = { Active: 3, Upcoming: "2", Archived: 1 };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  let sortedData = props.campaigns
    ? props.campaigns.sort((a, b) => {
        return priorityMap[a.status] > priorityMap[b.status] ? -1 : 1;
      })
    : null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderMetaInfoTableHead = (rest) => {
    return (
      <TableHead>
        <TableRow>
          {rest.campaigns[0].meta_info &&
            Object.keys(rest.campaigns[0].meta_info).map((item, index) => (
              <TableCell align="left" key={index} style={{ fontWeight: "600" }}>
                {item}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  const renderMetaInfoTableBody = (rest) => {
    if (rest.campaigns.length > 0 && rest.campaigns[0]?.meta_info) {
      return (
        <TableRow>
          {Object.values(rest.campaigns[0].meta_info).map((item, index) => (
            <TableCell align="left" key={index}>
              {item}
            </TableCell>
          ))}
        </TableRow>
      );
    } else {
      return (
        <p style={{ padding: "1em 0", marginBottom: "2em" }}>
          No Meta info available for this user.
        </p>
      );
    }
  };

  const getRowsPerPage = () => {
    if (rowsPerPage > 0) {
      return sortedData.slice(
        page.rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    } else {
      return sortedData;
    }
  };

  return (
    <>
      <div className={classes.tableOptions}>
        <h2>
          ASSIGNED CAMPAIGNS{" "}
          {props.campaigns &&
            props.campaigns.length > 0 &&
            props.campaigns[0].name !== "Unknown" &&
            `FOR ${props.campaigns[0]?.name.toUpperCase()}`}
        </h2>
      </div>
      <div style={{ marginTop: "2em" }}>
        <h4>META INFO:</h4>
        <TableContainer
          style={{
            marginTop: "1em",
          }}
        >
          <Table>
            {props.campaigns &&
              props.campaigns.length > 0 &&
              renderMetaInfoTableHead(props)}
            <TableBody>
              {props.campaigns
                ? renderMetaInfoTableBody(props)
                : renderLoading(2, 3)}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ marginTop: "2em" }}>
        <h3>CAMPAIGNS:</h3>
        <TableContainer style={{ marginTop: "1em" }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Campaign Code</StyledTableCell>
                <StyledTableCell align="left">Campaign Name</StyledTableCell>
                <StyledTableCell align="left">Start Date</StyledTableCell>
                <StyledTableCell align="left">End Date</StyledTableCell>
                <StyledTableCell align="left">Generation Date</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!props.loading && props.campaigns
                ? getRowsPerPage().map((row) => (
                    <StyledTableRow
                      key={row.campaign_code}
                      onClick={() => {
                        props.selectCampaign({ campaign: row });
                        history.push({
                          pathname: "/outbound/form",
                          state: { campaign: row },
                        });
                      }}
                    >
                      <StyledTableCell align="left">
                        {getData(row.campaign_code)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {getData(row.campaign_name)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.start_date).toLocaleDateString("en-US")}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.end_date).toLocaleDateString("en-US")}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.generation_time).toLocaleDateString(
                          "en-US"
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : renderLoading(8, 5)}
            </TableBody>
            {props.campaigns && (
              <TableFooter
                style={{
                  background: theme.palette.grey.main,
                }}
              >
                <StyledTableRow>
                  <TablePagination
                    style={{ borderBottom: "none" }}
                    rowsPerPageOptions={[
                      15,
                      25,
                      50,
                      { label: "All", value: -1 },
                    ]}
                    count={props.campaigns.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </StyledTableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </div>
      {/* <CampaignForm
        showModal={showModal}
        handleModalOpen={handleModalOpen}
        // campaign={atob(localStorage.getItem("campaignId"))}
      /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.campaignReducer.loading,
  campaigns: state.campaignReducer.campaigns,
});

const mapDispatchToProps = (dispatch) => ({
  campaignRequest: ({ phone }) => {
    dispatch(fetchCampaignRequest({ phone }));
  },
  selectCampaign: ({ campaign }) => {
    dispatch(selectCampaignRequest({ campaign }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(CampaignTable));
