import { makeStyles } from "@material-ui/core/styles";

const emojiRatingStyles = makeStyles((theme) => ({
  ratingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2em 0",
    "& > * + *": {
      marginLeft: "2em",
    },
    "& > img": {
      width: "70px",
      height: "70px",
      cursor: "pointer",
      transition: "0.3s",
      //   filter: "grayscale(0.8)",
      "&:hover": {
        transform: "scale(1.1)",
        filter: "grayscale(0)",
      },
      "&:active": {
        transform: "scale(0.9)",
      },
    },
  },
  active: {
    filter: "grayscale(0)",
  },
  inactive: {
    filter: "grayscale(0.9)",
  },
}));

export default emojiRatingStyles;
