import * as types from "../../types";

const initialState = {
  loading: false,
  campaigns: null,
  allCampaigns: null,
  searchTerm: null,
  // spoc: null,
  phone: null,
  selectedCampaign: null,
  error: {},
};

const fetchCampaignReducerOutbound = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CAMPAIGN_REQUEST:
      return {
        ...state,
        loading: true,
        // spoc: action.payload.spoc,
        phone: action.payload.phone,
        campaigns: null,
        allCampaigns: null,
        error: {},
      };
    case types.FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: action.payload.campaigns,
        allCampaigns: action.payload.campaigns,
        selectedCampaign: action.payload.campaigns[0],
        error: {},
      };
    case types.FETCH_CAMPAIGN_FAILURE:
      return {
        ...state,
        loading: false,
        campaigns: null,
        allCampaigns: null,
        error: action.error,
      };
    case types.UPDATE_CAMPAIGN_REQUEST:
      return {
        ...state,
        // spoc: action.payload.spoc,
        phone: action.payload.phone,
      };
    // case types.SEARCH_CAMPAIGN_REQUEST:
    //   return {
    //     ...state,
    //     searchTerm: action.payload.searchTerm,
    //   };
    // case types.SEARCH_CAMPAIGN_SUCCESS:
    //   return {
    //     ...state,
    //     campaigns: action.payload.campaigns,
    //   };
    // case types.SEARCH_CAMPAIGN_FAILURE:
    //   return {
    //     ...state,
    //     error: action.error,
    //   };
    case types.SELECT_CAMPAIGN:
      return {
        ...state,
        selectedCampaign: action.payload.campaign,
      };
    default:
      return state;
  }
};

export default fetchCampaignReducerOutbound;
