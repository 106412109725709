import React, { useState, useEffect } from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  InputField,
  InputAutocomplete,
  InputSelect,
  Button,
} from "../elements";
import json from "../data/loaction.json";
import Crop_arr from "../data/crop.json";
import { Formik, Form, Field } from "formik";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    background: theme.palette.light.main,
    outline: "none",
    border: "none",
    width: "60%",
    maxWidth: "800px",
    minHeight: "400px",
    height: "auto",
    padding: "2em",
    borderRadius: ".4em",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  modalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2em",
    "& > h2": {
      fontSize: "1.5em",
      color: theme.palette.dark.main,
    },
    "& > span": {
      cursor: "pointer",
      color: "red",
    },
  },
  formRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "1.5em",
    margin: "1.5em 0",
  },
  btnWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  submitBtn: {
    width: "50%",
  },
}));

const AddFarm = ({
  dataList,
  setAddedFarm,
  isModalOpen,
  setModalIsOpen,
  setFarmLand,
  handleChange,
  theme,
  ...props
}) => {
  const classes = useStyles();
  const [villageData, setVillageData] = useState([]);
  const [village, setVillage] = useState("");
  const [name, setName] = useState("");

  const [districtData, setDistrictData] = useState([]);
  const [talukaData, setTalukaData] = useState([]);
  const [stateData, setStateData] = useState([]);

  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [taluka, setTaluka] = useState("");
  const [data, setData] = useState({});
  const autoData = JSON.parse(sessionStorage.getItem("formData"));

  const cropArr = Crop_arr.crop_arr;

  useEffect(() => {
    setState(autoData.state);
    let res = Object.keys(json);
    res = res.map((el) => {
      return {
        label: el,
        value: el,
      };
    });
    setStateData(res);
    setDistrict(autoData.district);
    setVillage(autoData.village);
    setTaluka(autoData.taluka);
    setName(autoData.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (taluka !== "") {
      getVillageData();
      getDistrictData();
      getTalukaData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taluka, district, name]);

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      setFarmLand(data);
      console.log(data);
      setModalIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getVillageData = () => {
    setVillageData(json[state][district][taluka]);
  };
  function getTalukaData() {
    let res = Object.keys(json[state][district]);
    res = res.map((el) => {
      return {
        label: el,
        value: el,
      };
    });
    setTalukaData(res);
  }
  function getDistrictData() {
    let res = Object.keys(json[state]);
    res = res.map((el) => {
      return {
        label: el,
        value: el,
      };
    });
    setDistrictData(res);
  }

  return (
    <Modal open={isModalOpen}>
      <div className={classes.modalStyle}>
        <div className={classes.modalTop}>
          <h2>ADD NEW FARM</h2>
          <span
            onClick={() => {
              setModalIsOpen(!isModalOpen);
            }}
          >
            Close X
          </span>
        </div>
        <div className={classes.inputGroup}>
          <Formik
            initialValues={{
              farm_name: "",
              farmerName: name,
              state: state,
              district: district,
              taluka: taluka,
              address: "",
              area: "",
              crops: [],
              unit: "Acre",
              village: village,
              nearest_village: "",
              distance_from_nearest_village: "",
            }}
            validate={(values) => {
              const errors = {};
              if (values.farm_name === "") {
                errors.farm_name = "*Required Field!!";
              }
              if (values.nearest_village === "") {
                errors.nearest_village = "*Required Field!!";
              }
              if (values.area === "") {
                errors.area = "*Required Field!!";
              } else {
                let area = parseInt(values.area);
                if (values.unit === "Acre" && area > 50) {
                  errors.area = "Area should be less then 50 Acre";
                } else if (values.unit === "Hectare" && area > 20) {
                  errors.area = "Area should be less then 20 Hactare";
                } else if (values.unit === "Guntha" && area > 2000) {
                  errors.area = "Area should be less then 20 Guntha";
                }
              }
              if (values.distance_from_nearest_village === "") {
                errors.distance_from_nearest_village = "*Required Field!!";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values);
              if (values.unit === "Hectare") {
                values.area = values.area / 0.4046;
              } else if (values.unit === "Guntha") {
                values.area = values.area / 40;
              }
              if (parseFloat(values.distance_from_nearest_village) > 25) {
                values.distance_from_nearest_village = 25;
              }
              setAddedFarm(values.farm_name);
              setData({
                farm_name: values.farm_name,
                state: values.state,
                district: values.district,
                taluka: values.taluka,
                village: values.village,
                address: values.address,
                area: parseFloat(values.area),
                crops: values.crops,
                nearest_village: values.nearest_village,
                distance_from_nearest_village: parseFloat(
                  values.distance_from_nearest_village
                ),
              });
            }}
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => (
              <Form>
                <div className={classes.formRow}>
                  <Field
                    type="text"
                    name="farm_name"
                    id="add_farm_name"
                    label="Farm Name*"
                    component={InputField}
                  />
                  <Field
                    type="text"
                    name="address"
                    id="add_farm_address"
                    label="Address/Landmark"
                    component={InputField}
                  />
                </div>
                <div className={classes.formRow}>
                  <Field
                    type="text"
                    name="state"
                    id="add_farm_state"
                    label="State"
                    disabled
                    options={stateData.sort()}
                    component={InputSelect}
                  />
                  <Field
                    type="text"
                    name="district"
                    id="add_farm_district"
                    label="District"
                    disabled
                    options={districtData.sort()}
                    component={InputSelect}
                  />
                  <Field
                    type="text"
                    name="taluka"
                    id="add_farm_taluka"
                    label="Taluka"
                    disabled
                    options={talukaData.sort()}
                    component={InputSelect}
                  />
                </div>
                <div className={classes.formRow}>
                  <Field
                    name="nearest_village"
                    id="add_farm_village"
                    data={villageData ? villageData.sort() : []}
                    label="Nearest village"
                    component={InputAutocomplete}
                  />
                  <Field
                    type="number"
                    name="distance_from_nearest_village"
                    id="add_farm_earest_village"
                    label="Distance from nearest village(km)"
                    component={InputField}
                  />
                </div>
                <h3
                  style={{
                    fontSize: "1em",
                    color: theme.palette.dark.main,
                  }}
                >
                  Farm Size
                </h3>
                <div className={classes.formRow}>
                  <Field
                    type="number"
                    name="area"
                    id="add_farm_area"
                    label="Farm Area*"
                    component={InputField}
                  />
                  <Field
                    name="unit"
                    id="add_farm_unit"
                    label="Unit"
                    options={[
                      { label: "Acre", value: "Acre" },
                      { label: "Hectare", value: "Hectare" },
                      { label: "Guntha", value: "Guntha" },
                    ]}
                    component={InputSelect}
                  />
                </div>
                <div className={classes.formRow}>
                  <Field
                    withChips
                    limit={5}
                    name="crops"
                    id="add_farm_crops"
                    data={cropArr ? [...new Set(cropArr)].sort() : []}
                    label="Crops"
                    placeholder="crop"
                    component={InputAutocomplete}
                  />
                </div>
                <div className={classes.btnWrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.submitBtn}
                    style={{
                      margin: "auto",
                      boxShadow: "none",
                      background: theme.palette.accent.main,
                      color: theme.palette.light.main,
                      lineHeight: "55px",
                    }}
                  >
                    Save Farm
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default withTheme(AddFarm);
