import * as types from "../../types";
export const welcomeCallRequest = ({ data }) => {
  return {
    type: types.WELCOME_CALL_REQUEST,
    payload: data,
  };
};

export const welcomeCallSuccess = ({ res }) => {
  return {
    type: types.WELCOME_CALL_SUCCESS,
    payload: res,
  };
};

export const welcomeCallFailure = (e) => {
  return {
    type: types.WELCOME_CALL_FAILURE,
    payload: {},
    error: e,
  };
};

export const resetWelcome = () => {
  return {
    type: types.RESET_WELCOME,
  };
};
