import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, fade } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { useHistory } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import krishiLogo from "../assets/Krish-logo.svg";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import { CircularProgress } from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Check from "@material-ui/icons/Check";
import Search from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import { forwardRef } from "react";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Clear from "@material-ui/icons/Clear";
import { API_ROUTES, API_BASE_URL } from "../config";
const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <div></div>),
  Edit: forwardRef((props, ref) => (
    <Edit {...props} ref={ref} color="primary" />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} color="secondary" />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <GetAppIcon {...props} ref={ref} />),
};

const drawerWidth = 60;

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  toolbar: theme.mixins.toolbar,
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    padding: "0px",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(10) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(10) + 1,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  spacing: {
    marginTop: "80px",
    width: "auto",
  },
  cardRow: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "row",
    marginBottom: "20px",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  textUndecorate: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.54)",
  },
}));

export default function Table({ setLoggedIn, username, tableUrl }) {
  const classes = useStyles();
  const history = useHistory();
  const open = false;

  const [state, setState] = React.useState();
  const [isLoaded, setIsLoaded] = React.useState(false);
  useEffect(() => {
    let body = {
      //  phone:number,
      //  sys_user:sys_user
    };
    axios
      .post(
        `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.JOTFORM_LIST}${tableUrl}/`,
        body
      )
      .then((val) => {
        console.log(val.data);
        let data1 = val.data;

        const arr = data1.data.map((item, idx) => {
          return {
            s_no: idx + 1,
            phone: item.phone,
            time:
              item.created_at.split(" ")[1].split(":")[0] +
              ":" +
              item.created_at.split(" ")[1].split(":")[1],
            date: item.created_at.split(" ")[0].split("-").reverse().join("-"),
          };
        });

        setState({
          columns: [
            {
              title: "#",
              field: "s_no",
              type: "numeric",
              cellStyle: (rowData) => ({
                fontSize: "16px",
                lineHeight: "19px",
                fontFamily: "Lucida Grande, sans-serif",
              }),
            },

            {
              title: "Phone Number",
              field: "phone",

              cellStyle: (rowData) => ({
                color: "#2F80ED",
                fontSize: "16px",
                lineHeight: "19px",
                fontFamily: "Lucida Grande, sans-serif",
              }),
            },
            {
              title: "Date",
              field: "date",

              cellStyle: (rowData) => ({
                fontSize: "16px",
                lineHeight: "19px",
                fontFamily: "Lucida Grande, sans-serif",
              }),
            },

            {
              title: "Start time",
              field: "time",
              cellStyle: (rowData) => ({
                fontSize: "16px",
                lineHeight: "19px",
                fontFamily: "Lucida Grande, sans-serif",
              }),
            },
          ],
          data: arr,
        });
        setIsLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoaded) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        style={{ backgroundColor: "#652078" }}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <div style={{ zIndex: "3" }}>
            <ArrowBackIcon
              onClick={() => history.push({ pathname: "/" })}
              style={{
                float: "left",
                width: "30px",
                cursor: "pointer",
              }}
            />
            <span
              onClick={() => history.push({ pathname: "/" })}
              style={{
                float: "left",
                cursor: "pointer",
                verticalAlign: "middle",
              }}
            >
              Back To Home
            </span>
          </div>
          <img
            alt=""
            src={krishiLogo}
            width="140px"
            height="50px"
            style={{
              backgroundColor: "green",
              alignItems: "center",
              margin: "auto",
            }}
          />
          <Typography
            style={{
              margin: "auto 20px",
              fontSize: "24px",
              fontFamily: "Lucida Grande, sans-serif",
            }}
          >
            Hello {username}
          </Typography>
          <PowerSettingsNewIcon
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
            style={{ float: "right", cursor: "pointer" }}
          />
        </Toolbar>
      </AppBar>

      <React.Fragment>
        <div style={{ maxWidth: "100%", margin: "80px auto" }}>
          {state && (
            <MaterialTable
              style={{ width: 1200, borderRadius: "4px" }}
              options={{
                search: true,
                paging: true,
                pageSize: 10,

                exportButton: true,

                draggable: false,
                actionsColumnIndex: -1,
                headerStyle: {
                  zIndex: "0",
                  backgroundColor: "#FAF3FF",
                  color: "#706F6F",
                },
                searchFieldStyle: {
                  float: "left",
                },
              }}
              actions={[
                {
                  icon: (prop) => (
                    <div>
                      <span></span>
                    </div>
                  ),
                  tooltip: "Add New User",
                  isFreeAction: true,
                },
              ]}
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              icons={tableIcons}
              title=""
              columns={state.columns}
              data={state.data}
            />
          )}
        </div>
      </React.Fragment>
    </div>
  );
}
