import React from "react";
import TextField from "@material-ui/core/TextField";

const InputText = ({ field,form,width, ...other }) => {
  const currentError = form.errors[field.name];
  const touched = form.touched[field.name];
  return (
    <TextField
      error={touched && currentError ? true : false}
      style={{
        gridColumn: `${other?.elStyle && other?.elStyle?.gridColumn}`,
        flex: 1,
        width: width ?? "100%",
      }}
      id={field.id}
      label={other.label}
      value={field.value}
      name={field.name}
      onChange={(e) => form.setFieldValue(field.name, e.target.value, false)}
      multiline
      rows={4}
      helperText={touched && currentError && currentError}
      variant="outlined"
    />
  );
};

export default InputText;
