import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

function DatePicker({ field, form, ...other }) {
  return (
    <>
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        style={{
          width: "100%",
        }}
      >
        <KeyboardDatePicker
          autoOk={true}
          inputVariant="outlined"
          variant="inline"
          format={other?.format ? other.format : "MM/dd/yyyy"}
          margin="normal"
          label="Date"
          name={field.name}
          value={field.value}
          minDate={other?.minDate ?? undefined}
          maxDate={other?.maxDate ?? undefined}
          // disablePast={other.disablePast}
          // disableFuture={!other.disablePast}
          onChange={(date) => form.setFieldValue(field.name, date, false)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
}

export default DatePicker;
