import { select, put, takeLatest } from "redux-saga/effects";
import * as types from "../../types";
import {
  searchTicketSuccess,
  searchTicketFailure,
} from "../../action/cmsActions/searchTicketAction";
import { returnErrors } from "../../action/errorAction";

export const getTickets = (state) => state.ticketReducer.allTickets;

const formatSearchTerms = (searchTerms, filters) => {
  let result = [];
  for (let key of filters) {
    let data = searchTerms.filter((search) => search.key === key) || [];
    let dataRes = data.map((item) => item.value.toString());
    result.push({ key, value: dataRes });
  }
  return result;
};

function* asyncSearchTicketRequestSaga(action) {
  try {
    let tickets = yield select(getTickets);
    let { searchTerms } = action.payload;
    if (searchTerms.length === 0) {
      yield put(searchTicketSuccess({ tickets }));
    } else {
      console.log("SearchTerms are :", searchTerms);
      let filteredTickets = [];
      let filters = [
        "spoc",
        "state",
        "district",
        "type",
        "user_name",
        "device_id",
        "id",
      ];

      let formattedSearchTerms = formatSearchTerms(searchTerms, filters);
      console.log(formattedSearchTerms);
      console.log("before : ", tickets.length);

      // TESTING:
      let darakshatickets = tickets.filter(
        (ticket) => ticket.spoc === "daraksha"
      );

      console.log("Daraksha tickets are : ", darakshatickets);

      // let accept;
      tickets.forEach((ticket) => {
        // accept = true;
        for (let i = 0; i < formattedSearchTerms.length; i++) {
          // if (
          //   ticket[formattedSearchTerms[i].key] &&
          //   formattedSearchTerms[i].value.length > 0 &&
          //   !formattedSearchTerms[i].value.includes(
          //     ticket[formattedSearchTerms[i].key]
          //       .toString()
          //       .trim()
          //       .toLowerCase()
          //   )
          // ) {
          //   accept = false;
          //   break;
          // }
          if (
            ticket[formattedSearchTerms[i].key] &&
            formattedSearchTerms[i].value.length > 0 &&
            formattedSearchTerms[i].value.includes(
              ticket[formattedSearchTerms[i].key]
                .toString()
                .trim()
                .toLowerCase()
            )
          ) {
            filteredTickets.push(ticket);
            break;
          }
        }
        /*
        formattedSearchTerms = [
          [key:"spoc",value:["daraksha","kriti"]],
          [key:"state",value:[]],
          [key:"district",value:[]],
          [key:"type",value:[]],
          [key:"user_name",value:[]],
          [key:"device_id",value:[]],
        ]
        */
        // if (accept) {
        //   filteredTickets.push(ticket);
        // }
      });
      console.log("after : ", filteredTickets.length);
      yield put(searchTicketSuccess({ tickets: filteredTickets }));
    }
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    yield put(searchTicketFailure(e));
  }
}

export default function* searchTicketSaga() {
  yield takeLatest(types.SEARCH_TICKET_REQUEST, asyncSearchTicketRequestSaga);
}
