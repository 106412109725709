import React from "react";
import { TicketTable } from "../../components";

function Dashboard() {
  return (
    <>
      <TicketTable />
    </>
  );
}

export default Dashboard;
