import { withTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    borderRadius: 4,
    border: 0,
    padding: "0 30px",
  },
  label: {
    textTransform: "capitalize",
  },
});

const CustomButton = withStyles(styles, { withTheme: true })(Button);

export default withTheme(CustomButton);
