import { combineReducers } from "redux";
import fetchDataReducer from "./fetchDataReducer.js";
import fetchServicesReducer from "./fetchServicesReducer";
import uploadExcelReducer from "./uploadExcelReducer";
import createCampaignReducer from "./createCampaignReducer";
// import errorReducer from "./errorReducer";
import fetchFilterCallsReducer from "./fetchFilterCallsReducer";

import errorReducer from "./errorReducer";
import fetchTicketReducer from "./cmsReducers/fetchTicketReducer";
import fetchTractorReducer from "./cmsReducers/fetchTractorReducer";
import issueTypeReducer from "./cmsReducers/issueTypeReducer";
import questionsReducer from "./cmsReducers/questionsReducer";
import welcomeReducer from "./cmsReducers/welcomeReducer";
import feedbackReducer from "./cmsReducers/feedbackReducer";
import forceCloseReducer from "./cmsReducers/forceCloseReducer";

import fetchCampaignReducer from "./campaignReducer";
import callRatingReducer from "./callRatingReducer";
import fetchCampaignReducerOutbound from "./outboundReducers/campaignReducer";
import fetchSmsTemplatesReducer from "./fetchSmsTemplatesReducer.js";
import smsTriggerReducer from "./smssTriggerReducer.js";

const rootReducer = combineReducers({
  errorReducer,
  fetchDataReducer,
  ticketReducer: fetchTicketReducer,
  tractorReducer: fetchTractorReducer,
  issuesReducer: issueTypeReducer,
  questionsReducer,
  welcomeReducer,
  feedbackReducer,
  servicesReducer: fetchServicesReducer,
  forceClose: forceCloseReducer,
  uploadExcelReducer,
  createCampaignReducer,
  campaignReducer: fetchCampaignReducer,
  callRating: callRatingReducer,
  filterCalls: fetchFilterCallsReducer,
  campaignReducerOutbound: fetchCampaignReducerOutbound,
  smsTemplates: fetchSmsTemplatesReducer,
  smsTrigger: smsTriggerReducer,
});

export default rootReducer;
