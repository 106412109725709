import React, { useState, useEffect } from "react";
import embedformStyles from "./embedform.styles";

import { JOTFORM_LOOKUP } from "../../config";
import Sidecall from "../Sidecall2";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import { useHistory, useLocation } from "react-router-dom";
import Formnav from "../Formnav";

const UrlToJotformMap = {
  "/general": "generalinquiry",
  "/miscellaneous": "miscellaneous",
  "/rental": "rentalinquiry",
  "/tractor": "tractorinquiry",
  "/tractor-fdw": "tractorinquiryFdw",
  "/implement-fdw": "implementinquiryFdw",
  "/implement": "implementinquiry",
  "/advisory": "advisory",
  "/complaints": "complaint",
  "/outbound-campaign": "outbound",
  "/ecommerce":"ecommerce"
};

const EmbedForm = ({ url, val, setVal }) => {
  const classes = embedformStyles();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [jotformUrl, setJotformUrl] = useState("");
  const [isFdw, setIsFdw] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [fdwType, setFdwType] = useState("Tractor Inquiry");
  const phoneNumber = sessionStorage.getItem("number");
  const formData = JSON.parse(sessionStorage.getItem("formData"));

  useEffect(() => {
    if (location.state && location.state.fdw) {
      setIsFdw(true);
      setFdwType(location.state.type);
    }
    if (location.state && location.state.ecommerce) {
      setIsEcommerce(true);
    }
    setLoading(true);
    setJotformUrl(
      JOTFORM_LOOKUP[UrlToJotformMap[location.pathname] ?? ""] ?? ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (val) {
      sessionStorage.setItem("form", val);
    }
  }, [val]);

  return (
    <div className={classes.root}>
      {/* <AppBar1 username={username} /> */}
      <div className={classes.formContainerWrapper}>
        <div className={classes.formNavContainer}>
          <Button
            color="secondary"
            style={{ lineHeight: "2.3em", width: "auto", color: "#652078" }}
            startIcon={<ArrowBackIcon style={{ fontSize: 18 }} />}
            onClick={() => history.push({ pathname: "/nav" })}
          >
            <p className={classes.backIcon}>Back to List</p>
          </Button>
          <Formnav val={val} setVal={setVal} />
        </div>
        <div className={classes.formContainer}>
          {loading ? (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          ) : null}
          <main className={classes.jotformContainer}>
            <iframe
              title="frame"
              id="JotFormIFrame"
              onLoad="window.parent.scrollTo(0,0)"
              onSubmit={() => {
                history.push({ pathname: "/redirect" });
              }}
              onLoad={() => {
                setLoading(false);
              }}
              allowtransparency="true"
              src={
                isFdw
                  ? `${jotformUrl}?number=${phoneNumber}&type=${fdwType}&originSource=Inbound&district=${
                      formData?.district ?? ""
                    }&state=${formData?.state ?? ""}&taluka=${
                      formData?.taluka ?? ""
                    }&village=${formData?.village ?? ""}&name=${
                      formData?.name ?? ""
                    }`
                  : `${jotformUrl}${url}`
              }
              frameBorder="0"
              style={{ width: "100%", height: "100%", border: "none" }}
              scrolling="yes"
            ></iframe>
          </main>
          <span className={classes.divider} />
          <Sidecall url={url?.split("&")[1].split("=")[1]} />
        </div>
      </div>
    </div>
  );
};

export default EmbedForm;
