import React from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { useHistory } from "react-router-dom";
import OutlinedInput from "@material-ui/core/OutlinedInput";
export default function Formnav({ val, setVal }) {
  const history = useHistory();

  function handleChange(e) {
    if (e.target.value === "General Understanding of Krish-e") {
      history.push("/general");
      setVal("General Understanding of Krish-e");
    } else if (e.target.value === "Krishe Rental Inquiry or Order Booking") {
      history.push("/rental");
      setVal("Krishe Rental Inquiry or Order Booking");
    } else if (e.target.value === "Tractor Inquiry") {
      history.push("/tractor");
      setVal("Tractor Inquiry");
    } else if (e.target.value === "Implement Inquiry") {
      history.push("/implement");
      setVal("Implement Inquiry");
    } else if (e.target.value === "Complaint") {
      setVal("Complaint");
      history.push("/complaints");
    } else if (e.target.value === "Advisory") {
      history.push("/advisory");
      setVal("Advisory");
    } else if (e.target.value === "Rental Order") {
      history.push("/rental-order");
      setVal("Rental Order");
    } else if (e.target.value === "Implement Inquiry FDW") {
      history.push("/implement-fdw");
      setVal("Implement Inquiry FDW");
    } else if (e.target.value === "Tractor Inquiry FDW") {
      history.push("/tractor-fdw");
      setVal("Tractor Inquiry FDW");
    }else if (e.target.value === "Inbound Ecommerce") {
      history.push("/ecommerce");
      setVal("Inbound Ecommerce");
    }
  }
  return (
    <FormControl
      variant="outlined"
      size="small"
      style={{
        float: "center",
        margin: "10px auto",
        width: "20%",
      }}
    >
      <InputLabel id="demo-simple-select-outlined-label"></InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={val}
        onChange={handleChange}
        size="large"
        label="Age"
        input={<OutlinedInput labelWidth={0} name="members" id="members" />}
      >
        <MenuItem value="General Understanding of Krish-e">
          General Understanding of Krish-e
        </MenuItem>
        <MenuItem value="Rental Order">Rental Order</MenuItem>
        <MenuItem value="Advisory">Advisory</MenuItem>
        <MenuItem value="Tractor Inquiry">Tractor Inquiry</MenuItem>
        <MenuItem value="Implement Inquiry">Implement Inquiry</MenuItem>
        <MenuItem value="Krishe Rental Inquiry or Order Booking">
          Krishe Rental Inquiry or Order Booking
        </MenuItem>
        <MenuItem value="Tractor Inquiry FDW">Tractor Inquiry FDW</MenuItem>
        <MenuItem value="Implement Inquiry FDW">Implement Inquiry FDW</MenuItem>
        <MenuItem value="Complaint">Complaint</MenuItem>
        <MenuItem value="Inbound Ecommerce">Inbound Ecommerce</MenuItem>
      </Select>
    </FormControl>
  );
}
