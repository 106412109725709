import { makeStyles, fade } from "@material-ui/core/styles";
const commonInfoStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  content: {
    flex: 1,
    overflow: "hidden",
  },
  space: {
    marginTop: "90px",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  widget: {
    height: "70vh",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  table: {
    minWidth: 600,
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
  Input: {
    // padding:'5px',
    padding: "4px",
  },
  text: {
    fontSize: "1rem",
    color: "grey",
    textAlign: "-webkit-center",
    fontFamily: "Lucida Grande, sans-serif",
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  textUndecorate: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.54)",
  },
  dataContainer: {
    maxWidth: "1000px",
    margin: "auto",
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
    background: "#fff",
    marginTop: "1em",
    boxShadow: "2px 2px 10px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    "& > * + *": {
      marginLeft: "3em",
    },
  },
  form: {
    flex: 2,
    padding: "2em 0",
    alignItems: "center",
  },
  formTitle: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > p": {
      backgroundColor: "#652078",
      color: "white",
      borderRadius: "4px",
      padding: "0 2em",
    },
  },
  callHealthContainer: {
    flex: 1,
    minHeight: "300px",
    height: "100%",
    padding: "2em 0",
    display: "flex",
    flexDirection: "column",
    "& > p": {
      color: "white",
      background: theme.palette.primary.main,
      borderRadius: "4px",
      fontWeight: "500",
      padding: "0 2em",
      alignSelf: "flex-start",
    },
  },
  callHealths: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2em",
    "& > * + *": {
      marginTop: ".5em",
    },
  },
  health: {
    width: "100%",
    padding: "1em 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& > p": {
      color: "rgba(0,0,0,0.8)",
      "& > span": {
        fontWeight: 700,
        color: "rgba(0,0,0,0.9)",
      },
    },
  },
  healthStatus: {
    fontSize: "0.78em",
    fontWeight: "400 !important",
    padding: "0 0.4em",
    color: "white !important",
    borderRadius: "4px",
  },
  healthHappy: {
    background: "rgba(0,255,0,0.9)",
    color: "rgba(0,0,0,0.8) !important",
    fontWeight: "bold !important",
  },
  healthNeutral: {
    background: "rgba(255,255,0,0.8)",
    color: "rgba(0,0,0,0.8) !important",
    fontWeight: "bold !important",
  },
  healthSad: {
    background: "rgba(255,0,0,0.8)",
  },
  noPhoneNumber: {
    fontWeight: "normal",
    textAlign: "left",
    alignSelf: "flex-start",
    padding: "0 1em 0 0",
    fontSize: "1em",
    "& > span": {
      fontWeight: "700",
    },
  },
}));

export default commonInfoStyles;
