import * as types from "../types";

export const callRatingRequest = ({ body }) => {
  return {
    type: types.CALL_RATING_REQUEST,
    payload: { body },
  };
};

export const callRatingSuccess = ({ res }) => {
  return {
    type: types.CALL_RATING_SUCCESS,
    payload: { res },
  };
};

export const callRatingFailure = (error) => {
  return {
    type: types.CALL_RATING_FAILURE,
    payload: {},
    error: error,
  };
};

export const resetCallRating = () => {
  return {
    type: types.CALL_RATING_RESET,
  };
};
