import axios from "axios";
import { API_ROUTES, API_BASE_URL } from "../config";

export const fetchTickets = async ({ spoc }) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.FETCH_CMS_TICKETS}?spoc=${spoc}`
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchTractors = async ({ phone }) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.FETCH_TRACTORS}?phone=${phone}`
    );
    console.log(data);
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const welcomeFormSubmission = async ({ requestData }) => {
  try {
    const { data } = await axios.post(
          `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.WELCOME_FORM_SUBMIT}`,
      requestData
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const feedbackCallSubmission = async ({ requestData }) => {
  try {
    const { data } = await axios.post(
          `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.FEEDBACK_CALL_SUBMIT}`,
      requestData
    );
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const forceCloseTicket = async ({ body }) => {
  try {
    const { data } = await axios.post(
          `${API_BASE_URL.CRM_FAST_API}${API_ROUTES.FORCE_CLOSE_TICKET}`,
      body
    );
    return data;
  } catch (e) {
    throw e;
  }
};
