import * as types from "../types";
const initialState = {
  loading: false,
  data: null,
  selected: null,
  selectedData: null,
  selectedLoading: false,
  selectedError: null,
  error: {},
};

const fetchFilterCallsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_FILTER_CALLS_REQUEST:
      return {
        ...state,
        loading: true,
        data: null,
        selected: null,
        selectedData: null,
        error: {},
      };
    case types.FETCH_FILTER_CALLS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: {},
      };
    case types.FETCH_FILTER_CALLS_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        selectedData: null,
        selected: null,
        error: action.error,
        selectedLoading: false,
      };
    case types.FETCH_FILTER_CALLS_SELECT_REQUEST:
      return {
        ...state,
        selected: {
          ticket_id: action.payload.ticket_id,
          category: action.payload.category,
        },
        selectedLoading: true,
        selectedError: null,
      };
    case types.FETCH_FILTER_CALLS_SELECT_FAILURE:
      return {
        ...state,
        selected: null,
        selectedLoading: false,
        selectedError: action.error,
      };
    case types.FETCH_FILTER_CALLS_SELECT_SUCCESS:
      return {
        ...state,
        selectedData: action.payload.data,
        selectedError: null,
        selectedLoading: false,
      };
    default:
      return state;
  }
};

export default fetchFilterCallsReducer;
