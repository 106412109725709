import * as types from "../types";

export const fetchSmsTemplatesRequest = () => {
  return {
    type: types.FETCH_SMS_TEMPLATE_REQUEST,
  };
};

export const fetchSmsTemplatesSuccess = ({ templates }) => {
  return {
    type: types.FETCH_SMS_TEMPLATE_SUCCESS,
    payload: { templates },
  };
};

export const fetchSmsTemplatesFailure = (error) => {
  return {
    type: types.FETCH_SMS_TEMPLATE_FAILURE,
    payload: {},
    error: error,
  };
};
