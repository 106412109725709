import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types";
import {fetchData} from "../../api";
import { fetchDataSuccess, fetchDataFailure } from "../action/fetchDataAction";
import { returnErrors, clearErrors } from "../action/errorAction";

function* asyncfetchDataSaga(action) {
  try {
    yield put(clearErrors());
    console.log('-------========'+JSON.stringify(action.payload.body));
    let res = yield call(fetchData, { phone: action.payload.body});
    yield put(fetchDataSuccess({ data: res.data }));
    // yield put(fetchDataSuccess({ data: res.data.data.orders }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(fetchDataFailure(e));
  }
}
export default function* fetchDataSaga() {
  yield takeLatest(types.FETCH_DATA_REQUEST, asyncfetchDataSaga);
}
