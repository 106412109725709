import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../types";
import {
  fetchTractorSuccess,
  fetchTractorFailure,
} from "../../action/cmsActions/fetchTractorAction";
import { returnErrors, clearErrors } from "../../action/errorAction";
import * as _api from "../../../cmsApi";

function* asyncTractorRequestSaga(action) {
  try {
    yield put(clearErrors());
    let res = yield call(_api.fetchTractors, { phone: action.payload.phone });
    yield put(
      fetchTractorSuccess({
        tractors: res.tractors,
        existingTickets: res.existing_tickets,
      })
    );
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(fetchTractorFailure(e));
  }
}

export default function* tractorRequestSaga() {
  yield takeLatest(types.FETCH_TRACTOR_REQUEST, asyncTractorRequestSaga);
}
