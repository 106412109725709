import * as types from "../../types";

const initialState = {
  issues: [],
};

const issueTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_ISSUE_TYPE:
      return {
        issues: [...state.issues, action.payload.issue],
      };
    case types.REMOVE_ISSUE_TYPE:
      return {
        issues: state.issues.filter((issue) => issue !== action.payload.issue),
      };
    case types.RESET_ISSUE_TYPE:
      return {
        issues: [],
      };
    default:
      return state;
  }
};

export default issueTypeReducer;
