import * as types from "../types";

const initialState = {
  loading: false,
  body: null,
  res: null,
  error: {},
};

const callRatingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CALL_RATING_REQUEST:
      return {
        ...state,
        loading: true,
        body: action.payload.body,
      };
    case types.CALL_RATING_SUCCESS:
      return {
        ...state,
        loading: false,
        res: action.payload.res,
        error: {},
      };
    case types.CALL_RATING_FAILURE:
      return {
        ...state,
        loading: false,
        body: null,
        res: null,
        error: action.error,
      };
    case types.CALL_RATING_RESET:
      return {
        ...state,
        loading: false,
        body: null,
        res: null,
        error: {},
      };
    default:
      return state;
  }
};

export default callRatingReducer;
