import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../types";
import {
  forceCloseSuccess,
  forceCloseFailure,
} from "../../action/cmsActions/forceCloseAction";
import { returnErrors, clearErrors } from "../../action/errorAction";
import * as _api from "../../../cmsApi";

function* asyncForceCloseSaga(action) {
  try {
    yield put(clearErrors());
    let { body } = action.payload;
    let res = yield call(_api.forceCloseTicket, {
      body,
    });
    yield put(forceCloseSuccess({ res }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(forceCloseFailure(e));
  }
}

export default function* forceCloseSaga() {
  yield takeLatest(types.FORCE_CLOSE_REQUEST, asyncForceCloseSaga);
}
