import * as types from "../types";
const initialState = {
  loading: false,
  res: null,
  error: {},
};

const smsTriggerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SMS_TRIGGER_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case types.SMS_TRIGGER_SUCCESS:
      return {
        ...state,
        loading: false,
        res: action.payload.res,
        error: {},
      };
    case types.SMS_TRIGGER_FAILURE:
      return {
        ...state,
        loading: false,
        res: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default smsTriggerReducer;
