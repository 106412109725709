import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const InputSelect = ({ field, form, ...other }) => {
  const currentError = form.errors[field.name];
  return (
    <FormControl
      variant="outlined"
      error={currentError ? true : false}
      style={{
        width: "100%",
      }}
    >
      <InputLabel htmlFor="outlined-tractor-fuel-meter">
        {other.label}
      </InputLabel>
      <Select
        value={field.value}
        onChange={(e) => {
          console.log(e);
          form.setFieldValue(field.name, e.target.value, false);
        }}
        label={other.label}
        inputProps={{
          name: other.name,
          id: other.id,
        }}
      >
        {other.options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {currentError && <FormHelperText>{currentError}</FormHelperText>}
    </FormControl>
  );
};

export default InputSelect;
