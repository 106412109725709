import React from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import { Button } from "../elements";
const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2em 0 0 0",
    position: "relative",
  },
  navBtn: {
    lineHeight: "45px",
    color: theme.palette.dark.main,
    background: theme.palette.grey.main,
  },
  activeBtn: {
    border: `1px solid ${theme.palette.accent.main} !important`,
    zIndex: 5,
    borderRadius: "4px",
    color: theme.palette.accent.main,
    fontWeight: "bold",
  },
  backToPrev: {
    position: "absolute",
    top: "50%",
    left: 0,
    transform: "translateY(-80%)",
    background: theme.palette.light.main,
    color: theme.palette.accent.main,
  },
  disableBtn: {
    opacity: 0.5,
  },
}));

const TableFilter = ({ filter, setFilter }) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <Button
        className={`${classes.navBtn} ${
          filter === "Open" ? classes.activeBtn : classes.disableBtn
        }`}
        onClick={() => setFilter("Open")}
      >
        <p>OPEN</p>
      </Button>
      <Button
        className={`${classes.navBtn} ${
          filter === "Closed" ? classes.activeBtn : classes.disableBtn
        }`}
        onClick={() => setFilter("Closed")}
      >
        <p>CLOSED</p>
      </Button>
      <Button
        className={`${classes.navBtn} ${
          filter === "No Response" ? classes.activeBtn : classes.disableBtn
        }`}
        onClick={() => setFilter("No Response")}
      >
        <p>NO RESPONSE</p>
      </Button>
    </div>
  );
};

export default withTheme(TableFilter);
