import React, { useState, useEffect } from "react";
import searchCallTableStyles from "./searchCallTable.styles";
import {
  makeStyles,
  withStyles,
  useTheme,
  withTheme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";

import TablePagination from "@material-ui/core/TablePagination";
import { TableFooter } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { fetchFiltercallsSelectRequest } from "../../../../store/action/fetchFilterCallsAction";

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: theme.palette.grey.main,
    color: "#706F6F",
  },
  body: {
    fontSize: 14,
    color: theme.palette.dark.main,
    fontWeight: "bold",
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    cursor: "pointer",
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
}))(TableRow);

const renderLoading = () => {
  let range = [...Array(8).keys()];
  let range2 = [...Array(5).keys()];
  return range.map((i) => (
    <StyledTableRow key={i}>
      {range2.map((j) => (
        <StyledTableCell
          key={j + 10}
          style={{
            height: 60,
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Skeleton
            animation="wave"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </StyledTableCell>
      ))}
    </StyledTableRow>
  ));
};

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const SearchCallTable = ({
  theme,
  selectRow,
  handleModal,
  filterCallsData,
  filterCallsLoading,
}) => {
  const classes = searchCallTableStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (Array.isArray(filterCallsData)) {
      let SortHelper = (a, b) =>
        new Date(a.created_at) > new Date(b.created_at) ? -1 : 1;
      let newData = filterCallsData.sort((a, b) => SortHelper(a, b));
      setData(newData);
    }
  }, [filterCallsData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const getCurrentData = (rowData) => {
    if (rowsPerPage > 0) {
      return rowData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    } else {
      return rowData;
    }
  };

  return (
    <TableContainer style={{ marginTop: "3em" }}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Ticket ID</StyledTableCell>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Phone No.</StyledTableCell>
            <StyledTableCell align="left">Category</StyledTableCell>
            <StyledTableCell align="left">Created At</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!filterCallsLoading ? (
            <>
              {data && Array.isArray(data) && (
                <>
                  {getCurrentData(data).map((row) => (
                    <StyledTableRow
                      key={row.ticket_id}
                      onClick={() => {
                        selectRow({
                          ticket_id: row.ticket_id,
                          category: row.category,
                        });
                        handleModal();
                      }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.ticket_id}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.farmer_name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.farmer_phone}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.category}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(row.created_at).toLocaleDateString("en-US")}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              )}
            </>
          ) : (
            renderLoading()
          )}
        </TableBody>
        {Array.isArray(data) && data.length > 0 && (
          <TableFooter
            style={{
              background: theme.palette.grey.main,
            }}
          >
            <StyledTableRow>
              <TablePagination
                style={{ borderBottom: "none" }}
                rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </StyledTableRow>
          </TableFooter>
        )}
      </Table>
      {!filterCallsLoading &&
        (!filterCallsData || filterCallsData?.length === 0) && (
          <div className={classes.noDataFound}>
            <p>No Data Found, Search Something Else...</p>
          </div>
        )}
    </TableContainer>
  );
};

const mapStateToProps = (state) => ({
  filterCallsData: state.filterCalls.data,
  filterCallsLoading: state.filterCalls.loading,
});

const mapDispatchToProps = (dispatch) => ({
  selectRow: ({ ticket_id, category }) => {
    dispatch(fetchFiltercallsSelectRequest({ ticket_id, category }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(SearchCallTable));
