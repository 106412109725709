import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { FormHelperText } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles((theme) => ({}));

function InputField({ field, form, ...other }) {
  const classes = useStyles();
  const currentError = form.errors[field.name];

  return (
    <FormControl
      variant="outlined"
      // error={errors.actualArea && touched.actualArea ? true : false}
      error={currentError ? true : false}
      style={{
        width: "100%",
      }}
    >
      <InputLabel htmlFor="outlined-implement-pin">{other.label}</InputLabel>
      <OutlinedInput
        className={classes.searchInput}
        id={other.id}
        type={other.type ? other.type : "number"}
        name={field.name}
        value={field.value}
        onChange={(e) => form.setFieldValue(field.name, e.target.value, false)}
        onError={(error) => {
          if (error !== currentError) {
            form.setFieldError(field.name, error);
          }
        }}
        labelWidth={other.labelWidth}
      />
      {currentError && <FormHelperText>{currentError}</FormHelperText>}
    </FormControl>
  );
}

export default InputField;
