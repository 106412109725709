import { makeStyles } from "@material-ui/core/styles";

const searchCallTableStyles = makeStyles((theme) => ({
  searchCallContainer: {
    maxWidth: "1300px",
    margin: "3em auto",
    width: "100%",
    minHeight: "800px",
    borderRadius: "15px",
    background: "#fff",
    padding: "2em",
    boxShadow: "0px 3px 20px rgba(0,0,0,0.1)",
  },
  noDataFound: {
    width: "100%",
    height: "300px",
    display: "grid",
    placeContent: "center",
    "& > p": {
      fontSize: "2em",
      fontWeight: "bold",
      color: "rgba(0,0,0,.5)",
    },
  },
}));

export default searchCallTableStyles;
