import React from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import { Button } from "../elements";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "2em",
    borderBottom: `2px solid ${theme.palette.grey.main}`,
    position: "relative",
  },
  navBtn: {
    lineHeight: "45px",
    color: theme.palette.dark.main,
    background: theme.palette.grey.main,
  },
  activeBtn: {
    border: `1px solid ${theme.palette.accent.main} !important`,
    zIndex: 5,
    borderRadius: "4px",
    color: theme.palette.accent.main,
    fontWeight: "bold",
  },
  backToPrev: {
    position: "absolute",
    top: "50%",
    left: 0,
    transform: "translateY(-80%)",
    background: theme.palette.light.main,
    color: theme.palette.accent.main,
  },
  disableBtn: {
    opacity: 0.5,
  },
}));

function FormNav() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  return (
    <div className={classes.header}>
      <Button
        className={classes.backToPrev}
        startIcon={<ArrowBackIosIcon style={{ fontSize: 14 }} />}
        onClick={() => history.push("/cms")}
      >
        Back
      </Button>
      <Button
        className={`${classes.navBtn} ${
          location.pathname === "/cms/feedback"
            ? classes.activeBtn
            : classes.disableBtn
        }`}
        disabled={location.pathname !== "/cms/feedback"}
      >
        <NavLink to="/cms/feedback">FEEDBACK</NavLink>
      </Button>
      <Button
        className={`${classes.navBtn} ${
          location.pathname === "/cms/welcome"
            ? classes.activeBtn
            : classes.disableBtn
        }`}
        disabled={location.pathname !== "/cms/welcome"}
      >
        <NavLink to="/cms/welcome">WELCOME</NavLink>
      </Button>
    </div>
  );
}

export default withTheme(FormNav);
