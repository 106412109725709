import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: theme.palette.grey.main,
    color: "#706F6F",
  },
  body: {
    fontSize: 14,
    color: theme.palette.dark.main,
    fontWeight: "bold",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "fixed",
    top: "25%",
    left: "50%",
    transform: "translate(-50%,-25%)",
    minWidth: "600px",
    minHeight: "300px",
    maxHeight: "600px",
    outline: "none",
    border: "none",
    background: theme.palette.light.main,
    padding: "2em",
    borderRadius: "4px",
    display: "flex",
    marginTop: "3em",
    marginBottom: "3em",
    flexDirection: "column",
    "& h1,h2,h3,h4,h5,p": {
      margin: "0 !important",
      padding: "0 !important",
    },
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  ticketHeader: {
    width: "100%",
    paddingBottom: "1em",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `4px solid ${theme.palette.grey.main}`,
    "& > h2": {
      fontWeight: "600",
      fontSize: "1.3em",
      color: theme.palette.dark.main,
    },
    "& > span": {
      color: theme.palette.accent.main,
      cursor: "pointer",
    },
  },
  mainContent: {
    width: "100%",
    minHeight: "300px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flex: 1,
    overflowY: "scroll",
  },
}));

const SearchCallModal = ({
  open,
  data,
  error,
  handleClose,
  selectedLoading,
}) => {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState(null);
  useEffect(() => {
    if (error?.status) {
      setErrorMsg(error.msg);
    }
  }, [error]);

  const formatData = (row) => {
    if (typeof row === "object") {
      if (Array.isArray(row)) {
        return row.join(", ");
      } else {
        return JSON.stringify(row);
      }
    } else if (row === "") {
      return "NA";
    } else {
      return String(row);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="ticket-detail-modal"
      aria-describedby="ticket-detail-modal"
    >
      <div className={classes.modalStyle}>
        <div className={classes.ticketHeader}>
          <h2>Call Details</h2>
          <span onClick={handleClose}>Close X</span>
        </div>
        <div className={classes.mainContent}>
          {selectedLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <TableBody>
                  {data &&
                    Object.keys(data).map((element) => (
                      <StyledTableRow key={element}>
                        <StyledTableCell align="left">
                          {element}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatData(data[element])}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {errorMsg && <p>{errorMsg}</p>}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  selectedLoading: state.filterCalls.selectedLoading,
  data: state.filterCalls.selectedData,
  error: state.filterCalls.selectedError,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCallModal);
