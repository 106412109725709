import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import AppBar from "@material-ui/core/AppBar";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { useLocation } from "react-router-dom";
import krisheJson from "../data/krishe.json";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { API_ROUTES, API_BASE_URL } from "../config";
const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "auto",
    backgroundColor: "#652078",
  },
  card: {
    height: "auto",
    width: "700px",
  },
  rowWise: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px auto",
  },
  smallCard: {
    width: "500px",
    margin: "8px auto  24px  auto",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function SubmitOrder({ number }) {
  const classes = useStyles();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [search, setSearch] = useState(location.state.data);

  const [comments, setComments] = useState("");
  const [jobTime, setJobTime] = useState("");
  const [duration, setDuration] = useState("");
  const [date, setDate] = useState(new Date());
  const [coupon, setCoupon] = useState("");
  const [area, setArea] = useState("");
  const [dateData, setDateData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const type = location.state.type;
  const newFarm = location.state.new_farm;
  const farm = location.state.farm;
  const [data, setData] = useState({});
  const [checkUnit, setCheckUnit] = useState("");

  const [color, setColor] = useState(true);
  const [unit, setUnit] = useState("acre");
  const [timeUnit, setTimeUnit] = useState("hour");
  const [tab, setTab] = useState(0);
  let unit_arr = ["acre", "hectare", "bigha", "guntha"];
  let unit_time = ["hour", "minute", "second"];
  const formik = useFormik({
    initialValues: {
      job_start_time: "",
      duration: "",
      area: null,
      category: "",
      subcategory: "",
      coupon_code: "",
      comments: "",
      price_unit: "",
      price: 0,
    },
    onSubmit: (values, e) => {
      setData({
        job_start_time: values.job_start_time,
        duration: values.duration,
        area: values.area,
        category: values.category,
        subcategory: values.subcategory,
        comments: values.comments,
        coupon_code: values.coupon,
        price_unit: values.price_unit,
        price: values.price,
      });
    },
  });
  useEffect(() => {
    if (type !== "hub")
      setSearch([...new Set(krisheJson.map((val) => val.Type))]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let body = {
      is_servicable: type === "hub" ? true : false,
      center_type: type,
      new_farm: newFarm,
      farm_details: farm,
      farmer_details: JSON.parse(sessionStorage.getItem("formData")),
      service_details: {
        job_start_time: data.job_start_time,
        duration: data.duration,
        area: data.area,
        category: data.category,
        subcategory: data.subcategory,
        comments: data.comments,
        coupon_code: data.coupon_code,
        price_unit: data.price_unit,
        price: data.price,
      },
    };
    console.log("-----" + JSON.stringify(body));
    let config = {
      ApiKey: `41k59h2PEWniwk3QD0IjnYFYC7X92qPz`,
      Authorization: `Token ${token}`,
    };
    if (Object.keys(data).length !== 0) {
      axios.post(
        `${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.CREATE_NEW_ORDER}`,
        body,
        {
          headers: config,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleTab = (event, newValue) => {
    setTab(newValue);
  };
  const handleComments = (event) => {
    setComments(event.target.value);
    formik.values.comments = event.target.value;
  };
  const handleArea = (event) => {
    setArea(event.target.value);
    if (type === "hub") {
      formik.values.area = parseInt(event.target.value);
    }
  };
  const handleDuration = (event) => {
    setDuration(event.target.value);
    formik.values.duration = parseInt(event.target.value);
  };
  const handleDate = (event) => {
    // setDate(event.target.value);

    setDateData(
      event.getFullYear() + "-" + event.getMonth() + "-" + event.getDate()
    );
    setDate(event);
    console.log(
      event.getFullYear() + "-" + event.getMonth() + "-" + event.getDate()
    );
  };
  const handleJobTime = (event) => {
    setJobTime(event.target.value);
    formik.values.job_start_time = dateData + " " + event.target.value + ":00";
  };
  const handleCoupon = (event) => {
    setCoupon(event.target.value);
    formik.values.coupon = event.target.value;
  };
  const handleUnit = (event) => {
    setUnit(event.target.value);
  };
  const handleTimeUnit = (e) => {
    setTimeUnit(e.target.value);
  };
  const handleSearch = (val) => {
    formik.values.category = val;
  };
  function sPrice(price, unit) {
    formik.values.price = parseInt(price);
    setCheckUnit(unit);
    formik.values.price_unit = unit;
  }

  return (
    <div className={classes.row}>
      <form onSubmit={formik.handleSubmit} autoComplete="none">
        <Card className={classes.card}>
          <Paper className={classes.rowWise}>
            <h4 style={{ fontSize: "20px", color: "grey", margin: "20px" }}>
              Search Implements:
            </h4>
            {type === "hub" ? (
              <Autocomplete
                freeSolo
                id="combo-box-demo"
                disableClearable
                options={Object.keys(search)}
                getOptionLabel={(option) => option}
                value={searchTerm}
                onChange={(event, newValue) => {
                  setSearchTerm(newValue);
                  if (newValue !== null) handleSearch(newValue);
                }}
                style={{ width: 360 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose Implements"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: "search" }}
                  />
                )}
              />
            ) : (
              <Autocomplete
                freeSolo
                id="combo-box-demo"
                disableClearable
                options={search}
                getOptionLabel={(option) => option}
                value={searchTerm.Type}
                onChange={(event, newValue) => {
                  setSearchTerm(newValue);
                  if (newValue !== null) handleSearch(newValue);
                }}
                style={{ width: 360 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose Implements"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: "search" }}
                  />
                )}
              />
            )}
          </Paper>
          <Card style={{ margin: "30px auto" }}>
            {searchTerm !== "" && type === "hub" && (
              <AppBar position="static">
                <Tabs
                  value={tab}
                  onChange={handleTab}
                  aria-label="simple tabs example"
                >
                  <Tab label="PER HOUR" />
                  <Tab label="PER ACRE" />
                  <Tab label="PER DAY" />
                </Tabs>
              </AppBar>
            )}

            {searchTerm !== "" && type === "hub" && (
              <TabPanel value={tab} index={0}>
                <b> Rate/Per Hour: </b> ₹
                <span style={{ fontSize: "20px" }}>
                  {" "}
                  {search[searchTerm]["price_per_hour"]}{" "}
                </span>
                <Button
                  style={{
                    backgroundColor: color ? "#F37021" : "green",
                    color: "white",
                    margin: "20px 10px",
                    fontSize: "16px",
                    width: "100px",
                    height: "38px",
                  }}
                  onClick={() => {
                    sPrice(search[searchTerm]["price_per_hour"], "hour");
                    setColor(!color);
                  }}
                >
                  {color ? "Select" : "Selected"}
                </Button>
              </TabPanel>
            )}
            {searchTerm !== "" && type === "hub" && (
              <TabPanel value={tab} index={1}>
                <b>Rate/Per Acre: </b> ₹
                <span style={{ fontSize: "20px" }}>
                  {" "}
                  {search[searchTerm]["price_per_acre"]}
                </span>
                <Button
                  style={{
                    backgroundColor: color ? "#F37021" : "green",
                    color: "white",
                    margin: "20px 10px",
                    fontSize: "16px",
                    width: "100px",
                    height: "38px",
                  }}
                  onClick={() => {
                    sPrice(search[searchTerm]["price_per_acre"], "acre");
                    setColor(!color);
                  }}
                >
                  {color ? "Select" : "Selected"}
                </Button>
              </TabPanel>
            )}
            {searchTerm !== "" && type === "hub" && (
              <TabPanel value={tab} index={2}>
                <b>Rate/Per Day: </b>₹
                <span style={{ fontSize: "20px" }}>
                  {search[searchTerm]["price_per_day"]}
                </span>
                <Button
                  className={classes.snapLeftBtn}
                  style={{
                    backgroundColor: color ? "#F37021" : "green",
                    color: "white",
                    margin: "20px 10px",
                    fontSize: "16px",

                    width: "100px",
                    height: "38px",
                  }}
                  onClick={() => {
                    sPrice(search[searchTerm]["price_per_day"], "day");
                    setColor(!color);
                  }}
                >
                  {color ? "Select" : "Selected"}
                </Button>
              </TabPanel>
            )}
          </Card>

          <Card className={classes.smallCard}>
            <h2 style={{ color: "grey", margin: "20px" }}>
              Operation Details:
            </h2>
            {checkUnit !== "day" && checkUnit !== "hour" && (
              <div style={{ margin: "40px auto" }}>
                <span style={{ verticalAlign: "center" }}>
                  {" "}
                  <b
                    style={{
                      fontSize: "20px",
                      color: "grey",
                      margin: "auto 20px",
                    }}
                  >
                    Servicable Area :
                  </b>
                  <TextField
                    id="filled-multiline-flexible"
                    type="text"
                    multiline
                    autoComplete="off"
                    style={{ width: "8rem" }}
                    onChange={handleArea}
                    //   value={phone}
                    variant="outlined"
                    // onKeyDown={(e)=>keyPress(e)}
                    size="small"
                  />
                  <b
                    style={{
                      fontSize: "20px",
                      color: "grey",
                      margin: "auto 10px",
                    }}
                  >
                    Unit:{" "}
                  </b>
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      onChange={handleUnit}
                      defaultValue="Unit"
                      style={{ width: "80px" }}
                      size="small"
                      value={unit}
                    >
                      {unit_arr.map((val) => {
                        return <MenuItem value={val}>{val}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </span>
              </div>
            )}
            <div style={{ margin: "40px auto" }}>
              <span style={{ verticalAlign: "center" }}>
                {" "}
                <b
                  style={{
                    fontSize: "20px",
                    color: "grey",
                    margin: "auto 20px",
                  }}
                >
                  Duration :
                </b>
                <TextField
                  id="filled-multiline-flexible"
                  type="text"
                  multiline
                  autoComplete="off"
                  style={{ width: "8rem" }}
                  onChange={handleDuration}
                  value={duration}
                  variant="outlined"
                  // onKeyDown={(e)=>keyPress(e)}
                  size="small"
                />
                <b
                  style={{
                    fontSize: "20px",
                    color: "grey",
                    margin: "auto 20px",
                  }}
                >
                  Unit:
                </b>
                <FormControl>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={handleTimeUnit}
                    defaultValue="Unit"
                    style={{ width: "80px" }}
                    size="small"
                    value={timeUnit}
                  >
                    {unit_time.map((val) => {
                      return <MenuItem value={val}>{val}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </span>
            </div>
          </Card>
          <Card className={classes.smallCard}>
            <h2 style={{ color: "grey", margin: "20px" }}>
              Select Date & Time:
            </h2>
            <div style={{ margin: "40px auto" }}>
              <span style={{ verticalAlign: "center" }}>
                {" "}
                <b
                  style={{
                    fontSize: "20px",
                    color: "grey",
                    margin: "auto 20px",
                  }}
                >
                  Pick a Date :
                </b>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    disablePast
                    id="date-picker-dialog"
                    //  label="Date picker dialog"
                    format="dd/MM/yyyy"
                    onChange={handleDate}
                    value={date}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </span>
            </div>
            <div style={{ margin: "40px auto" }}>
              <span style={{ verticalAlign: "center" }}>
                {" "}
                <b
                  style={{
                    fontSize: "20px",
                    color: "grey",
                    margin: "auto 20px",
                  }}
                >
                  Pick Time :
                </b>
                <TextField
                  id="time"
                  label="time"
                  type="time"
                  defaultValue="00:00"
                  className={classes.textField}
                  value={jobTime}
                  onChange={handleJobTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "120px" }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </span>
            </div>
          </Card>

          <Card className={classes.smallCard}>
            <h2 style={{ color: "grey", margin: "20px" }}>Apply Coupon:</h2>
            <div style={{ margin: "40px auto" }}>
              <span style={{ verticalAlign: "center" }}>
                {" "}
                <b
                  style={{
                    fontSize: "20px",
                    color: "grey",
                    margin: "auto 20px",
                  }}
                >
                  Enter Coupon Code :
                </b>
                <TextField
                  id="filled-multiline-flexible"
                  type="text"
                  multiline
                  autoComplete="off"
                  style={{ width: "8rem", marginTop: "20px" }}
                  onChange={handleCoupon}
                  value={coupon}
                  variant="outlined"
                  // onKeyDown={(e)=>keyPress(e)}
                  size="small"
                />
                <Button
                  style={{
                    backgroundColor: "#F37021",
                    color: "white",
                    margin: "20px 10px",
                    fontSize: "16px",
                    width: "100px",
                    height: "38px",
                  }}
                >
                  Apply
                </Button>
              </span>
            </div>
          </Card>
          <Card className={classes.smallCard}>
            <div style={{ margin: "40px auto" }}>
              <span style={{ verticalAlign: "center" }}>
                {" "}
                <b
                  style={{
                    fontSize: "20px",
                    color: "grey",
                    margin: "auto 20px",
                  }}
                >
                  Comments:
                </b>
                <TextField
                  id="filled-multiline-flexible"
                  type="text"
                  multiline
                  autoComplete="off"
                  onChange={handleComments}
                  value={comments}
                  variant="outlined"
                  // onKeyDown={(e)=>keyPress(e)}
                  size="small"
                />
              </span>
            </div>
          </Card>
          <div>
            <Button
              type="submit"
              style={{
                backgroundColor: "#F37021",
                color: "white",
                margin: "20px 10px",
                fontSize: "16px",
                width: "140px",
                height: "58px",
              }}
            >
              Place Order
            </Button>
            <Button
              style={{
                backgroundColor: "#F37021",
                color: "white",
                margin: "20px 10px",
                fontSize: "16px",
                width: "140px",
                height: "58px",
              }}
            >
              Cancel Order
            </Button>
          </div>
        </Card>
      </form>
    </div>
  );
}
