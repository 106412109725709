import { call, put, takeLatest, select } from "redux-saga/effects";
import * as types from "../../types";
import {
  feedbackSuccess,
  feedbackFailure,
} from "../../action/cmsActions/feedbackAction";
import { returnErrors, clearErrors } from "../../action/errorAction";
import * as _api from "../../../cmsApi";


export const getIssues = (state) => state.issuesReducer.issues;
export const getAnswers = (state) => state.questionsReducer;

function* asyncFeedbackSaga(action) {
  try {
    yield put(clearErrors());
    let {
      id,
      callType,
      device_id,
      additional_remarks,
      user_phone,
      spoc,
    } = action.payload;

    let requestData = {
      id,
      type: callType,
      response: [],
      user_phone: user_phone,
      spoc: spoc,
    };

    let issues = yield select(getIssues);
    let answers = yield select(getAnswers);

    issues.forEach((issue) => {
      console.log(issue);
      let qna = {};
      qna.device_id = device_id;
      qna.additional_remarks = additional_remarks;
      switch (issue) {
        case "area":
          qna.issue_type = "Area";
          qna.ticket_qna = answers.areaAnswers;
          break;
        case "distance":
          qna.issue_type = "Distance";
          qna.ticket_qna = answers.distanceAnswers;
          break;
        case "fuel_consumption":
          qna.issue_type = "Fuel Consumption";
          qna.ticket_qna = answers.fuelConsumptionAnswers;
          break;
        case "fuel_level":
          qna.issue_type = "Fuel Level";
          qna.ticket_qna = answers.fuelLevelAnswers;
          break;
        case "job_time":
          qna.issue_type = "Job Time";
          qna.ticket_qna = answers.jobTimeAnswers;
          break;
        case "location":
          qna.issue_type = "Location";
          qna.ticket_qna = answers.locationAnswers;
          break;
        case "missing_alerts":
          qna.issue_type = "Missing alerts";
          qna.ticket_qna = answers.missingAlertsAnswers;
          break;
        case "others":
          qna.issue_type = "Others";
          qna.ticket_qna = answers.othersAnswers;
          break;
        case "recharge_request":
          qna.issue_type = "Recharge Request";
          qna.ticket_qna = answers.rechargeRequestAnswers;
          break;
        case "total_time":
          qna.issue_type = "Total Time";
          qna.ticket_qna = answers.totalTimeAnswers;
          break;
        case "trip_miss":
          qna.issue_type = "Trip Miss";
          qna.ticket_qna = answers.tripMissAnswers;
          break;
        case "too_many_alerts":
          qna.issue_type = "Too many alerts";
          qna.ticket_qna = answers.tooManyAlertsAnswers;
          break;
        default:
          break;
      }
      requestData.response.push(qna);
    });

    console.log(requestData);

    let res = yield call(_api.feedbackCallSubmission, {
      requestData,
    });
    yield put(feedbackSuccess({ res }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(feedbackFailure(e));
  }
}

export default function* feedbackSaga() {
  yield takeLatest(types.FEEDBACK_REQUEST, asyncFeedbackSaga);
}
