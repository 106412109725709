import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { fetchFilterCallsRequest } from "../../../../store/action/fetchFilterCallsAction";

const useStyles = makeStyles((theme) => ({
  tableOptions: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "2em",
    borderBottom: `2px solid ${theme.palette.grey.main}`,
  },
  tableOptionsRight: {
    display: "flex",
    alignItems: "center",
    gap: "1.2em",
  },
  searchInput: {
    width: "300px",
    background: theme.palette.grey.main,
  },
  fieldSelection: {
    marginLeft: "1em",
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },
}));

const SearchCallTopBar = ({ fetchCalls, theme, ...props }) => {
  const classes = useStyles();
  let number = sessionStorage.getItem("number");
  const [searchValue, setSearchValue] = useState(
    sessionStorage.getItem("number") ?? ""
  );
  const [searchKey, setSearchKey] = useState("phone");

  useEffect(() => {
    fetchCalls({ searchKey: "phone", searchValue: number });
  }, []);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchCalls({ searchKey, searchValue });
    }
  };

  return (
    <div className={classes.tableOptions}>
      <div className={classes.searchBox}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-search-input">Search</InputLabel>
          <OutlinedInput
            className={classes.searchInput}
            id="outlined-search-input"
            name="outlined-search"
            value={searchValue}
            onChange={(e) => handleSearchChange(e)}
            onKeyDown={(e) => handleKeyPress(e)}
            labelWidth={50}
          />
        </FormControl>
        <FormControl variant="outlined">
          <Select
            value={searchKey}
            onChange={(e) => {
              setSearchKey(e.target.value);
            }}
          >
            {[
              { label: "Phone", value: "phone" },
              { label: "Order ID", value: "order_id" },
              { label: "Name", value: "name" },
            ].map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  fetchCalls: ({ searchKey, searchValue }) => {
    dispatch(fetchFilterCallsRequest({ searchKey, searchValue }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCallTopBar);
