import { makeStyles } from "@material-ui/core/styles";

const navbarStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "5em",
    background: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    padding: "0 2em",
    zIndex: 100,
  },
  logo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    "& > img": {
      width: "100%",
      height: "2.5em",
    },
  },
  accountDesc: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    "& > h3": {
      color: theme.palette.light.main,
    },
  },
  logoutBtn: {
    width: "9.3em",
    lineHeight: "3em",
    marginLeft: "2em",
    border: "1px solid white",
  },
  navLink: {
    color: theme.palette.light.main,
    textDecoration: "none",
  },
}));

export default navbarStyles;
