import * as types from "../types";

export const fetchServicesRequest = () => {
  return {
    type: types.FETCH_SERVICES_REQUEST,
  };
};

export const fetchServicesSuccess = ({ data }) => {
  return {
    type: types.FETCH_SERVICES_SUCCESS,
    payload: { data },
  };
};

export const fetchServicesFailure = (error) => {
  return {
    type: types.FETCH_SERVICES_FAILURE,
    payload: {},
    error: error,
  };
};
