import * as types from "../../types";

export const feebackRequest = ({
  id,
  callType,
  device_id,
  additional_remarks,
  user_phone,
  spoc,
}) => {
  return {
    type: types.FEEDBACK_REQUEST,
    payload: { id, callType, device_id, additional_remarks, user_phone, spoc },
  };
};

export const feedbackSuccess = ({ res }) => {
  return {
    type: types.FEEDBACK_SUCCESS,
    payload: res,
  };
};

export const feedbackFailure = (e) => {
  return {
    type: types.FEEDBACK_FAILURE,
    payload: {},
    error: e,
  };
};

export const resetFeedback = () => {
  return {
    type: types.RESET_FEEDBACK,
  };
};
