import * as types from "../types";

export const smsTriggerRequest = ({ body }) => {
  return {
    type: types.SMS_TRIGGER_REQUEST,
    payload: {
      body,
    },
  };
};

export const smsTriggerSuccess = ({ res }) => {
  return {
    type: types.SMS_TRIGGER_SUCCESS,
    payload: { res },
  };
};

export const smsTriggerFailure = (error) => {
  return {
    type: types.SMS_TRIGGER_FAILURE,
    payload: {},
    error: error,
  };
};
