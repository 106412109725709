import React from "react";
import { CampaignTable } from "../../components";

function Dashboard() {
  return (
    <>
      <CampaignTable />
    </>
  );
}

export default Dashboard;
