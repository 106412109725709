import * as types from "../../types";

export const issueTypeRequest = ({ issue }) => {
  return {
    type: types.ISSUE_TYPE_REQUEST,
    payload: { issue },
  };
};

export const addIssue = ({ issue }) => {
  return {
    type: types.ADD_ISSUE_TYPE,
    payload: {
      issue,
    },
  };
};

export const removeIssue = ({ issue }) => {
  return {
    type: types.REMOVE_ISSUE_TYPE,
    payload: {
      issue,
    },
  };
};

export const resetIssues = () => {
  return {
    type: types.RESET_ISSUE_TYPE,
  };
};
