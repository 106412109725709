import React from "react";
import App from "./App.jsx";
// import { Provider } from "react-redux";
// import store from "./store";

const CMS = () => {
  return <App />;
};

export default CMS;
