import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { fetchDataReset } from "../store/action/fetchDataAction";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#652078",
  },
  card: {
    padding: "30px 60px",
    fontSize: "30px",
    height: "600px",
    fontWeight: "600",
    borderRadius: "6px",
    borderBottom: "4px solid hsl(221, 89%, 85%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function CancelOrder2({ number, ...props }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.row}>
      <Card className={classes.card}>
        <h4>No nearby hub or krish-e center found !</h4>
        <h4>We regret, your order cannot be booked.</h4>
        <Button
          style={{
            backgroundColor: "rgb(243, 112, 33)",
            color: "white",

            fontSize: "16px",
            margin: "20px auto",
            width: "240px",
            height: "58px",
          }}
          onClick={() => {
            props.resetFetch();
            sessionStorage.clear();
            history.push("/");
          }}
        >
          Back to Home
        </Button>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  resetFetch: () => {
    dispatch(fetchDataReset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelOrder2);
