import * as types from "../types";
const initialState = {
  loading: false,
  data: null,
  phone: null,
  error: {},
};

const fetchDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        phone: action.payload.body,
        data: null,
        error: {},
      };
    case types.FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: {},
      };
    case types.FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.error,
      };
    case types.FETCH_DATA_RESET:
      return {
        ...state,
        loading: false,
        data: null,
        phone: null,
        error: {},
      };
    default:
      return state;
  }
};

export default fetchDataReducer;
