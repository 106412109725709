import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import inquiry from "../assets/image6.png";
import general from "../assets/image2.png";
import { useHistory } from "react-router-dom";
import outbounds from "../assets/outbounds.svg";
import Grid from "@material-ui/core/Grid";
import tractor from "../assets/image1.png";
import CardItem from "./Carditem";
import tractorInquiry from "../assets/image3.png";
import fass from "../assets/image5.png";
import complaints from "../assets/image4.png";
import Advisory from "../assets/advisory.svg";
import rentalOrder from "../assets/rental_order.svg";
import cms from "../assets/cms.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    backgroundColor: "#FAF3FF",
    padding: "3em 0",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  cardRow: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "row",
  },
}));

export default function Navigation({
  timer,
  setLoggedIn,
  region,
  setTimer,
  number,
  username,
  setTableUrl,
  setVal,
}) {
  const classes = useStyles();
  const history = useHistory();
  const view = localStorage.getItem("analytics");
  const formData = JSON.parse(sessionStorage.getItem("formData"));
  const [showBurdwan, setShowBurdwan] = useState(false);
  const hideTiles = true;
  useEffect(() => {
    const timeListener = window.setTimeout(() => {
      history.push({ pathname: "/", search: `?number=${number}` });
    }, 120000);
    if (
      formData &&
      formData?.district &&
      ["purba bardhaman", "birbhum", "bankura"].includes(
        formData?.district.toLowerCase().trim()
      )
    ) {
      setShowBurdwan(true);
    }
    return () => {
      clearTimeout(timeListener);
    };
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Grid
        spacing={3}
        className={classes.spacing}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          // alignItems:"center",
          flexWrap: "wrap",
        }}
      >
        <Grid
          spacing={3}
          className={classes.spacing}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Grid
            style={{ margin: "0px 20px", cursor: "pointer" }}
            className={classes.cardRow}
            item
            onClick={() => {
              history.push("/search-calls");
            }}
            spacing={4}
          >
            <motion.div
              initial={{ x: "300px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <CardItem icon={general} text="Search Calls" />
            </motion.div>
          </Grid>

          <Grid
            style={{ margin: "0px 20px", cursor: "pointer" }}
            className={classes.cardRow}
            item
            onClick={() => {
              if (sessionStorage.getItem("uid")) {
                history.push("/general");
                setTableUrl("213413549977466");
                setVal(
                  sessionStorage.setItem(
                    "form",
                    "General Understanding of Krish-e"
                  )
                );
                setVal("General Understanding of Krish-e");
              } else {
                history.push("/table");
                setTableUrl("213413549977466");
              }
            }}
            spacing={4}
          >
            <motion.div
              initial={{ x: "300px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <CardItem
                icon={general}
                text="General Understanding of Krish-e"
              />
            </motion.div>
          </Grid>
        </Grid>

        <br />

        <Grid
          spacing={3}
          className={classes.spacing}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Grid
            className={classes.cardRow}
            style={{ margin: "10px 20px", cursor: "pointer" }}
            item
            onClick={() => {
              history.push({
                pathname: "/rental-order",
                state: showBurdwan
                  ? {
                      data: {
                        implements: ["Rotavator"],
                        selectFarm: false,
                        isBurdwan: true,
                        area_units: ["Acre"],
                      },
                    }
                  : null,
              });
              setVal("Rental Order");
              sessionStorage.setItem("form", "Rental Order");
            }}
            spacing={4}
          >
            <motion.div
              initial={{ x: "300px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <CardItem
                icon={rentalOrder}
                text={showBurdwan ? "Burdwan" : "Rental Order"}
              />
            </motion.div>
          </Grid>
          <Grid
            className={classes.cardRow}
            style={{ margin: "10px 20px", cursor: "pointer" }}
            item
            onClick={() => {
              if (sessionStorage.getItem("uid")) {
                history.push("/advisory");
                setTableUrl("203045789049462");
                setVal("Advisory");
                sessionStorage.setItem("form", "Advisory");
              } else {
                history.push("/table");
                setTableUrl("203045789049462");
              }
            }}
            spacing={4}
          >
            <motion.div
              initial={{ x: "300px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <CardItem icon={Advisory} text="Advisory" />
            </motion.div>
          </Grid>
        </Grid>
        <br />
        <Grid
          spacing={3}
          className={classes.spacing}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Grid
            style={{ margin: "10px 20px", cursor: "pointer" }}
            className={classes.cardRow}
            item
            onClick={() => {
              if (sessionStorage.getItem("uid")) {
                history.push("/tractor");
                setTableUrl("203045714511444");
                setVal("Tractor Inquiry");
                sessionStorage.setItem("form", "Tractor Inquiry");
              } else {
                history.push("/table");
                setTableUrl("203045714511444");
              }
            }}
            spacing={4}
          >
            <motion.div
              initial={{ x: "300px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <CardItem icon={tractorInquiry} text="Tractor Inquiry" />
            </motion.div>
          </Grid>
          <Grid
            style={{ margin: "10px 20px", cursor: "pointer" }}
            className={classes.cardRow}
            item
            onClick={() => {
              if (sessionStorage.getItem("uid")) {
                history.push("/implement");
                setTableUrl("203046275443451");
                setVal("Implement Inquiry");
                sessionStorage.setItem("form", "Implement Inquiry");
              } else {
                history.push("/table");
                setTableUrl("203046275443451");
              }
            }}
            spacing={4}
          >
            <motion.div
              initial={{ x: "300px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <CardItem icon={inquiry} text="Implement Inquiry" />
            </motion.div>
          </Grid>
        </Grid>

        <br />

        <Grid
          spacing={3}
          className={classes.spacing}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Grid
            className={classes.cardRow}
            style={{ margin: "0px 20px", cursor: "pointer" }}
            item
            onClick={() => {
              if (sessionStorage.getItem("uid")) {
                history.push("/rental");
                setTableUrl("203032247876455");
                setVal("Krishe Rental Inquiry or Order Booking");
                sessionStorage.setItem(
                  "form",
                  "Krishe Rental Inquiry or Order Booking"
                );
              } else {
                history.push("/table");
                setTableUrl("203032247876455");
              }
            }}
            spacing={4}
          >
            <motion.div
              initial={{ x: "300px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <CardItem icon={tractor} text=" Rental Inquiry" />
            </motion.div>
          </Grid>
          <Grid
            className={classes.cardRow}
            style={{ margin: "10px 20px", cursor: "pointer" }}
            item
            onClick={() => {
              history.push("/cms");
            }}
            spacing={4}
          >
            <motion.div
              initial={{ x: "300px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <CardItem icon={cms} text="SmartKit Complaints" />
            </motion.div>
          </Grid>
        </Grid>

        {!hideTiles && (
          <Grid
            spacing={1}
            className={classes.spacing}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Grid
              className={classes.cardRow}
              style={{ margin: "10px 20px", cursor: "pointer" }}
              item
              onClick={() => {
                if (sessionStorage.getItem("uid")) {
                  history.push("/miscellaneous");
                  setTableUrl("203045563627454");
                  setVal("Miscellaneous");
                  sessionStorage.setItem("form", "Miscellaneous");
                } else {
                  history.push("/table");
                  setTableUrl("203045563627454");
                }
              }}
              spacing={4}
            >
              <motion.div
                initial={{ x: "300px", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
              >
                <CardItem icon={fass} text="Miscellaneous" />
              </motion.div>
            </Grid>
            <Grid
              className={classes.cardRow}
              style={{ margin: "10px 20px", cursor: "pointer" }}
              item
              onClick={() => {
                if (sessionStorage.getItem("uid")) {
                  history.push("/outbound-campaign");
                  setTableUrl("203300994509051");
                  setVal("Outbound Campaign");
                  sessionStorage.setItem("form", "Outbound Campaign");
                } else {
                  history.push("/table");
                  setTableUrl("203300994509051");
                }
              }}
              spacing={4}
            >
              <motion.div
                initial={{ x: "300px", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
              >
                <CardItem icon={outbounds} text="Outbound Campaign" />
              </motion.div>
            </Grid>
          </Grid>
        )}
        {/* <Grid> */}
        <Grid
          spacing={1}
          className={classes.spacing}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Grid
            style={{ margin: "10px 20px", cursor: "pointer" }}
            className={classes.cardRow}
            item
            onClick={() => {
              if (sessionStorage.getItem("uid")) {
                history.push({
                  pathname: "/tractor-fdw",
                  state: { fdw: true, type: "Tractor Enquiry" },
                });
                setTableUrl("203045714511444");
                setVal("Tractor Inquiry FDW");
                sessionStorage.setItem("form", "Tractor Inquiry FDW");
              } else {
                history.push("/table");
                setTableUrl("203045714511444");
              }
            }}
            spacing={4}
          >
            <motion.div
              initial={{ x: "300px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <CardItem icon={tractorInquiry} text="Tractor Inquiry Fdw" />
            </motion.div>
          </Grid>
          {/* {view === "true" && (
            <Grid
              className={classes.cardRow}
              style={{ margin: "10px 20px", cursor: "pointer" }}
              item
              onClick={() => {
                window.open("http://eight-connection.surge.sh/");
              }}
              spacing={4}
            >
              <motion.div
                initial={{ x: "300px", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
              >
                <CardItem icon={Advisory} text="Analytics" />
              </motion.div>
            </Grid>
          )} */}
          <Grid
            style={{ margin: "10px 20px", cursor: "pointer" }}
            className={classes.cardRow}
            item
            onClick={() => {
              if (sessionStorage.getItem("uid")) {
                history.push({
                  pathname: "/implement-fdw",
                  state: { fdw: true, type: "Implement Enquiry" },
                });
                setTableUrl("203046275443451");
                setVal("Implement Inquiry FDW");
                sessionStorage.setItem("form", "Implement Inquiry FDW");
              } else {
                history.push("/table");
                setTableUrl("203046275443451");
              }
            }}
            spacing={4}
          >
            <motion.div
              initial={{ x: "300px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <CardItem icon={inquiry} text="Implement Inquiry Fdw" />
            </motion.div>
          </Grid>

          {/* <Grid></Grid> */}
        </Grid>
        <Grid
          spacing={1}
          className={classes.spacing}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Grid
            className={classes.cardRow}
            style={{ margin: "10px 20px", cursor: "pointer" }}
            item
            onClick={() => {
              if (sessionStorage.getItem("uid")) {
                history.push("/complaints");
                setTableUrl("203045367090450");
                setVal("Complaint");
                sessionStorage.setItem("form", "Complaint");
              } else {
                history.push("/table");
                setTableUrl("203045367090450");
              }
            }}
            spacing={4}
          >
            <motion.div
              initial={{ x: "300px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <CardItem icon={complaints} text="Complaint" />
            </motion.div>
          </Grid>
          <Grid
            className={classes.cardRow}
            style={{ margin: "10px 20px", cursor: "pointer" }}
            item
            onClick={() => {
              if (sessionStorage.getItem("uid")) {
                history.push({
                  pathname: "/ecommerce",
                  state: { ecommerce: true },
                });
                setTableUrl("203045789049462");
                setVal("Inbound Ecommerce");
                sessionStorage.setItem("form", "Inbound Ecommerce");
              } else {
                history.push("/table");
                setTableUrl("203045789049462");
              }
            }}
            spacing={4}
          >
            <motion.div
              initial={{ x: "300px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              <CardItem icon={general} text="Inbound Ecommerce" />
            </motion.div>
          </Grid>
        </Grid>
        
        {showBurdwan && (
          <Grid
            spacing={1}
            className={classes.spacing}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Grid
              className={classes.cardRow}
              style={{ margin: "10px 20px", cursor: "pointer" }}
              item
              onClick={() => {
                history.push("/burdwan-cms");
              }}
              spacing={4}
            >
              <motion.div
                initial={{ x: "300px", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
              >
                <CardItem icon={rentalOrder} text="Burdwan CMS" />
              </motion.div>
            </Grid>
            <Grid />
          </Grid>
        )}

        <Grid
          spacing={1}
          className={classes.spacing}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          {view === "true" && (
            <>
              <Grid
                className={classes.cardRow}
                style={{ margin: "10px 20px", cursor: "pointer" }}
                item
                onClick={() => {
                  // window.open("http://eight-connection.surge.sh/");
                  history.push("/admin");
                }}
                spacing={4}
              >
                <motion.div
                  initial={{ x: "300px", opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                >
                  <CardItem icon={Advisory} text="Admin" />
                </motion.div>
              </Grid>
              <Grid
                className={classes.cardRow}
                style={{ margin: "10px 20px", cursor: "pointer" }}
                item
                onClick={() => {
                  window.open("http://eight-connection.surge.sh/");
                }}
                spacing={4}
              >
                <motion.div
                  initial={{ x: "300px", opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                >
                  <CardItem icon={Advisory} text="Analytics" />
                </motion.div>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
