import React from "react";
import { CampaignForm } from "../../components";

function CampaignJotForm({ props }) {
  return (
    <>
      <CampaignForm {...props} />
    </>
  );
}

export default CampaignJotForm;
