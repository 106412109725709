import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { API_ROUTES, API_BASE_URL } from "../config";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory, useLocation } from "react-router-dom";
import { TextField } from "@material-ui/core";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#652078",
  },
  card: {
    padding: "10px 30px 60px",
    fontSize: "30px",
    height: "600px",

    fontWeight: "600",

    borderRadius: "6px",
    borderBottom: "4px solid hsl(221, 89%, 85%)",
  },
  //   text:{
  //       fontSize:"30px"
  //   }
}));

export default function CancelOrder({ number }) {
  const [value, setValue] = React.useState("");
  const [text, setText] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const [orderId, setOrderId] = useState(location.state.orderId);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const cancelOrder = () => {
    let body = {
      crm_order_id: orderId,
      reason: value === "" ? text : value,
    };
    let config = {
      ApiKey: `41k59h2PEWniwk3QD0IjnYFYC7X92qPz`,
      Authorization: `Token ${token}`,
    };
    axios
      .post(`${API_BASE_URL.KRISHE_CRM_API}${API_ROUTES.CANCEL_ORDERS}`, body, {
        headers: config,
      })
      .then((val) => console.log(val));
  };
  return (
    <div className={classes.row}>
      <Card className={classes.card}>
        <p>
          Your Order has been Cancelled !!
          <br />
          Reason for Cancellation:
        </p>

        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="Price too high"
              control={<Radio style={{ color: "#652078" }} />}
              label="Price too high"
            />
            <FormControlLabel
              value="No longer required"
              control={<Radio style={{ color: "#652078" }} />}
              label="No longer required"
            />
            <FormControlLabel
              value="No RE accepted"
              control={<Radio style={{ color: "#652078" }} />}
              label="No RE accepted"
            />
            <FormControlLabel
              value="Service not available"
              control={<Radio style={{ color: "#652078" }} />}
              label="Service not available"
            />
            <FormControlLabel
              value="RE did not show up"
              control={<Radio style={{ color: "#652078" }} />}
              label="RE did not show up"
            />
            <FormControlLabel
              value="Customer not reachable"
              control={<Radio style={{ color: "#652078" }} />}
              label="Customer not reachable"
            />
            <p style={{ fontSize: "16px", float: "left" }}>Others:</p>
            <TextField
              id="filled-multiline-static"
              multiline
              style={{
                width: "400px",
              }}
              rows={4}
              variant="filled"
              defaultValue={text}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </RadioGroup>
          <Button
            style={{
              backgroundColor: "rgb(243, 112, 33)",
              color: "white",

              fontSize: "16px",
              margin: "20px auto",
              width: "240px",
              height: "58px",
            }}
            onClick={() => {
              cancelOrder();
              history.push("/nav");
            }}
          >
            Cancel Order
          </Button>
        </FormControl>
      </Card>
    </div>
  );
}
