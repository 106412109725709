import * as types from "../../types";

export const selectTicketRequest = ({ ticket }) => {
  return {
    type: types.SELECT_TICKET,
    payload: { ticket },
  };
};
// export const selectTicketRequest = ({ ticket }) => {
//   return {
//     type: types.SELECT_TICKET_REQUEST,
//     payload: { ticket },
//   };
// };

// export const selectTicketSuccess = ({ ticket }) => {
//   return {
//     type: types.SELECT_TICKET_SUCCESS,
//     payload: { ticket },
//   };
// };

// export const selectTicketFailure = (error) => {
//   return {
//     type: types.SELECT_TICKET_FAILURE,
//     payload: {},
//     error: error,
//   };
// };
