import React, { Suspense, useState } from "react";
import { Route, Switch } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Navigation from "../components/Navigation";
import Table from "../components/Table";
import CommonInfo from "../components/CommonInfo2";
import SearchCalls from "../components/SearchCalls";
import Redirect from "../components/Redirect";
import Outbound from "../outbound/outbound";
import RentalOrder from "../components/RentalOrder2";
import NewOrder from "../components/NewOrder";
import CancelOrder2 from "../components/CancelOrder2";
import CMS from "../cms/CMS";
import CreateCampaign from "../components/CreateCampaign";
import Admin from "../components/Admin";
import UserRating from "../components/UserRating";

import SubmitOrder from "../components/SubmitOrder";
import CancelOrder from "../components/CancelOrder";
import BurdwanCMS from "../components/BurdwanCMS";
import EmbedForm from "../components/EmbedForm";
import SmsTrigger from "../components/SmsTrigger";
import { PrivateRoute } from "../elements";

const JotformUrl = [
  "/complaints",
  "/general",
  "/miscellaneous",
  "/rental",
  "/tractor",
  "/implement",
  "/advisory",
  "/outbound-campaign",
  "/tractor-fdw",
  "/implement-fdw",
  "/ecommerce"
];

const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        minHeight: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default function MainRoutes({
  isLoggedIn,
  number,
  setNumber,
  setLoggedIn,
}) {
  const [url, setUrl] = useState(sessionStorage.getItem("uid"));
  const [tableUrl, setTableUrl] = useState("");
  const [timer, setTimer] = useState([]);
  const username = localStorage.getItem("username");
  const [val, setVal] = useState(sessionStorage.getItem("form"));
  function getUrl(val, name, ph_number) {
    setUrl(sessionStorage.setItem("uid", val));
    sessionStorage.setItem("farmer_name", name);
    sessionStorage.setItem("number", ph_number);
    setUrl(val);
  }
  function getNumber(num) {
    setNumber(num);
  }

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <PrivateRoute
          exact
          path="/"
          isLoggedIn={isLoggedIn}
          search={`number=${number}`}
        >
          <CommonInfo
            username={username}
            setLoggedIn={setLoggedIn}
            setUrl={getUrl}
            getNumber={getNumber}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/burdwan-cms" isLoggedIn={isLoggedIn}>
          <BurdwanCMS username={username} />
        </PrivateRoute>
        <PrivateRoute exact path={JotformUrl} isLoggedIn={isLoggedIn}>
          <EmbedForm
            url={url}
            val={val}
            setVal={setVal}
            username={username}
            number={number}
            timer={timer}
          />
        </PrivateRoute>
        <PrivateRoute path="/cms" isLoggedIn={isLoggedIn}>
          <CMS />
        </PrivateRoute>
        <PrivateRoute exact isLoggedIn={isLoggedIn} path="/table">
          <Table username={username} tableUrl={tableUrl} />
        </PrivateRoute>

        <PrivateRoute exact path="/admin" isLoggedIn={isLoggedIn}>
          <Admin
            username={username}
            setLoggedIn={setLoggedIn}
            setUrl={getUrl}
            getNumber={getNumber}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/rating" isLoggedIn={isLoggedIn}>
          <UserRating username={username} />
        </PrivateRoute>
        <PrivateRoute exact path="/admin/create" isLoggedIn={isLoggedIn}>
          <CreateCampaign
            username={username}
            setLoggedIn={setLoggedIn}
            setUrl={getUrl}
            getNumber={getNumber}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/rental-order" isLoggedIn={isLoggedIn}>
          <RentalOrder
            setVal={setVal}
            val={val}
            setTableUrl={setTableUrl}
            username={username}
            number={number}
            setTimer={setTimer}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/newOrder" isLoggedIn={isLoggedIn}>
          <NewOrder
            setVal={setVal}
            setTableUrl={setTableUrl}
            username={username}
            number={number}
            setTimer={setTimer}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/submitOrder" isLoggedIn={isLoggedIn}>
          <SubmitOrder
            setVal={setVal}
            setTableUrl={setTableUrl}
            username={username}
            number={number}
            setTimer={setTimer}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/cancelOrder" isLoggedIn={isLoggedIn}>
          <CancelOrder
            setVal={setVal}
            setTableUrl={setTableUrl}
            username={username}
            number={number}
            setTimer={setTimer}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/cancelOrder2" isLoggedIn={isLoggedIn}>
          <CancelOrder2
            setVal={setVal}
            setTableUrl={setTableUrl}
            username={username}
            number={number}
            setTimer={setTimer}
          />
        </PrivateRoute>
        <Route exact path="/redirect" component={Redirect} />
        <PrivateRoute exact path="/nav" isLoggedIn={isLoggedIn}>
          <Navigation
            timer={timer}
            setVal={setVal}
            setTableUrl={setTableUrl}
            username={username}
            number={number}
            setTimer={setTimer}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/search-calls" isLoggedIn={isLoggedIn}>
          <SearchCalls />
        </PrivateRoute>
        <PrivateRoute exact path="/sms-trigger" isLoggedIn={isLoggedIn}>
          <SmsTrigger />
        </PrivateRoute>
        <PrivateRoute path="/outbound" isLoggedIn={isLoggedIn}>
          <Outbound
            url={url}
            val={val}
            setVal={setVal}
            username={username}
            number={number}
          />
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
}
