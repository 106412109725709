import * as types from "../types";
const initialState = {
  loading: false,
  templates: [],
  error: {},
};

const fetchSmsTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SMS_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        templates: [],
        error: {},
      };
    case types.FETCH_SMS_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload.templates,
        error: {},
      };
    case types.FETCH_SMS_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        templates: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default fetchSmsTemplatesReducer;
