import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withTheme,
  withStyles,
  useTheme,
} from "@material-ui/core/styles";
import { Button } from "../elements";
import TableOption from "./TableOption";
import TicketDetail from "./TicketDetail";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Skeleton from "@material-ui/lab/Skeleton";

import { connect } from "react-redux";
import { fetchTicketRequest } from "../../store/action/cmsActions/fetchTicketAction";
import { selectTicketRequest } from "../../store/action/cmsActions/selectTicketAction";
import { getData } from "../../utils";
import { TableFooter } from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableFilter from "./TableFilter";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: theme.palette.grey.main,
    color: "#706F6F",
  },
  body: {
    fontSize: 14,
    color: theme.palette.dark.main,
    fontWeight: "bold",
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);

const renderLoading = () => {
  let range = [...Array(8).keys()];
  let range2 = [...Array(10).keys()];
  return range.map((i) => (
    <StyledTableRow key={i}>
      {range2.map((i) => (
        <StyledTableCell
          key={i + 10}
          style={{
            height: 60,
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Skeleton
            animation="wave"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </StyledTableCell>
      ))}
    </StyledTableRow>
  ));
};

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const TicketTable = ({ theme, ...props }) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [filteredTickets, setFilteredTickets] = useState(null);
  const [filter, setFilter] = useState("Open");

  const [pageNoResponse, setPageNoResponse] = useState(0);
  const [rowsPerPageNoResponse, setRowsPerPageNoResponse] = useState(10);
  const [pageClosed, setPageClosed] = useState(0);
  const [rowsPerPageClosed, setRowsPerPageClosed] = useState(10);
  const [pageOpen, setPageOpen] = useState(0);
  const [rowsPerPageOpen, setRowsPerPageOpen] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    let spoc = localStorage.getItem("username");
    if (!props.tickets) {
      props.ticketRequest({ spoc });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.tickets) {
      let fTickets = props.tickets.filter((ticket) => ticket.status === filter);
      setFilteredTickets(fTickets);
      switch (filter) {
        case "Open":
          setRowsPerPage(rowsPerPageOpen);
          setPage(pageOpen);
          break;
        case "Closed":
          setRowsPerPage(rowsPerPageClosed);
          setPage(pageClosed);
          break;
        case "No Response":
          setRowsPerPage(rowsPerPageNoResponse);
          setPage(pageNoResponse);
          break;
        default:
          setRowsPerPage(rowsPerPageOpen);
          setPage(pageOpen);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tickets, filter]);

  const handleModalOpen = ({ ticket }) => {
    if (ticket) {
      console.log(ticket);
      props.selectTicket({ ticket });
    }
    setShowModal((prev) => !prev);
  };
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  let sortedData = filteredTickets
    ? filteredTickets.sort((a, b) => {
        if (a.generation_time > b.generation_time) return -1;
        if (a.generation_time < b.generation_time) return 1;

        return 1;
      })
    : null;
  // let sortedData = props.tickets
  //   ? props.tickets.sort((a, b) => {
  //       if (a.status > b.status) return -1;
  //       if (a.status < b.status) return 1;

  //       if (a.generation_time > b.generation_time) return -1;
  //       if (a.generation_time < b.generation_time) return 1;

  //       return 1;
  //     })
  //   : null;

  const handleChangePage = (event, newPage) => {
    switch (filter) {
      case "Open":
        setPageOpen(newPage);
        break;
      case "Closed":
        setPageClosed(newPage);
        break;
      case "No Response":
        setPageNoResponse(newPage);
        break;
      default:
        setPageOpen(newPage);
        break;
    }
  };

  const handleChangeRowsPerPage = (event) => {
    switch (filter) {
      case "Open":
        setRowsPerPageOpen(parseInt(event.target.value, 10));
        break;
      case "Closed":
        setRowsPerPageClosed(parseInt(event.target.value, 10));
        break;
      case "No Response":
        setRowsPerPageNoResponse(parseInt(event.target.value, 10));
        break;
      default:
        setRowsPerPageOpen(parseInt(event.target.value, 10));
        break;
    }
  };

  return (
    <>
      <TableOption />
      <TableFilter filter={filter} setFilter={setFilter} />
      <TableContainer style={{ marginTop: "3em" }}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell align="left">Device Id</StyledTableCell>
              <StyledTableCell align="left">Reason</StyledTableCell>
              <StyledTableCell align="left">Issue Date</StyledTableCell>
              <StyledTableCell align="left">Spoc</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">State</StyledTableCell>
              <StyledTableCell align="left">District</StyledTableCell>
              <StyledTableCell align="left">Is Resolved</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.loading && filteredTickets
              ? (rowsPerPage > 0
                  ? sortedData.slice(page, page * rowsPerPage + rowsPerPage)
                  : sortedData
                ).map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {row.id}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {getData(row.device_id)}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.type}</StyledTableCell>
                    <StyledTableCell align="left">
                      {new Date(row.generation_time).toLocaleDateString(
                        "en-US"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.spoc}</StyledTableCell>
                    <StyledTableCell align="left">
                      {getData(row.user_name)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {getData(row.state)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {getData(row.district)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {getData(row.is_resolved)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#DCFFE9",
                          lineHeight: "30px",
                          fontWeight: "bold",
                          color: "grey",
                          boxShadow: "none",
                        }}
                        onClick={() => handleModalOpen({ ticket: row })}
                      >
                        {getData(row.status)}
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : renderLoading()}
          </TableBody>
          {filteredTickets && (
            <TableFooter
              style={{
                background: theme.palette.grey.main,
              }}
            >
              <StyledTableRow>
                <TablePagination
                  style={{ borderBottom: "none" }}
                  rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
                  count={filteredTickets.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </StyledTableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      <TicketDetail showModal={showModal} handleModalOpen={handleModalOpen} />
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.ticketReducer.loading,
  tickets: state.ticketReducer.tickets,
});

const mapDispatchToProps = (dispatch) => ({
  ticketRequest: ({ spoc }) => {
    dispatch(fetchTicketRequest({ spoc }));
  },
  selectTicket: ({ ticket }) => {
    dispatch(selectTicketRequest({ ticket }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(TicketTable));
