import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../types";
import {
  callRatingSuccess,
  callRatingFailure,
} from "../action/callRatingAction";
import { returnErrors, clearErrors } from "../action/errorAction";
import * as _api from "../../api";

function* asyncCallRatingSaga(action) {
  try {
    yield put(clearErrors());
    // let res = yield call(_api.fetchCampaigns, { spoc: action.payload.spoc });
    let res = yield call(_api.submitCallRating, {
      body: action.payload.body,
    });
    yield put(callRatingSuccess({ res }));
  } catch (e) {
    yield put(returnErrors({ msg: e.message, status: true }));
    console.log(e);
    yield put(callRatingFailure(e));
  }
}

export default function* callRatingSaga() {
  yield takeLatest(types.CALL_RATING_REQUEST, asyncCallRatingSaga);
}
