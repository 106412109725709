import * as types from "../types";

export const fetchFilterCallsRequest = ({ searchKey, searchValue }) => {
  return {
    type: types.FETCH_FILTER_CALLS_REQUEST,
    payload: {
      searchKey,
      searchValue,
    },
  };
};

export const fetchFilterCallsSuccess = ({ data }) => {
  return {
    type: types.FETCH_FILTER_CALLS_SUCCESS,
    payload: { data },
  };
};

export const fetchFilterCallsFailure = (error) => {
  return {
    type: types.FETCH_FILTER_CALLS_FAILURE,
    payload: {},
    error: error,
  };
};

export const fetchFiltercallsSelectRequest = ({ ticket_id, category }) => {
  return {
    type: types.FETCH_FILTER_CALLS_SELECT_REQUEST,
    payload: {
      ticket_id,
      category,
    },
  };
};

export const fetchFiltercallsSelectFailure = (error) => {
  return {
    type: types.FETCH_FILTER_CALLS_SELECT_FAILURE,
    payload: {},
    error,
  };
};
export const fetchFiltercallsSelectSuccess = ({ data }) => {
  return {
    type: types.FETCH_FILTER_CALLS_SELECT_SUCCESS,
    payload: { data },
  };
};
