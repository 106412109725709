import React, { useEffect } from "react";
import { makeStyles, withTheme } from "@material-ui/core/styles";

import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import {
  issueTypeRequest,
  resetIssues,
} from "../../store/action/cmsActions/issueTypeAction";

const useStyles = makeStyles((theme) => ({
  checkBoxGroup: {
    margin: "2em 0",
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
  },
}));

const data = [
  {
    label: "Area",
    value: "area",
  },
  {
    label: "Distance",
    value: "distance",
  },
  {
    label: "Fuel Consumption",
    value: "fuel_consumption",
  },
  {
    label: "Fuel Level",
    value: "fuel_level",
  },
  {
    label: "Job Time",
    value: "job_time",
  },
  {
    label: "Location",
    value: "location",
  },
  {
    label: "Missing Alerts",
    value: "missing_alerts",
  },
  {
    label: "Others",
    value: "others",
  },
  {
    label: "Recharge Request",
    value: "recharge_request",
  },
  {
    label: "Total Time",
    value: "total_time",
  },
  {
    label: "Trip Miss",
    value: "trip_miss",
  },
  {
    label: "Too Many Alerts",
    value: "too_many_alerts",
  },
];

const CheckBoxes = ({ theme, ...props }) => {
  const classes = useStyles();
  useEffect(() => {
    props.resetIssuesArray();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FormGroup className={classes.checkBoxGroup}>
      {props.issues &&
        data.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={props.issues.includes(item.value)}
                value={item.value}
                onChange={(e) => {
                  props.issueRequest({ issue: e.target.value });
                }}
                name={item.label}
                color="primary"
                style={{
                  color: theme.palette.accent.main,
                }}
              />
            }
            label={item.label}
          />
        ))}
    </FormGroup>
  );
};

const mapStateToProps = (state) => ({
  issues: state.issuesReducer.issues,
});

const mapDispatchToProps = (dispatch) => ({
  issueRequest: ({ issue }) => {
    dispatch(issueTypeRequest({ issue }));
  },
  resetIssuesArray: () => {
    dispatch(resetIssues());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(CheckBoxes));
