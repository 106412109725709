import * as types from "../../types";

const initialState = {
  loading: false,
  tractors: null,
  existingTickets: null,
  phone: null,
  error: {},
};

const fetchTractorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TRACTOR_REQUEST:
      return {
        ...state,
        loading: true,
        phone: action.payload.phone,
        tractors: null,
        existingTickets: null,
        error: {},
      };
    case types.FETCH_TRACTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        tractors: action.payload.tractors,
        existingTickets: action.payload.existingTickets,
        error: {},
      };
    case types.FETCH_TRACTOR_FAILURE:
      return {
        ...state,
        loading: false,
        tractors: null,
        existingTickets: null,
        phone: null,
        error: action.error,
      };
    case types.RESET_TRACTORS:
      return {
        ...state,
        tractors: null,
        existingTickets: null,
        phone: null,
      };
    default:
      return state;
  }
};

export default fetchTractorReducer;
